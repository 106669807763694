import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import { Redirect, Link, useLocation } from "react-router-dom";

import { GlobalContext } from "../../../commonContext";
import { Form, Input, Button, message } from "antd";
import { Wrapper } from "../sign-up/style";

function Login(props) {
  const {
    loginAction,
    forgotPasswordAction,
    resetPasswordAction,
    fakeActionAuth,
  } = props;
  const [form] = Form.useForm();
  const contextVar = useContext(GlobalContext);
  const [forgotPasswordLoading, setForgotPasswordLoading] = useState(false);
  const LoginRes = useSelector((state) => state.Auth.LoginResponse || {});
  const ForgotPasswordResponse = useSelector(
    (state) => state.Auth.ForgotPasswordResponse || {}
  );
  const ResetPasswordResponse = useSelector(
    (state) => state.Auth.ResetPasswordResponse || {}
  );

  const location = useLocation();

  const [forgotPasswordStatus, setForgotPasswordStatus] = useState(false);

  const [redirect, setRedirect] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userData, setUserData] = useState({
    email: "user@gmail.com",
    password: "12345678",
  });
  document.title = "Login";
  useEffect(() => {
    if (LoginRes?.status === true) {
      setLoading(false);
      localStorage.setItem("user", JSON.stringify(LoginRes?.data?.user_data));
      localStorage.setItem(
        "token",
        LoginRes?.data?.auth_token_data?.access_token
      );
      const userType = LoginRes?.data?.user_data?.user_type;

      contextVar?.updateCommonGlobalVal("profile", LoginRes?.data?.user_data, {
        back: userType == 1,
      });
      localStorage.setItem("userType", userType);
      setRedirect(`${userType == 1 ? "/manage-seller-users" : "/"}`);
      message.destroy();
      message.success(LoginRes?.message);

      fakeActionAuth();
    } else if (LoginRes?.status === false) {
      setLoading(false);
      message.destroy();
      message.warning(LoginRes?.message);
      fakeActionAuth();
    }
  }, [LoginRes]);

  const submit = () => {
    if (userData.email && userData.password) {
      setLoading(true);
      let body = {
        username: userData.email,
        password: userData.password,
      };
      loginAction(body);
    } else {
      message.destroy();
      message.warning("please fill inputs");
    }
  };

  useEffect(() => {
    if (ForgotPasswordResponse?.status === true) {
      setLoading(false);
      setForgotPasswordStatus(false);
      message.success(ForgotPasswordResponse?.message);
      fakeActionAuth("ForgotPasswordResponse");
    } else if (ForgotPasswordResponse?.status === false) {
      setLoading(false);
      message.error(
        Object.values(ForgotPasswordResponse?.data)?.[0]?.[0] ||
          ForgotPasswordResponse?.message
      );
      fakeActionAuth("ForgotPasswordResponse");
    }
  }, [ForgotPasswordResponse]);

  const forgotPassword = () => {
    if (!loading) {
      setLoading(true);
      forgotPasswordAction({
        email: userData?.email,
      });
    }
  };

  useEffect(() => {
    if (ResetPasswordResponse?.status === true) {
      message.destroy();
      message.success(ResetPasswordResponse?.message);
      window.location.assign("/login");
      fakeActionAuth("ResetPasswordResponse");
    } else if (ResetPasswordResponse?.status === false) {
      message.destroy();
      message.error(ResetPasswordResponse?.message);
      fakeActionAuth("ResetPasswordResponse");
    }
  }, [ResetPasswordResponse]);

  if (redirect || localStorage.getItem("token")) {
    return <Redirect to={redirect || "/"} />;
  }

  const validatePassword = (_, value) => {
    if (!value || (value.length >= 8 && value.length <= 16)) {
      return Promise.resolve();
    }
    return Promise.reject("Password must be between 8 and 16 characters.");
  };

  const confirmPasswordValidator = (_, value, { getFieldValue }) => {
    if (value !== getFieldValue("password")) {
      return Promise.reject("Passwords do not match");
    }
    return Promise.resolve();
  };

  if (location?.pathname === "/reset-password") {
    return (
      <div
        className="d-flex flex-column flex-root"
        id="kt_app_root"
        style={{ height: "100%" }}
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-light">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10 ">
                <div className="text-center mb-11 ">
                  <Link to="/" className>
                    <img
                      alt="Logo"
                      src="/assets/media/vfads.png"
                      className="h-60px h-lg-55px"
                    />
                  </Link>
                </div>
                <Wrapper className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">Reset Password</h1>
                    <div className="text-gray-500 fw-semibold fs-6">
                      Your Social Campaigns
                    </div>
                  </div>
                  <Form
                    layout="vertical"
                    form={form}
                    name="forgot-password-form"
                  >
                    <Form.Item
                      name="password"
                      label="Password"
                      rules={[
                        {
                          required: true,
                          message: "Please enter your password!",
                        },
                        {
                          validator: validatePassword,
                        },
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>

                    <Form.Item
                      name="confirm_password"
                      label="Confirm Password"
                      dependencies={["password"]}
                      rules={[
                        {
                          required: true,
                          message: "Please confirm your password!",
                        },
                        ({ getFieldValue }) => ({
                          validator(_, value) {
                            if (!value || getFieldValue("password") === value) {
                              return Promise.resolve();
                            }
                            return Promise.reject(
                              new Error(
                                "The new password that you entered do not match!"
                              )
                            );
                          },
                        }),
                      ]}
                    >
                      <Input.Password size="large" />
                    </Form.Item>
                  </Form>

                  <div className="d-grid mb-10">
                    <button
                      onClick={() => {
                        form
                          .validateFields()
                          .then((values) => {
                            if (!location?.search?.split("=")?.[1]) {
                              message.warning("something was wrong");
                              return;
                            }
                            // form.resetFields();
                            setForgotPasswordLoading(true);
                            resetPasswordAction(
                              location?.search?.split("=")?.[1],
                              values
                            );
                          })
                          .catch((info) => {});
                      }}
                      className="btn btn-primary"
                    >
                      {forgotPasswordLoading ? (
                        <span className="">
                          Please wait...
                          <span className="spinner-border spinner-border-sm align-middle ms-2" />
                        </span>
                      ) : (
                        <span className="indicator-label">Reset Password</span>
                      )}
                    </button>
                  </div>
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Not a Member yet?
                    <Link to="/sign-up" className="link-primary fw-bold">
                      {" "}
                      Sign up
                    </Link>
                  </div>
                </Wrapper>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bg-white"
            style={{}}
          >
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img
                className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
                src="/assets/media/login.svg"
                alt=""
              />
              <h1 className="d-none d-lg-block text-dark fs-2qx fw-bolder text-center mb-7">
                E-Commerce &amp; Digital Marketing Services
              </h1>
              <div className="d-none d-lg-block text-dark fs-4 text-center">
                We are a team of digital marketing experts &amp; business <br />
                entrepreneurs with know how strategy to grow companies in
                e-commerce
                <br /> and develop a strong presence in the market.
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <div
        className="d-flex flex-column flex-root"
        id="kt_app_root"
        style={{ height: "100%" }}
      >
        <div className="d-flex flex-column flex-lg-row flex-column-fluid">
          <div className="d-flex flex-column flex-lg-row-fluid w-lg-50 p-10 order-2 order-lg-1 bg-light">
            <div className="d-flex flex-center flex-column flex-lg-row-fluid">
              <div className="w-lg-500px p-10 ">
                <div className="text-center mb-11 ">
                  <Link to="/" className>
                    <img
                      alt="Logo"
                      src="/assets/media/vfads.png"
                      className="h-60px h-lg-55px"
                    />
                  </Link>
                </div>
                <div className="form w-100">
                  <div className="text-center mb-11">
                    <h1 className="text-dark fw-bolder mb-3">
                      {forgotPasswordStatus ? "Reset Password" : "Sign In"}
                    </h1>
                    <div className="text-gray-500 fw-semibold fs-6">
                      Your Social Campaigns
                    </div>
                  </div>
                  <div className="fv-row mb-8">
                    <Input
                      type="text"
                      placeholder="Email"
                      name="email"
                      autoComplete="off"
                      size="large"
                      value={userData?.email}
                      onChange={(e) => {
                        setUserData({
                          ...userData,
                          email: e.target.value,
                        });
                      }}
                    />
                  </div>
                  {!forgotPasswordStatus && (
                    <div className="fv-row mb-3">
                      <Input.Password
                        type="password"
                        placeholder="Password"
                        name="password"
                        autoComplete="off"
                        size="large"
                        // className="form-control h-60px ps-5 bg-transparent"
                        value={userData?.password}
                        onChange={(e) => {
                          setUserData({
                            ...userData,
                            password: e.target.value,
                          });
                        }}
                      />
                    </div>
                  )}
                  {!forgotPasswordStatus && (
                    <div className="d-flex flex-stack flex-wrap gap-3 fs-base fw-semibold mb-8">
                      <div />
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() => setForgotPasswordStatus(true)}
                        className="link-primary fw-bold"
                      >
                        Forgot Password ?
                      </span>
                    </div>
                  )}
                  {!forgotPasswordStatus && (
                    <div className="d-grid mb-10">
                      <button
                        onClick={() => submit()}
                        className="btn btn-primary"
                      >
                        {loading ? (
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        ) : (
                          <span className="indicator-label">Sign In</span>
                        )}
                      </button>
                    </div>
                  )}
                  {forgotPasswordStatus && (
                    <div className="d-grid mb-10">
                      <button
                        onClick={() => forgotPassword()}
                        className="btn btn-primary"
                      >
                        {loading ? (
                          <span className="">
                            Please wait...
                            <span className="spinner-border spinner-border-sm align-middle ms-2" />
                          </span>
                        ) : (
                          <span className="indicator-label">
                            Forgot Password
                          </span>
                        )}
                      </button>
                    </div>
                  )}
                  <div className="text-gray-500 text-center fw-semibold fs-6">
                    Not a Member yet?
                    <Link to="/sign-up" className="link-primary fw-bold">
                      {" "}
                      Sign up
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div
            className="d-flex flex-lg-row-fluid w-lg-50 bgi-size-cover bgi-position-center order-1 order-lg-2 bg-white"
            style={{}}
          >
            <div className="d-flex flex-column flex-center py-7 py-lg-15 px-5 px-md-15 w-100">
              <img
                className="d-none d-lg-block mx-auto w-275px w-md-50 w-xl-500px mb-10 mb-lg-20"
                src="/assets/media/login.svg"
                alt=""
              />
              <h1 className="d-none d-lg-block text-dark fs-2qx fw-bolder text-center mb-7">
                E-Commerce &amp; Digital Marketing Services
              </h1>
              <div className="d-none d-lg-block text-dark fs-4 text-center">
                We are a team of digital marketing experts &amp; business <br />
                entrepreneurs with know how strategy to grow companies in
                e-commerce
                <br /> and develop a strong presence in the market.
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
