import { CloseCircleOutlined, DotChartOutlined } from "@ant-design/icons";
import {
  Button,
  DatePicker,
  Dropdown,
  Input,
  InputNumber,
  Segmented,
  Select,
  Skeleton,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import dayjs from "dayjs";
import Highcharts from "highcharts";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../components/icons";
import NoData from "../../../components/no-data";
import { TableLoading } from "../../../components/table-animation";
import { FormatNumber } from "../../../config";
import CardLoading from "../dashboard/lib/card-loading";
import BudgetModal from "./lib/FilterBudget";
import ColumnDrawer from "./lib/columns-grid";
import FilterModal from "./lib/filter";
import Targeting from "./lib/targeting";
import Targetloading from "./lib/targetloading";
import UpdateModal from "./lib/update";
import { Wrapper } from "./style";

const { RangePicker } = DatePicker;
const LineChart = (props) => {
  const { data, count, signs } = props;

  const colorHashCodes = [
    "#FF5733", // Deep Orange
    "#4CAF50", // Green
    "#2196F3", // Blue
    "#FFC107", // Amber
    "#9C27B0", // Purple
    "#FFEB3B", // Yellow
    "#FF9800", // Orange
    "#E91E63", // Pink
    "#795548", // Brown
    "#00BCD4", // Cyan
    "#FF4081", // Pink Red
  ];
  const sign = {
    ACOS: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    ROAS: {
      valueSuffix: "",
      valuePrefix: "",
    },
    Spend: {
      valueSuffix: "",
      valuePrefix: signs,
    },
    "Total Sales": {
      valueSuffix: "",
      valuePrefix: signs,
    },
    CPC: {
      valueSuffix: "",
      valuePrefix: signs,
    },
    "Conversion Rate": {
      valueSuffix: "%",
      valuePrefix: "",
    },
    Orders: {
      valueSuffix: "",
      valuePrefix: "",
    },
    Clicks: {
      valueSuffix: "",
      valuePrefix: "",
    },
    Impressions: {
      valueSuffix: "",
      valuePrefix: "",
    },
    CTR: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    "Ad Sales Percentage": {
      valueSuffix: "%",
      valuePrefix: "",
    },
  };

  useEffect(() => {
    if (data?.length === 0 || count?.length === 0) return;
    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "480px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
      },
      exporting: {
        enabled: true,
        buttons: {
          contextButton: {
            menuItems: [
              "downloadPNG",
              "downloadJPEG",
              "downloadPDF",
              "downloadSVG",
              "separator",
              "downloadCSV",
              "downloadXLS",
            ],
          },
        },
      },
      type: "column",
      title: "",
      xAxis: {
        title: {
          text: "Date",
        },
        categories: data,
      },

      yAxis: [
        {
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return signs + this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],

      series: count?.map((d, i) => {
        return {
          name: d?.name,
          type: d?.name === "Impressions" ? "column" : "line",
          tooltip: sign?.[d?.name],
          color: colorHashCodes?.reverse()?.[i] || "#A52A2A",
          data: d?.data,
          yAxis: sign?.[d?.name]?.valuePrefix
            ? 1
            : sign?.[d?.name]?.valueSuffix
            ? 2
            : 0,
        };
      }),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
          // You can add more properties to modify the tooltip style
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            this.series.name +
            ": <b>" +
            sign?.[this.series.name]?.valuePrefix +
            this.y?.toLocaleString() +
            sign?.[this.series.name]?.valueSuffix +
            "</b><br/>"
          );
        },
      },
    });
  }, [count]);

  return <div id="line-chart" />;
};
function CampaignsAnalyticsModule(props) {
  const {
    fakeActionCampaignsAnalytics,
    CampaignsAnalyticsFilterListAction,
    CampaignsAnalyticsPerformanceMetricsAction,
    ActiveCompaignsAction,
    CampaignsAnalyticsAllTableDataAction,
    CampaignsAnalyticsDailyPerformanceTrendAction,
    UpdateCampaignBugestAction,
    CampaignConfigListAction,
    GetPortfoliosListAction,
    GetFilterListAction,

    getOptionListAction,
    DashboardTargetingCardDataAction,
    fakeActionDashboard,

    GetMarketplaceListAction,
    fakeActionMarketplace,
  } = props;
  const [filterLoading, setFilterLoading] = useState(true);
  const [filters, setFilters] = useState({
    advertising_type: [],
    metrics: [],
    start_date: dayjs().add(-60, "d"),
    end_date: dayjs().add(-30, "d"),
  });

  const [filtersModals, setFiltersModals] = useState({
    show: false,
  });

  const [targeting, setTargeting] = useState({});
  const [targetingPrev, setTargetingPrev] = useState({});

  const [configModal, setConfigModal] = useState(false);
  const [advertisingTypeData, setAdvertisingTypeData] = useState([]);
  const [metricsData, setMetricsData] = useState([]);

  const [performanceMetricsLoading, setPerformanceMetricsLoading] =
    useState(true);
  const [performanceMetricsList, setPerformanceMetricsList] = useState({});

  const [dailyPerformanceTrendLoading, setDailyPerformanceTrendLoading] =
    useState(true);
  const [dailyPerformanceTrendList, setDailyPerformanceTrendList] = useState(
    []
  );

  const [activeCompaignsList, setActiveCompaignsList] = useState({});
  const [activeCompaignsLoading, setActiveCompaignsLoading] = useState(true);

  const [portfoliosList, setPortfoliosList] = useState([]);
  const [portfoliosLoading, setPortfoliosLoading] = useState(true);

  const [tableLoading, setTableLoading] = useState(true);
  const [tableList, setTableList] = useState({});

  const [seriesList, setSeriesList] = useState([]);

  const [optionLoader, setOptionLoader] = useState(true);
  const [matchOptionList, setMatchOptionList] = useState([]);

  const [configLoading, setConfigLoading] = useState(true);
  const [configList, setConfigList] = useState([]);

  const [dateCurrent, setDateCurrent] = useState({
    start_date: dayjs().add(-30, "d"),
    end_date: dayjs(),
  });

  const [performancePrev, setPerformancePrev] = useState({});
  const [activeCampaign, setActiveCampaign] = useState({});

  const [marketplaceLoading, setMarketplaceLoading] = useState(true);
  const [marketplaceList, setMarketplaceList] = useState([]);
  const [selectedMarketplace, setSelectedMarketplace] = useState(null);

  const [graphType, setGraphType] = useState("Current");
  const [graphCurrentData, setGraphCurrentData] = useState([]);
  const [graphSeriesList, setGraphSeriesList] = useState([]);

  const [targetingLoading, setTargetingLoading] = useState(true);
  const [targetingCurrantLoading, setTargetingCurrantLoading] = useState(true);

  const [filterList, setFilterList] = useState([]);
  const [operatorList, setOperatorList] = useState([]);

  const [filterValues, setFilterValues] = useState({});

  const [actionView, setActionView] = useState({
    show: false,
  });

  const FilterResponse = useSelector(
    (state) => state?.CampaignsAnalytics.CampaignsAnalyticsFilterResponse || {}
  );
  const PerformanceMetricsResponse = useSelector(
    (state) =>
      state?.CampaignsAnalytics.CampaignsAnalyticsPerformanceMetricsResponse ||
      {}
  );
  const DailyPerformanceTrendResponse = useSelector(
    (state) =>
      state?.CampaignsAnalytics
        .CampaignsAnalyticsDailyPerformanceTrendResponse || {}
  );
  const ActiveCompaignsDataResponse = useSelector(
    (state) => state?.CampaignsAnalytics.ActiveCompaignsDataResponse || {}
  );
  const AllCompaignsDataResponse = useSelector(
    (state) => state?.CampaignsAnalytics.AllCompaignsDataResponse || {}
  );
  const GetAllPortFoliosListResponse = useSelector(
    (state) => state?.CampaignsAnalytics.GetAllPortFoliosListResponse || {}
  );
  const UpdateCampaignBudgetResponse = useSelector(
    (state) => state?.CampaignsAnalytics.UpdateCampaignBudgetResponse || {}
  );
  const GetCampaignConfigListResponse = useSelector(
    (state) => state?.CampaignsAnalytics.GetCampaignConfigListResponse || {}
  );
  const UpdateCampaignConfigListResponse = useSelector(
    (state) => state?.CampaignsAnalytics.UpdateCampaignConfigListResponse || {}
  );
  const BulkActionUpdateResponse = useSelector(
    (state) => state?.CampaignsAnalytics.BulkActionUpdateResponse || {}
  );

  const DashboardTargetingCardResponse = useSelector(
    (state) => state.Dashboard.DashboardTargetingCardResponse || {}
  );
  const OptionListResponse = useSelector(
    (state) => state?.Dashboard.OptionListResponse || {}
  );

  const GetMarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredential.GetMarketplaceListResponse || {}
  );

  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[selectedMarketplace] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  useEffect(() => {
    if (DashboardTargetingCardResponse?.status === true) {
      if (DashboardTargetingCardResponse?.data?.request_type === "Current") {
        setTargeting(
          DashboardTargetingCardResponse?.data?.targeting_data || {}
        );
        setTargetingLoading(false);
      } else {
        setTargetingCurrantLoading(false);
        setTargetingPrev(
          DashboardTargetingCardResponse?.data?.targeting_data || {}
        );
      }

      fakeActionDashboard("DashboardTargetingCardResponse");
    } else if (DashboardTargetingCardResponse?.status === false) {
      if (DashboardTargetingCardResponse?.data?.request_type !== "Current") {
        setTargeting({});
        setTargetingCurrantLoading(false);
      } else {
        setTargetingPrev({});
      }
      setTargetingLoading(false);
      fakeActionDashboard("DashboardTargetingCardResponse");
    }
  }, [DashboardTargetingCardResponse]);
  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      if (GetMarketplaceListResponse?.selected_marketplaces?.length > 0) {
        setSelectedMarketplace(
          GetMarketplaceListResponse?.selected_marketplaces?.filter(
            (d) => d?.id === "A21TJRUUN4KGV"
          )?.[0]?.id ||
            GetMarketplaceListResponse?.selected_marketplaces?.[0]?.id
        );
      }
      setMarketplaceList(GetMarketplaceListResponse?.selected_marketplaces);
      setMarketplaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketplaceLoading(false);
      setMarketplaceList([]);
      setSelectedMarketplace(null);
      fakeActionMarketplace("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    if (UpdateCampaignConfigListResponse?.status === true) {
      setConfigList(
        UpdateCampaignConfigListResponse?.data?.["campaigns-analytics"] || []
      );
      setConfigModal(false);
      message.destroy();
      message.success(UpdateCampaignConfigListResponse?.message);
      fakeActionCampaignsAnalytics("UpdateCampaignConfigListResponse");
    } else if (UpdateCampaignConfigListResponse?.status === false) {
      setConfigList([]);
      message.destroy();
      message.warning(UpdateCampaignConfigListResponse?.message || "-");
      fakeActionCampaignsAnalytics("UpdateCampaignConfigListResponse");
    }
  }, [UpdateCampaignConfigListResponse]);

  useEffect(() => {
    if (GetAllPortFoliosListResponse?.status === true) {
      setPortfoliosList(GetAllPortFoliosListResponse?.data || []);
      setPortfoliosLoading(false);
      fakeActionCampaignsAnalytics("GetAllPortFoliosListResponse");
    } else if (GetAllPortFoliosListResponse?.status === false) {
      setPortfoliosList([]);
      setPortfoliosLoading(false);
      fakeActionCampaignsAnalytics("GetAllPortFoliosListResponse");
    }
  }, [GetAllPortFoliosListResponse]);

  useEffect(() => {
    if (GetCampaignConfigListResponse?.status === true) {
      setConfigList(GetCampaignConfigListResponse?.data);
      setConfigLoading(false);
      fakeActionCampaignsAnalytics("GetCampaignConfigListResponse");
    } else if (GetCampaignConfigListResponse?.status === false) {
      setConfigLoading(false);
      setConfigList([]);
      message.destroy();
      message.error(GetCampaignConfigListResponse?.message || "-");
      fakeActionCampaignsAnalytics("GetCampaignConfigListResponse");
    }
  }, [GetCampaignConfigListResponse]);

  useEffect(() => {
    if (OptionListResponse.status) {
      let optionsList = OptionListResponse?.data;

      if (Object.keys(optionsList?.match_type_data).length > 0) {
        let match_option = Object.entries(optionsList?.match_type_data).map(
          ([key, val]) => {
            return {
              value: key,
              label: val,
            };
          }
        );
        setMatchOptionList(match_option);
        setFilters({
          ...filters,
          match_type: match_option?.map((d) => d?.value),
        });
      }

      setOptionLoader(false);
      fakeActionDashboard("OptionListResponse");
    } else if (OptionListResponse.status === false) {
      setOptionLoader(false);
      fakeActionDashboard("OptionListResponse");
    }
  }, [OptionListResponse]);

  useEffect(() => {
    if (FilterResponse?.status === true) {
      let optionsList = FilterResponse?.data;
      if (Object.keys(optionsList?.advertising_type_data).length > 0) {
        let advertising_option = Object.entries(
          optionsList?.advertising_type_data
        ).map(([key, val]) => {
          return {
            value: key,
            label: val,
          };
        });
        setAdvertisingTypeData(advertising_option);
      }
      if (Object.keys(optionsList?.metrics_data).length > 0) {
        let metrics_option = Object.entries(optionsList?.metrics_data).map(
          ([key, val]) => {
            return {
              value: key === "acos" ? "acos_percentage" : key,
              label: val,
            };
          }
        );

        setFilters({
          ...filters,
          metrics: metrics_option.slice(0, 4)?.map((d) => d.value),
        });
        setMetricsData(metrics_option);
      }

      if (Object.keys(optionsList?.table_filter_operator_data).length > 0) {
        let operatorSet = Object.entries(
          optionsList?.table_filter_operator_data
        ).map(([key, val]) => {
          return {
            value: key,
            label: val,
          };
        });

        setOperatorList(operatorSet);
      }
      if (Object.keys(optionsList?.table_filter_operand_data).length > 0) {
        let metrics_option = Object.entries(
          optionsList?.table_filter_operand_data
        ).map(([key, val]) => {
          return {
            value: key,
            label: val,
          };
        });

        setFilterList(metrics_option);
      }

      setFilterLoading(false);
      fakeActionCampaignsAnalytics("CampaignsAnalyticsFilterResponse");
    } else if (FilterResponse?.status === false) {
      setFilterLoading(false);
      fakeActionCampaignsAnalytics("CampaignsAnalyticsFilterResponse");
    }
  }, [FilterResponse]);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setPortfoliosLoading(true);
    GetPortfoliosListAction({ marketplace_id: selectedMarketplace });
  }, [selectedMarketplace]);

  useEffect(() => {
    setFilterLoading(true);
    CampaignsAnalyticsFilterListAction();

    setOptionLoader(true);
    getOptionListAction();

    setConfigLoading(true);
    CampaignConfigListAction("campaigns-analytics");
    return () => {};
  }, []);

  // graph
  useEffect(() => {
    if (!selectedMarketplace) return;
    setDailyPerformanceTrendList([]);
    setDailyPerformanceTrendLoading(true);

    CampaignsAnalyticsDailyPerformanceTrendAction({
      advertising_type: filters?.advertising_type,
      metrics: filters?.metrics,
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      request_type: "prev_count",
      marketplace_id: selectedMarketplace,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    filters?.advertising_type,
    filters?.metrics,
    selectedMarketplace,
  ]);

  useEffect(() => {
    if (!selectedMarketplace) return;
    setGraphSeriesList([]);
    setDailyPerformanceTrendLoading(true);

    CampaignsAnalyticsDailyPerformanceTrendAction({
      advertising_type: filters?.advertising_type,
      metrics: filters?.metrics,
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "current_count",
      marketplace_id: selectedMarketplace,
    });
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    filters?.advertising_type,
    filters?.metrics,
    selectedMarketplace,
  ]);

  const getFilterTable = (e) => {
    if (!selectedMarketplace) return;
    message.destroy();
    message.loading("Loading...", 0);
    const { advertising_type, start_date, end_date } = filters;
    const obj = {
      advertising_type: advertising_type,
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "prev_count",
      marketplace_id: selectedMarketplace,
    };
    const filterV = Object?.entries(e)?.filter(
      (d) => d?.[0] !== "filter_condition"
    );
    setFiltersModals({
      show: false,
    });
    setTableLoading(true);
    setTableList({});
    CampaignsAnalyticsAllTableDataAction(
      obj,
      filterV?.[0]?.[0],
      filterV?.[0]?.[1],
      e?.filter_condition
    );
  };
  const [filterLabel, setFilterLabel] = useState({ label: "", value: "" });
  const [filterValues_, setFilterValues_] = useState({});
  const getNameFilter = () => {
    const filterV = Object?.entries(filterValues)?.filter(
      (d) => d?.[0] !== "filter_condition"
    );
    return (
      <div
        style={{
          padding: "6px",
          background: "#21aae6",
          borderRadius: "6px",
          marginRight: "10px",
          color: "#FFF",
        }}
      >
        {filterLabel?.label || "-"}:&nbsp;{filterLabel?.value || "-"}
        &nbsp;&nbsp;
        <Tooltip title="Reset">
          <CloseCircleOutlined
            style={{ cursor: "pointer" }}
            onClick={() => {
              setFilterLabel({
                label: "",
                value: "",
              });
              setFilterValues_({});
              setFilterValues({});
              getFilterTable({});
            }}
          />
        </Tooltip>
      </div>
    );
  };

  useEffect(() => {
    if (!selectedMarketplace) return;
    const { advertising_type } = filters;
    const obj = {
      advertising_type: advertising_type,
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "prev_count",
      marketplace_id: selectedMarketplace,
    };
    const filterV = Object?.entries(filterValues)?.filter(
      (d) => d?.[0] !== "filter_condition"
    );

    setTableLoading(true);
    setTableList({});
    CampaignsAnalyticsAllTableDataAction(
      obj,
      filterV?.[0]?.[0],
      filterV?.[0]?.[1],
      filterValues?.filter_condition
    );
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    filters?.advertising_type,
    selectedMarketplace,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    const { advertising_type, start_date, end_date } = filters;
    const obj = {
      advertising_type: advertising_type,
      start_date: !start_date ? null : dayjs(start_date).format("YYYY-MM-DD"),
      end_date: !end_date ? null : dayjs(end_date).format("YYYY-MM-DD"),
      request_type: "prev_count",
      marketplace_id: selectedMarketplace,
    };

    setPerformanceMetricsLoading(true);
    CampaignsAnalyticsPerformanceMetricsAction(obj);
  }, [
    filters?.end_date,
    filters?.start_date,
    filters?.advertising_type,
    selectedMarketplace,
  ]);
  useEffect(() => {
    if (BulkActionUpdateResponse?.status === true) {
      message.destroy();
      message.success(BulkActionUpdateResponse?.message);
      setActionView({
        show: false,
      });
      const { advertising_type, start_date, end_date } = filters;
      const obj = {
        advertising_type: advertising_type,
        start_date: !dateCurrent?.start_date
          ? null
          : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
        end_date: !dateCurrent?.end_date
          ? null
          : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
        request_type: "prev_count",
        marketplace_id: selectedMarketplace,
      };
      const filterV = Object?.entries(filterValues)?.filter(
        (d) => d?.[0] !== "filter_condition"
      );
      setTableLoading(true);
      setTableList({});
      CampaignsAnalyticsAllTableDataAction(
        obj,
        filterV?.[0]?.[0],
        filterV?.[0]?.[1],
        filterValues?.filter_condition
      );
      fakeActionCampaignsAnalytics("BulkActionUpdateResponse");
    } else if (BulkActionUpdateResponse?.status === false) {
      message.destroy();
      message.error(BulkActionUpdateResponse?.message);
      fakeActionCampaignsAnalytics("BulkActionUpdateResponse");
    }
  }, [BulkActionUpdateResponse]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    const { advertising_type, start_date, end_date } = filters;
    const obj = {
      advertising_type: advertising_type,
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "current_count",
      marketplace_id: selectedMarketplace,
    };
    // setPerformanceMetricsList({});
    setPerformanceMetricsLoading(true);
    CampaignsAnalyticsPerformanceMetricsAction(obj);
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    filters?.advertising_type,
    selectedMarketplace,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    setActiveCompaignsLoading(true);
    // setActiveCompaignsList({});
    // two date
    ActiveCompaignsAction({
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      request_type: "prev_count",
      marketplace_id: selectedMarketplace,
    });
  }, [filters?.end_date, filters?.start_date, selectedMarketplace]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    setActiveCompaignsLoading(true);
    // setActiveCampaign({});
    // two date
    ActiveCompaignsAction({
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),
      request_type: "current_count",
      marketplace_id: selectedMarketplace,
    });
  }, [dateCurrent?.end_date, dateCurrent?.start_date, selectedMarketplace]);
  useEffect(() => {
    if (dailyPerformanceTrendList?.length !== 0 && metricsData?.length !== 0) {
      const series_ = [];

      metricsData?.forEach((e) => {
        const isExit = dailyPerformanceTrendList?.filter((d) => d?.[e?.value]);

        if (isExit?.length === 0) return;
        series_?.push({
          name: e?.label,
          data: dailyPerformanceTrendList?.map((d) =>
            parseFloat(d?.[e?.value === "acos" ? "acos_percentage" : e?.value])
          ),
        });
      });
      setSeriesList(series_);
    }
  }, [dailyPerformanceTrendList, metricsData]);
  useEffect(() => {
    if (graphCurrentData?.length !== 0 && metricsData?.length !== 0) {
      const series__ = [];
      metricsData?.forEach((e) => {
        const isExit = graphCurrentData?.filter((d) => d?.[e?.value]);

        if (isExit?.length === 0) return;
        series__?.push({
          name: e?.label,
          data: graphCurrentData?.map((d) =>
            parseFloat(d?.[e?.value === "acos" ? "acos_percentage" : e?.value])
          ),
        });
      });
      setGraphSeriesList(series__);
    }
  }, [graphCurrentData, metricsData]);
  useEffect(() => {
    if (!selectedMarketplace) return;
    setTargeting({});
    setTargetingLoading(true);
    DashboardTargetingCardDataAction({
      start_date: !dateCurrent?.start_date
        ? null
        : dayjs(dateCurrent?.start_date).format("YYYY-MM-DD"),
      end_date: !dateCurrent?.end_date
        ? null
        : dayjs(dateCurrent?.end_date).format("YYYY-MM-DD"),

      request_type: "Current",
      marketplace_id: selectedMarketplace,
      advertising_type: filters?.advertising_type,
    });
  }, [
    dateCurrent?.end_date,
    dateCurrent?.start_date,
    selectedMarketplace,
    filters?.advertising_type,
  ]);
  useEffect(() => {
    if (!selectedMarketplace) return;

    setTargetingPrev({});
    setTargetingCurrantLoading(true);
    DashboardTargetingCardDataAction({
      start_date: !filters?.start_date
        ? null
        : dayjs(filters?.start_date).format("YYYY-MM-DD"),
      end_date: !filters?.end_date
        ? null
        : dayjs(filters?.end_date).format("YYYY-MM-DD"),
      request_type: "Previous",
      marketplace_id: selectedMarketplace,
      advertising_type: filters?.advertising_type,
    });
  }, [
    filters?.end_date,
    filters?.start_date,
    selectedMarketplace,
    filters?.advertising_type,
  ]);
  // two type date
  useEffect(() => {
    if (ActiveCompaignsDataResponse?.status === true) {
      if (ActiveCompaignsDataResponse?.data?.request_type === "prev_count") {
        setActiveCampaign(ActiveCompaignsDataResponse?.data || {});
      } else {
        setActiveCompaignsList(ActiveCompaignsDataResponse?.data || {});
      }

      setActiveCompaignsLoading(false);
      fakeActionCampaignsAnalytics("ActiveCompaignsDataResponse");
    } else if (ActiveCompaignsDataResponse?.status === false) {
      setActiveCompaignsList({});
      setActiveCampaign({});
      setActiveCompaignsLoading(false);
      fakeActionCampaignsAnalytics("ActiveCompaignsDataResponse");
    }
  }, [ActiveCompaignsDataResponse]);
  useEffect(() => {
    if (PerformanceMetricsResponse?.status === true) {
      if (PerformanceMetricsResponse?.data?.request_type === "prev_count") {
        setPerformancePrev(PerformanceMetricsResponse?.data);
      } else {
        setPerformanceMetricsList(PerformanceMetricsResponse?.data);
      }

      setPerformanceMetricsLoading(false);
      fakeActionCampaignsAnalytics(
        "CampaignsAnalyticsPerformanceMetricsResponse"
      );
    } else if (PerformanceMetricsResponse?.status === false) {
      setPerformanceMetricsList({});
      setPerformanceMetricsLoading(false);
      fakeActionCampaignsAnalytics(
        "CampaignsAnalyticsPerformanceMetricsResponse"
      );
    }
  }, [PerformanceMetricsResponse]);
  useEffect(() => {
    if (AllCompaignsDataResponse?.status === true) {
      message.destroy();
      setTableList(AllCompaignsDataResponse?.data?.data || {});
      setTableLoading(false);

      fakeActionCampaignsAnalytics("AllCompaignsDataResponse");
    } else if (AllCompaignsDataResponse?.status === false) {
      setTableLoading(false);
      setTableList({});
      fakeActionCampaignsAnalytics("AllCompaignsDataResponse");
    }
  }, [AllCompaignsDataResponse]);
  // graph
  useEffect(() => {
    if (DailyPerformanceTrendResponse?.status === true) {
      // setDailyPerformanceTrendList(DailyPerformanceTrendResponse?.data);
      if (
        DailyPerformanceTrendResponse?.data?.request_type !== "current_count"
      ) {
        setSeriesList([]);
        setDailyPerformanceTrendList(DailyPerformanceTrendResponse?.data?.data);
      } else {
        setGraphSeriesList([]);
        setGraphCurrentData(DailyPerformanceTrendResponse?.data?.data);
      }
      setDailyPerformanceTrendLoading(false);

      fakeActionCampaignsAnalytics(
        "CampaignsAnalyticsDailyPerformanceTrendResponse"
      );
    } else if (DailyPerformanceTrendResponse?.status === false) {
      setDailyPerformanceTrendList([]);
      setGraphCurrentData([]);
      setDailyPerformanceTrendLoading(false);
      message.destroy();
      message.warning(DailyPerformanceTrendResponse?.message);
      fakeActionCampaignsAnalytics(
        "CampaignsAnalyticsDailyPerformanceTrendResponse"
      );
    }
  }, [DailyPerformanceTrendResponse]);
  useEffect(() => {
    if (UpdateCampaignBudgetResponse?.status === true) {
      setTableList({
        table_column_headers: tableList?.table_column_headers,
        table_data: (tableList?.table_data || []).map((d) => ({
          ...d,
          campaign_budget_amount:
            d?.campaign_id === UpdateCampaignBudgetResponse?.data?.campaign_id
              ? UpdateCampaignBudgetResponse?.data?.budget
              : d?.campaign_budget_amount_new || d?.campaign_budget_amount,
          edit:
            d?.campaign_id === UpdateCampaignBudgetResponse?.data?.campaign_id
              ? false
              : d?.edit,
        })),
      });
      message.destroy();
      message.success(UpdateCampaignBudgetResponse?.message);
      fakeActionCampaignsAnalytics("UpdateCampaignBudgetResponse");
    } else if (UpdateCampaignBudgetResponse?.status === false) {
      message.destroy();
      message.error(UpdateCampaignBudgetResponse?.message);
      fakeActionCampaignsAnalytics("UpdateCampaignBudgetResponse");
    }
  }, [UpdateCampaignBudgetResponse]);
  const options = {
    chart: {
      height: 350,
      type: "line",
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: false,
      },
      dropShadow: {
        enabled: true,
        color: "#000",
        top: 15,
        left: 7,
        blur: 10,
        opacity: 0.1,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      lineCap: "butt",
      width: 3,
      dashArray: 0,
    },
    xaxis: {
      categories: (graphType === "Current"
        ? graphCurrentData || []
        : dailyPerformanceTrendList || []
      )?.map((d) => dayjs(d?.report_date, "YYYY-MM-DD").format("MMM DD, YYYY")),
      title: {
        text: "Month",
      },
    },
    // xaxis: {
    //   categories:
    //     dailyPerformanceTrendList?.length === 0
    //       ? []
    //       : (dailyPerformanceTrendList || [])?.map((d) => d?.report_date),
    //   title: {
    //     text: "Month",
    //   },
    // },
    yaxis: {
      labels: {
        formatter: function (value) {
          return FormatNumber(parseFloat(value).toFixed(2));
        },
      },
    },
    colors: [
      "#FF5733",
      "#1ABC9C",
      "#3498DB",
      "#E74C3C",
      "#9B59B6",
      "#F1C40F",
      "#27AE60",
      "#34495E",
      "#E67E22",
      "#2ECC71",
      "#F39C12",
      "#2980B9",
      "#D35400",
      "#8E44AD",
      "#C0392B",
      "#1E8449",
      "#ECF0F1",
      "#D35400",
      "#3498DB",
      "#2C3E50",
    ],
    tooltip: {
      x: {
        format: "dd/MM/yy HH:mm",
      },

      y: {
        formatter: function (
          value,

          { series, seriesIndex, dataPointIndex, w }
        ) {
          const withTitle_ = w?.config?.series?.[seriesIndex];

          const pr =
            withTitle_?.name === "ACOS" ||
            withTitle_?.name === "CTR" ||
            withTitle_?.name === "Ad Sales Percentage"
              ? "%"
              : "";
          const Sign =
            withTitle_?.name === "Spend" ||
            withTitle_?.name === "CPC" ||
            withTitle_?.name === "Total Sales"
              ? SIGN()
              : "";
          return Sign + value?.toLocaleString() + pr;
        },
      },
    },
  };
  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
  ];

  const obj = {
    "Sponsored Products": "SP",
    "Sponsored Brands": "SB",
    "Sponsored Display": "SD",
  };

  function addCommasToNumber(number) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  const columns = [
    {
      keyTitle: "type",
      defaultTitle: "Type",

      align: "left",
      render: (text) => {
        return <span>{obj?.[text?.type]}</span>;
      },
    },

    {
      keyTitle: "campaign_status",
      defaultTitle: "Campaign Status",

      align: "left",
      render: (text) => {
        return (
          <Tag
            style={{ textTransform: "capitalize" }}
            color={
              text?.campaign_status?.toLowerCase() === "enabled"
                ? "green"
                : "yellow"
            }
          >
            {text?.campaign_status?.toLowerCase() || "-"}
          </Tag>
        );
      },
    },
    {
      keyTitle: "campaign_name",
      defaultTitle: "Campaign Name",

      align: "left",
      width: 300,
      render: (text) => {
        return <span>{text?.campaign_name || "-"}</span>;
      },
    },
    {
      keyTitle: "campaign_budget_amount",
      defaultTitle: "Campaign Budget",
      sorter: (a, b) =>
        parseInt(a.campaign_budget_amount || 0) -
        parseInt(b.campaign_budget_amount || 0),
      align: "center",
      width: 280,
      render: (text, row) => {
        if (!text?.edit) {
          return (
            <div className="d-flex align-items-center justify-content-center">
              {SIGN()}
              {(text?.campaign_budget_amount || 0).toLocaleString()}
              <Icons
                onClick={() => {
                  setTableList({
                    table_column_headers: tableList?.table_column_headers,
                    table_data: (tableList?.table_data || []).map((d) => ({
                      ...d,
                      campaign_budget_amount_new:
                        d?.campaign_id === text?.campaign_id
                          ? d?.campaign_budget_amount
                          : d?.campaign_budget_amount_new ||
                            d?.campaign_budget_amount,
                      edit:
                        d?.campaign_id === text?.campaign_id
                          ? !d?.edit
                          : d?.edit,
                    })),
                  });
                }}
                className="ms-3"
                type="edit"
              />
            </div>
          );
        }
        return (
          <>
            <div className="d-flex align-items-center justify-content-center">
              <InputNumber
                value={text?.campaign_budget_amount_new}
                className="w-100px"
                onChange={(e) => {
                  setTableList({
                    table_column_headers: tableList?.table_column_headers,
                    table_data: (tableList?.table_data || []).map((d) => ({
                      ...d,

                      campaign_budget_amount_new:
                        d?.campaign_id === text?.campaign_id
                          ? e || ""
                          : d?.campaign_budget_amount_new ||
                            d?.campaign_budget_amount,
                    })),
                  });
                }}
              />
              <Icons
                type="check"
                style={{
                  opacity:
                    row?.campaign_budget_amount_new ==
                      row?.campaign_budget_amount ||
                    row?.campaign_budget_amount_new < 1
                      ? 0.5
                      : 1,
                }}
                onClick={() => {
                  const {
                    campaign_id,
                    campaign_budget_amount_new,
                    campaign_budget_amount,
                  } = row;
                  if (
                    campaign_budget_amount === campaign_budget_amount_new ||
                    campaign_budget_amount_new < 1
                  ) {
                    return;
                  }
                  const obj_ = {
                    marketplace_id: selectedMarketplace,
                    advertising_type: obj?.[row?.type],
                    campaign_id: campaign_id,
                    new_budget: campaign_budget_amount_new,
                    old_budget: campaign_budget_amount,
                  };
                  message.destroy();
                  message.loading("Loading...", 0);
                  UpdateCampaignBugestAction(obj_);
                }}
                className="ms-5"
              />
              <Icons
                onClick={() => {
                  setTableList({
                    table_column_headers: tableList?.table_column_headers,
                    table_data: (tableList?.table_data || []).map((d) => ({
                      ...d,
                      campaign_budget_amount_new: d?.campaign_budget_amount,
                      campaign_budget_amount_new:
                        d?.campaign_id === text?.campaign_id
                          ? d?.campaign_budget_amount
                          : d?.campaign_budget_amount_new ||
                            d?.campaign_budget_amount,
                      edit:
                        d?.campaign_id === text?.campaign_id ? false : d?.edit,
                    })),
                  });
                }}
                type="cross"
                className="ms-2"
              />
              {/* <Icons
              onClick={() => {
                setTableList({
                  table_column_headers: tableList?.table_column_headers,
                  table_data: (tableList?.table_data || []).map((d) => ({
                    ...d,
                    edit:
                      d?.campaign_id === text?.campaign_id ? !d?.edit : d?.edit,
                  })),
                });
              }}
              className="ms-3"
              type="edit"
            /> */}
            </div>
            {text?.campaign_budget_amount_new < 1 && (
              <div
                style={{
                  position: "relative",
                  fontSize: "12px",
                  top: "5px",
                  width: "239px",
                }}
                className="d-flex"
              >
                <small
                  style={{
                    color: "#ff2424",
                  }}
                >
                  !Enter a budget of at least {SIGN()}1.00
                </small>
                <div
                  style={{
                    color: "#1e93c6",
                    cursor: "pointer",

                    marginLeft: "10px",
                  }}
                  onClick={() => {
                    setTableList({
                      table_column_headers: tableList?.table_column_headers,
                      table_data: (tableList?.table_data || []).map((d) => ({
                        ...d,

                        campaign_budget_amount_new:
                          d?.campaign_id === text?.campaign_id
                            ? 1 || ""
                            : d?.campaign_budget_amount_new ||
                              d?.campaign_budget_amount,
                      })),
                    });
                  }}
                >
                  Quick fix
                </div>
              </div>
            )}
          </>
        );
      },
    },
    {
      keyTitle: "campaign_bidding_strategy",
      defaultTitle: "Campaign Bidding Strategy",

      align: "left",
      render: (text) => {
        return <span>{text?.campaign_bidding_strategy}</span>;
      },
    },
    {
      keyTitle: "start_date",
      defaultTitle: "Start Date",
      sorter: (a, b) => new Date(a.start_date) - new Date(b.start_date),
      align: "left",
      render: (text) => {
        return <span>{text?.start_date}</span>;
      },
    },
    {
      keyTitle: "end_date",
      defaultTitle: "End Date",
      sorter: (a, b) => new Date(a.end_date) - new Date(b.end_date),
      align: "left",
      render: (text) => {
        return <span>{text?.end_date}</span>;
      },
    },
    {
      keyTitle: "impression",
      defaultTitle: "Impressions",
      sorter: (a, b) =>
        parseFloat(a.impressions || 0) - parseFloat(b.impressions || 0),
      align: "left",
      render: (text) => {
        return (
          <span>{addCommasToNumber(parseFloat(text?.impressions || 0))}</span>
        );
      },
    },
    {
      keyTitle: "clicks",
      defaultTitle: "Clicks",
      sorter: (a, b) => parseFloat(a.clicks || 0) - parseFloat(b.clicks || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.clicks)}</span>;
      },
    },
    {
      keyTitle: "unit_ordered",
      defaultTitle: "Unit Ordered",
      sorter: (a, b) =>
        parseFloat(a.unit_ordered || 0) - parseFloat(b.unit_ordered || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.unit_ordered)}</span>;
      },
    },

    {
      keyTitle: "spend",
      defaultTitle: "Spend",
      sorter: (a, b) => parseFloat(a.spend || 0) - parseFloat(b.spend || 0),
      align: "left",
      render: (text) => {
        return (
          <span>
            {SIGN()}
            {addCommasToNumber(text?.spend)}
          </span>
        );
      },
    },
    {
      keyTitle: "sales",
      defaultTitle: "Sales",
      sorter: (a, b) => parseFloat(a.sales || 0) - parseFloat(b.sales || 0),
      align: "left",
      render: (text) => {
        return (
          <span>
            {SIGN()}
            {addCommasToNumber(text?.sales)}
          </span>
        );
      },
    },
    {
      keyTitle: "CPC",
      defaultTitle: "Cost-per-click (CPC)",
      sorter: (a, b) => parseFloat(a.CPC || 0) - parseFloat(b.CPC || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.CPC)} </span>;
      },
    },
    {
      keyTitle: "CTR",
      defaultTitle: "Click Through Rate (CTR)",
      sorter: (a, b) => parseFloat(a.CTR || 0) - parseFloat(b.CTR || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.CTR)}%</span>;
      },
    },
    {
      keyTitle: "CVR",
      defaultTitle: "Conversion Rate",
      sorter: (a, b) => parseFloat(a.CVR || 0) - parseFloat(b.CVR || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.CVR)}</span>;
      },
    },
    {
      keyTitle: "ROAS",
      defaultTitle: "Return on Ad spend (ROAS)",
      sorter: (a, b) => parseFloat(a.ROAS || 0) - parseFloat(b.ROAS || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.ROAS)}</span>;
      },
    },
    {
      keyTitle: "ACoS_percentage",
      defaultTitle: "Advertising cost of sales (ACOS %)",
      sorter: (a, b) =>
        parseFloat(a.ACoS_percentage || 0) - parseFloat(b.ACoS_percentage || 0),
      align: "left",
      render: (text) => {
        return <span>{addCommasToNumber(text?.ACoS_percentage)}%</span>;
      },
    },
    {
      keyTitle: "top_of_search_impression_share",
      defaultTitle: "Top-of-search impression share (IS)",

      align: "left",
      render: (text) => {
        return <span>{text?.top_of_search_impression_share}</span>;
      },
    },
    {
      keyTitle: "campaign_id",
      defaultTitle: "Campaign ID",

      align: "left",
      render: (text) => {
        return <span>{text?.campaign_id}</span>;
      },
    },
    {
      keyTitle: "new_to_brand_orders",
      defaultTitle: "New-to-brand (NTB) orders",

      align: "left",
      render: (text) => {
        return <span>{text?.new_to_brand_orders}</span>;
      },
    },
    {
      keyTitle: "percentage_of_order_new_to_brand",
      defaultTitle: "% of orders new-to-brand (NTB)",

      align: "left",
      render: (text) => {
        return <span>{text?.percentage_of_order_new_to_brand}</span>;
      },
    },
    {
      keyTitle: "new_to_brand_sales",
      defaultTitle: "New-to-brand (NTB) sales",

      align: "left",
      render: (text) => {
        return <span>{text?.new_to_brand_sales}</span>;
      },
    },
    {
      keyTitle: "percentage_of_sales_new_to_brand",
      defaultTitle: "% of sales new-to-brand (NTB)",

      align: "left",
      render: (text) => {
        return <span>{text?.percentage_of_sales_new_to_brand}</span>;
      },
    },
  ];

  const hideColumns = [
    "new_to_brand_orders",
    "percentage_of_order_new_to_brand",
    "new_to_brand_sales",
    "percentage_of_sales_new_to_brand",
  ];

  const FilterDataObj = {
    portfolio_id: {
      data: portfoliosList,
      componentType: "select",
    },
    campaign_type: {
      data: [
        { label: "Manual", value: "MANUAL" },
        { label: "Automatic", value: "AUTO" },
      ],
      componentType: "radio",
    },
    campaign_status: {
      data: [
        { label: "Enabled", value: "ENABLED" },
        { label: "Paused", value: "PAUSED" },
        { label: "Archived", value: "ARCHIVED" },
      ],
      componentType: "radio",
    },
    acos: {
      data: operatorList,
      componentType: "custom",
    },
    sales: {
      data: operatorList,
      sign: true,
      componentType: "custom",
    },
    spends: {
      data: operatorList,
      sign: true,
      componentType: "custom",
    },
    impressions: {
      data: operatorList,
      componentType: "custom",
    },
    ctr: {
      data: operatorList,
      componentType: "custom",
    },
    clicks: {
      data: operatorList,
      componentType: "custom",
    },
    unit_ordered: {
      data: operatorList,
      sign: true,
      componentType: "custom",
    },
    budget: {
      data: operatorList,
      sign: true,
      componentType: "custom",
    },
    conversion_rate: {
      data: operatorList,
      componentType: "custom",
    },
  };

  const filterClick = (type) => {
    setFiltersModals({
      show: true,
      apiKey: type?.value,
      modalLabel: type?.label,
      ...FilterDataObj?.[type?.value],
    });
  };

  const items_ = filterList?.map((d, i) => {
    return {
      key: i + 1,
      label: <a onClick={() => filterClick(d)}>{d?.label}</a>,
    };
  });

  const items = [
    {
      title: "Update Budget",
      value: "update_budget",
    },
    {
      title: "Enable Rule set / Automation",
      value: "all_rule",
    },
    {
      title: "Add Portfolio",
      value: "add_portfolio",
    },
    {
      title: "Update Campaign State",
      value: "update_campaign_state",
    },
    {
      title: "Configure Negative Targeting",
      value: "",
    },
    {
      title: "Apply Bid Management Rule Set",
      value: "bid_rule",
    },
    {
      title: "Apply Campaign Management/Budget Rules",
      value: "budget_rule",
    },
  ]?.map((d, i) => {
    return {
      key: i + 1,
      label: (
        <div
          onClick={() => {
            if (!d?.value) return;
            setActionView({
              show: true,
              action: d?.value,
              marketplace_id: selectedMarketplace,
              advertising_type: "SP",
              data: selectedRowKeys,
              title: d?.title,
            });
          }}
        >
          {d?.title}
        </div>
      ),
    };
  });

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const onSelectChange = (_, row) => {
    setSelectedRowKeys(row);
  };

  const rowSelection = {
    selectedRowKeys: selectedRowKeys?.map((d) => d?.campaign_id) || [],
    onChange: onSelectChange,
  };

  const prLogic = (newvalue, oldvalue, type) => {
    const value =
      newvalue === 0 && oldvalue === 0
        ? 0
        : parseFloat(((newvalue - oldvalue) / (oldvalue || 1)) * 100).toFixed(
            2
          );

    if (value === 0) {
      return (
        <span className=" fs-base">
          <i className="ki-outline ki-arrow-down fs-3 text-warning" />
          {value}%
        </span>
      );
    } else if (value < 0) {
      return (
        <span className=" d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    }
    return (
      <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
        + {value}% <i className="ki-outline ki-arrow-up fs-3 text-success" />
      </span>
    );
  };

  useEffect(() => {
    setMarketplaceLoading(true);
    setMarketplaceList([]);
    GetMarketplaceListAction();

    return () => {};
  }, []);

  const [searchText, setSearchText] = useState("");

  const tableData = tableList?.table_data
    ?.filter((items) => {
      return (
        items?.campaign_name
          ?.toLowerCase()
          ?.replace(/\s/g, "")
          .includes(searchText.toLowerCase()?.replace(/\s/g, "")) ||
        items?.campaign_id
          ?.toLowerCase()
          ?.replace(/\s/g, "")
          .includes(searchText.toLowerCase()?.replace(/\s/g, ""))
      );
    })
    ?.sort((a, b) => a?.campaign_name?.localeCompare(b?.campaign_name));

  const prLogic_ = (newvalue, oldvalue, type) => {
    const value =
      newvalue === 0 && oldvalue === 0
        ? 0
        : parseFloat(((newvalue - oldvalue) / (oldvalue || 1)) * 100).toFixed(
            2
          );
    if (value === 0) {
      return (
        <span className=" fs-base">
          <i className="ki-outline ki-arrow-down fs-3 text-warning" />
          {value}%
        </span>
      );
    } else if (value < 0) {
      return (
        <span className=" d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-danger">
          {value}% <i className="ki-outline ki-arrow-down fs-3 text-danger" />
        </span>
      );
    }
    return (
      <span className=" d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
        + {value}% <i className="ki-outline ki-arrow-up fs-3 text-success" />
      </span>
    );
  };

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div
              className="d-flex flex-wrap gap-3 mb-0"
              style={{
                position: "sticky",
                top: "0px",
                zIndex: 999,
                background: "rgb(249 248 248 / 0%)",
                padding: "10px 0px",
                marginTop: "-20px",
                backdropFilter: "blur(23px)",
              }}
            >
              <div className="position-relative d-grid">
                <label>Previous</label>
                <RangePicker
                  size="large"
                  suffixIcon={
                    <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                    </i>
                  }
                  presets={rangePresets}
                  format="MMM, DD YYYY"
                  value={
                    filters?.start_date && filters?.end_date
                      ? [filters.start_date, filters.end_date]
                      : []
                  }
                  onChange={(e) => {
                    setFilters({
                      ...filters,
                      start_date: !e ? null : e?.[0] || null,
                      end_date: !e ? null : e?.[1] || null,
                    });
                  }}
                  allowClear={false}
                  className="light-range-picker"
                  style={{
                    width: "280px",
                  }}
                />
              </div>
              <div className="position-relative d-grid">
                <label>Current</label>
                <RangePicker
                  suffixIcon={
                    <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                      <span className="path1" />
                      <span className="path2" />
                      <span className="path3" />
                      <span className="path4" />
                      <span className="path5" />
                      <span className="path6" />
                    </i>
                  }
                  size="large"
                  presets={rangePresets}
                  format="MMM, DD YYYY"
                  value={
                    dateCurrent?.start_date && dateCurrent?.end_date
                      ? [dateCurrent.start_date, dateCurrent.end_date]
                      : []
                  }
                  onChange={(e) => {
                    setDateCurrent({
                      ...dateCurrent,
                      start_date: !e ? null : e?.[0] || null,
                      end_date: !e ? null : e?.[1] || null,
                    });
                  }}
                  className="light-range-picker"
                  style={{
                    width: "280px",
                  }}
                />
              </div>
              <div className="position-relative d-grid">
                <label style={{ opacity: 0 }}>-</label>
                <Select
                  showSearch
                  allowClear
                  size="large"
                  placeholder="Select Option"
                  mode="multiple"
                  maxTagCount="responsive"
                  value={filters?.match_type || []}
                  onChange={(e) => {
                    let filters_ = { ...filters };
                    filters_ = {
                      ...filters_,
                      match_type: e?.length !== 0 ? e : [],
                    };
                    setFilters(filters_);
                  }}
                  loading={optionLoader}
                  style={{
                    width: "200px",
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={matchOptionList}
                  className="fs-7"
                />
              </div>
              <div className="position-relative d-grid">
                <label style={{ opacity: 0 }}>-</label>
                <Select
                  showSearch
                  allowClear
                  mode="multiple"
                  size="large"
                  maxTagCount="responsive"
                  placeholder="Select Option"
                  loading={filterLoading}
                  value={filters?.advertising_type || []}
                  onChange={(e) => {
                    let filters_ = { ...filters };
                    filters_ = {
                      ...filters_,
                      advertising_type: e?.length !== 0 ? e : [],
                    };
                    setFilters(filters_);
                  }}
                  style={{
                    width: "200px",
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={advertisingTypeData}
                  className="fs-7"
                />
              </div>
              <div className="position-relative d-grid">
                <label style={{ opacity: 0 }}>-</label>
                <Select
                  size="large"
                  placeholder="Select Marketplace"
                  loading={marketplaceLoading}
                  value={selectedMarketplace || null}
                  onChange={(e) => {
                    setSelectedMarketplace(e);
                  }}
                  style={{
                    width: "200px",
                  }}
                  options={marketplaceList?.map((d) => ({
                    label: d?.name,
                    value: d?.id,
                  }))}
                  className="fs-7"
                />
              </div>
            </div>

            <div className="row gx-5 gx-xl-5">
              <div className="col-xl-4 col-lg-6 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {performanceMetricsLoading ? (
                    <CardLoading />
                  ) : Object.keys(performanceMetricsList).length === 0 ||
                    Object.keys(performancePrev).length === 0 ? (
                    <div style={{ minHeight: "200px" }}>
                      <NoData height={"100%"} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="card-header border-bottom-dashed"
                        style={{ minHeight: "50px", background: "#e1f6ff" }}
                      >
                        <h3 className="card-title fw-bold">Performance</h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-2 pb-4 ">
                        <div className="d-flex flex-wrap justify-content-between">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                Spend
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {SIGN()}
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      performancePrev?.spend?.current || 0
                                    )
                                  : "0"}
                                {prLogic(
                                  performanceMetricsList?.spend?.current,
                                  performancePrev?.spend?.current || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {SIGN()}

                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.spend?.current ||
                                        0
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex  flex-wrap justify-content-between">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                Sales
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {SIGN()}
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(
                                        performancePrev?.sales?.current || 0
                                      )
                                    )
                                  : "0"}{" "}
                                {/* {ConvertValue(
                                  performanceMetricsList?.sales
                                    ?.change_percentage
                                )} */}
                                {prLogic(
                                  performanceMetricsList?.sales?.current,
                                  performancePrev?.sales?.current || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {SIGN()}
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.sales?.current ||
                                        0
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex  flex-wrap justify-content-between">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                ACOS
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(
                                        performancePrev?.acos?.current || 0
                                      )
                                    )
                                  : "0"}
                                %{" "}
                                {prLogic(
                                  performanceMetricsList?.acos?.current || 0,
                                  performancePrev?.acos?.current || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.acos?.current || 0
                                    )
                                  )
                                : "0"}
                              %
                            </h4>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xl-4 col-lg-6 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {performanceMetricsLoading ? (
                    <CardLoading />
                  ) : Object.keys(performanceMetricsList).length === 0 &&
                    Object.keys(performancePrev).length === 0 ? (
                    <div style={{ minHeight: "200px" }}>
                      <NoData height={"100%"} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="card-header border-bottom-dashed"
                        style={{ minHeight: "50px", background: "#e1f6ff" }}
                      >
                        <h3 className="card-title fw-bold">Conversion</h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-2 pb-4 ">
                        <div className="d-flex  flex-wrap justify-content-between">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                Clicks
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      performancePrev?.clicks?.current || 0
                                    )
                                  : "0"}{" "}
                                {prLogic(
                                  performanceMetricsList?.clicks?.current || 0,
                                  performancePrev?.clicks?.current || 0
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.clicks?.current ||
                                        0
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex  flex-wrap justify-content-between">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                Orders
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(
                                        performancePrev?.orders?.current || 0
                                      )
                                    )
                                  : "0"}{" "}
                                {/* {ConvertValue(
                                  performanceMetricsList?.orders
                                    ?.change_percentage
                                )} */}
                                {prLogic(
                                  performanceMetricsList?.orders?.current,
                                  performancePrev?.orders?.current
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.orders?.current
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex  flex-wrap justify-content-between">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                CVR
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(performancePrev?.cvr?.current)
                                    )
                                  : "0"}
                                %{" "}
                                {/* {ConvertValue(
                                  performanceMetricsList?.cvr?.change_percentage
                                )} */}
                                {prLogic(
                                  performanceMetricsList?.cvr?.current,
                                  performancePrev?.cvr?.current
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.cvr?.current
                                    )
                                  )
                                : "0"}
                              %
                            </h4>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-xl-4 col-lg-12 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {performanceMetricsLoading ? (
                    <CardLoading />
                  ) : Object.keys(performanceMetricsList).length === 0 &&
                    Object.keys(performancePrev).length === 0 ? (
                    <div style={{ minHeight: "200px" }}>
                      <NoData height={"100%"} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="card-header border-bottom-dashed"
                        style={{ minHeight: "50px", background: "#e1f6ff" }}
                      >
                        <h3 className="card-title fw-bold">Efficiency</h3>
                        <div className="card-toolbar"></div>
                      </div>
                      <div className="card-body pt-2 pb-4 ">
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                CPC
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {SIGN()}
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(performancePrev?.cpc?.current)
                                    )
                                  : "0"}{" "}
                                {prLogic(
                                  performanceMetricsList?.cpc?.current,
                                  performancePrev?.cpc?.current
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {SIGN()}
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.cpc?.current
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SPC
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {SIGN()}
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(performancePrev?.spc?.current)
                                    )
                                  : "0"}{" "}
                                {/* {ConvertValue(
                                  performanceMetricsList?.spc?.change_percentage
                                )} */}
                                {prLogic(
                                  performanceMetricsList?.spc?.current,
                                  performancePrev?.spc?.current
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {SIGN()}
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.spc?.current
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                CTR
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(performancePrev).length > 0
                                  ? FormatNumber(
                                      parseFloat(performancePrev?.ctr?.current)
                                    )
                                  : "0"}
                                %{" "}
                                {/* {ConvertValue(
                                  performanceMetricsList?.ctr?.change_percentage
                                )} */}
                                {prLogic(
                                  performanceMetricsList?.ctr?.current || 0,
                                  performancePrev?.ctr?.current || 0,
                                  "jenish"
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(performanceMetricsList).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      performanceMetricsList?.ctr?.current || 0
                                    )
                                  )
                                : "0"}
                              %
                            </h4>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
            {/* active */}

            <div className="row gx-5 gx-xl-5">
              <div className="col-sm-4 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  {activeCompaignsLoading ? (
                    <CardLoading />
                  ) : Object.keys(activeCompaignsList).length === 0 ||
                    Object?.keys(activeCampaign)?.length === 0 ? (
                    <div style={{ minHeight: "200px" }}>
                      <NoData height={"100%"} />
                    </div>
                  ) : (
                    <>
                      <div
                        className="card-header border-bottom-dashed"
                        style={{ minHeight: "50px", background: "#e1f6ff" }}
                      >
                        <h3 className="card-title fw-bold">Active Campaign</h3>
                        <div className="card-toolbar"></div>
                      </div>

                      <div className="card-body pt-2 pb-4 ">
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SP
                              </a>

                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(activeCampaign?.SP).length > 0
                                  ? FormatNumber(
                                      parseFloat(
                                        activeCampaign?.SP?.current_count || 0
                                      )
                                    )
                                  : "0"}{" "}
                                {/* {ConvertValue(activeCompaignsList?.SP?.change)} */}
                                {prLogic_(
                                  activeCompaignsList?.SP?.current_count,
                                  activeCampaign?.SP?.current_count
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(activeCompaignsList?.SP).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      activeCompaignsList?.SP?.current_count
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SB
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(activeCampaign?.SB).length > 0
                                  ? FormatNumber(
                                      parseFloat(
                                        activeCampaign?.SB?.current_count
                                      )
                                    )
                                  : "0"}{" "}
                                {/* {ConvertValue(activeCampaign?.SB?.current_count)} */}
                                {prLogic_(
                                  activeCompaignsList?.SB?.current_count,
                                  activeCampaign?.SB?.current_count
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {" "}
                              {Object.keys(activeCompaignsList?.SB).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      activeCompaignsList?.SB?.current_count
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                        <div className="separator separator-dashed my-2" />
                        <div className="d-flex flex-stack">
                          <div className="d-flex align-items-center me-5">
                            <div className="me-5">
                              <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                SD
                              </a>
                              <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                {Object.keys(activeCampaign?.SD).length > 0
                                  ? FormatNumber(
                                      parseFloat(
                                        activeCampaign?.SD?.current_count
                                      )
                                    )
                                  : "0"}{" "}
                                {/* {ConvertValue(activeCampaign?.SD?.change)} */}
                                {prLogic_(
                                  activeCompaignsList?.SD?.current_count,
                                  activeCampaign?.SD?.current_count
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="d-flex align-items-center">
                            <h4 className="fw-bold mb-0">
                              {Object.keys(activeCompaignsList?.SD).length > 0
                                ? FormatNumber(
                                    parseFloat(
                                      activeCompaignsList?.SD?.current_count
                                    )
                                  )
                                : "0"}
                            </h4>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="col-sm-8 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  <div
                    className="card-header border-bottom-dashed"
                    style={{ minHeight: "50px", background: "#e1f6ff" }}
                  >
                    <h3 className="card-title fw-bold">Targeting</h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body pt-2 pb-4 ">
                    {targetingLoading || targetingCurrantLoading ? (
                      <Targetloading />
                    ) : (
                      <div className="row">
                        <div className="col-md-6 pe-7">
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-5">
                              <div className="me-5">
                                <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                  Active Keywords
                                </a>

                                <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targetingPrev?.Keyword?.Active?.current ||
                                        0
                                    )
                                  )}
                                  {prLogic(
                                    parseFloat(
                                      targeting?.Keyword?.Active?.current || 0
                                    ),
                                    parseFloat(
                                      targetingPrev?.Keyword?.Active?.current ||
                                        0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <h4 className="fw-bold mb-0">
                                {FormatNumber(
                                  parseFloat(
                                    targeting?.Keyword?.Active?.current || 0
                                  )
                                )}
                              </h4>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-2" />
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-5">
                              <div className="me-5">
                                <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                  Inactive Keywords
                                </a>
                                <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targetingPrev?.Keyword?.InActive
                                        ?.current || 0
                                    )
                                  )}
                                  {prLogic(
                                    parseFloat(
                                      targeting?.Keyword?.InActive?.current || 0
                                    ),
                                    parseFloat(
                                      targetingPrev?.Keyword?.InActive
                                        ?.current || 0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <h4 className="fw-bold mb-0">
                                {FormatNumber(
                                  parseFloat(
                                    targeting?.Keyword?.InActive?.current || 0
                                  )
                                )}
                              </h4>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-2" />
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-5">
                              <div className="me-5">
                                <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                  Active PAT
                                </a>
                                <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targetingPrev?.PAT?.Active?.current || 0
                                    )
                                  )}
                                  {prLogic(
                                    parseFloat(
                                      targeting?.PAT?.Active?.current || 0
                                    ),
                                    parseFloat(
                                      targetingPrev?.PAT?.Active?.current || 0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <h4 className="fw-bold mb-0">
                                {FormatNumber(
                                  parseFloat(
                                    targeting?.PAT?.Active?.current || 0
                                  )
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 ps-7 ms-auto">
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-5">
                              <div className="me-5">
                                <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                  Inactive PAT
                                </a>
                                <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targetingPrev?.PAT?.InActive?.current || 0
                                    )
                                  )}
                                  {prLogic(
                                    parseFloat(
                                      targeting?.PAT?.InActive?.current || 0
                                    ),
                                    parseFloat(
                                      targetingPrev?.PAT?.InActive?.current || 0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <h4 className="fw-bold mb-0">
                                {FormatNumber(
                                  parseFloat(
                                    targeting?.PAT?.InActive?.current || 0
                                  )
                                )}
                              </h4>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-2" />
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-5">
                              <div className="me-5">
                                <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                  Active Auto
                                </a>
                                <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targetingPrev?.Auto?.Active?.current || 0
                                    )
                                  )}
                                  {prLogic(
                                    parseFloat(
                                      targeting?.Auto?.Active?.current || 0
                                    ),
                                    parseFloat(
                                      targetingPrev?.Auto?.Active?.current || 0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <h4 className="fw-bold mb-0">
                                {FormatNumber(
                                  parseFloat(
                                    targeting?.Auto?.Active?.current || 0
                                  )
                                )}
                              </h4>
                            </div>
                          </div>
                          <div className="separator separator-dashed my-2" />
                          <div className="d-flex flex-stack">
                            <div className="d-flex align-items-center me-5">
                              <div className="me-5">
                                <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                                  Inactive Auto
                                </a>
                                <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                  {FormatNumber(
                                    parseFloat(
                                      targetingPrev?.Auto?.InActive?.current ||
                                        0
                                    )
                                  )}
                                  {prLogic(
                                    parseFloat(
                                      targeting?.Auto?.InActive?.current || 0
                                    ),
                                    parseFloat(
                                      targetingPrev?.Auto?.InActive?.current ||
                                        0
                                    )
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex align-items-center">
                              <h4 className="fw-bold mb-0">
                                {FormatNumber(
                                  parseFloat(
                                    targeting?.Auto?.InActive?.current || 0
                                  )
                                )}
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row gx-5">
              <div className="col-xl-12">
                <div className="card card-flush overflow-hidden h-lg-100">
                  <div className="card-header pt-5">
                    <h3 className="card-title align-items-start flex-column">
                      <span className="card-label fw-bold text-dark">
                        Daily Performance Trend
                      </span>
                    </h3>
                    <div className="card-toolbar">
                      <div className="position-relative">
                        <Segmented
                          value={graphType}
                          options={["Previous", "Current"]}
                          className="me-5"
                          onChange={(e) => setGraphType(e)}
                        />
                      </div>
                      <Select
                        showSearch
                        allowClear={false}
                        mode="multiple"
                        size="large"
                        maxTagCount="responsive"
                        value={filters?.metrics || []}
                        onChange={(e) => {
                          if (e?.length === 0) return;
                          let filters_ = { ...filters };
                          if (e?.length !== 0 && e?.length > 4) {
                            message.destroy();
                            message.warning("Select only 4 metrics");
                            return;
                          }
                          filters_ = {
                            ...filters_,
                            metrics:
                              e?.length !== 0
                                ? e?.length > 4
                                  ? filters_?.metrics
                                  : e
                                : [],
                          };
                          setFilters(filters_);
                        }}
                        loading={filterLoading}
                        placeholder="Select Option"
                        style={{
                          width: "300px",
                        }}
                        filterOption={(input, option) =>
                          (option?.label ?? "")
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        options={metricsData}
                        className="fs-7 ms-4"
                      />
                    </div>
                  </div>
                  <div className="card-body d-flex align-items-end p-0">
                    <div
                      id="kt_charts_widget_36"
                      style={{ height: "500px" }}
                      className={`min-h-auto w-100  ps-4 pe-6 ${
                        dailyPerformanceTrendLoading &&
                        `d-flex align-items-center justify-content-center`
                      }`}
                    >
                      {dailyPerformanceTrendLoading ? (
                        <>
                          <Skeleton.Node active>
                            <DotChartOutlined
                              style={{
                                fontSize: 40,
                                color: "#bfbfbf",
                              }}
                            />
                          </Skeleton.Node>
                        </>
                      ) : dailyPerformanceTrendList?.length === 0 ? (
                        <div
                          style={{
                            height: "100%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <NoData />
                        </div>
                      ) : (
                        <LineChart
                          data={(graphType === "Current"
                            ? graphCurrentData || []
                            : dailyPerformanceTrendList || []
                          )?.map((d) =>
                            dayjs(d?.report_date, "YYYY-MM-DD").format(
                              "MMM DD, YYYY"
                            )
                          )}
                          count={
                            graphType === "Current"
                              ? graphSeriesList
                              : seriesList
                          }
                          signs={SIGN()}
                        />
                        // <Chart
                        //   options={options}
                        //   series={
                        //     graphType === "Current"
                        //       ? graphSeriesList
                        //       : seriesList
                        //   }
                        //   type="line"
                        //   height={500}
                        // />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Wrapper className="row gy-5 g-xl-5 mt-2">
              <div className="col-xxl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title"></h3>

                    <div className="card-toolbar">
                      {Object?.keys(filterValues)?.length !== 0 && (
                        <div closeIcon onClose={() => {}}>
                          {getNameFilter()}
                        </div>
                      )}
                      <Input
                        className="w-200px"
                        onChange={(e) => setSearchText(e.target.value)}
                        value={searchText}
                        placeholder="Search by Campaign Name"
                      />

                      <>
                        <Dropdown
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          trigger={["click"]}
                          menu={{
                            items: items_,
                          }}
                          placement="bottomRight"
                          arrow
                        >
                          <div style={{ overflow: "auto", maxHeight: "200px" }}>
                            <Button
                              type="primary"
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Filter
                            </Button>
                          </div>
                        </Dropdown>

                        <Dropdown
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          menu={{
                            items,
                          }}
                          placement="bottomRight"
                          trigger={["hover"]}
                          arrow
                          disabled={selectedRowKeys?.length === 0}
                        >
                          <Button
                            type="dashed"
                            style={{
                              marginLeft: "10px",
                            }}
                          >
                            Action
                          </Button>
                        </Dropdown>
                      </>
                      <svg
                        width={24}
                        height={24}
                        onClick={() => {
                          if (configLoading)
                            return message.warning("Get Config Data...");
                          setConfigModal(true);
                        }}
                        style={{ cursor: "pointer", marginLeft: "10px" }}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          opacity="0.3"
                          d="M22.1 11.5V12.6C22.1 13.2 21.7 13.6 21.2 13.7L19.9 13.9C19.7 14.7 19.4 15.5 18.9 16.2L19.7 17.2999C20 17.6999 20 18.3999 19.6 18.7999L18.8 19.6C18.4 20 17.8 20 17.3 19.7L16.2 18.9C15.5 19.3 14.7 19.7 13.9 19.9L13.7 21.2C13.6 21.7 13.1 22.1 12.6 22.1H11.5C10.9 22.1 10.5 21.7 10.4 21.2L10.2 19.9C9.4 19.7 8.6 19.4 7.9 18.9L6.8 19.7C6.4 20 5.7 20 5.3 19.6L4.5 18.7999C4.1 18.3999 4.1 17.7999 4.4 17.2999L5.2 16.2C4.8 15.5 4.4 14.7 4.2 13.9L2.9 13.7C2.4 13.6 2 13.1 2 12.6V11.5C2 10.9 2.4 10.5 2.9 10.4L4.2 10.2C4.4 9.39995 4.7 8.60002 5.2 7.90002L4.4 6.79993C4.1 6.39993 4.1 5.69993 4.5 5.29993L5.3 4.5C5.7 4.1 6.3 4.10002 6.8 4.40002L7.9 5.19995C8.6 4.79995 9.4 4.39995 10.2 4.19995L10.4 2.90002C10.5 2.40002 11 2 11.5 2H12.6C13.2 2 13.6 2.40002 13.7 2.90002L13.9 4.19995C14.7 4.39995 15.5 4.69995 16.2 5.19995L17.3 4.40002C17.7 4.10002 18.4 4.1 18.8 4.5L19.6 5.29993C20 5.69993 20 6.29993 19.7 6.79993L18.9 7.90002C19.3 8.60002 19.7 9.39995 19.9 10.2L21.2 10.4C21.7 10.5 22.1 11 22.1 11.5ZM12.1 8.59998C10.2 8.59998 8.6 10.2 8.6 12.1C8.6 14 10.2 15.6 12.1 15.6C14 15.6 15.6 14 15.6 12.1C15.6 10.2 14 8.59998 12.1 8.59998Z"
                          fill="currentColor"
                        />
                        <path
                          d="M17.1 12.1C17.1 14.9 14.9 17.1 12.1 17.1C9.30001 17.1 7.10001 14.9 7.10001 12.1C7.10001 9.29998 9.30001 7.09998 12.1 7.09998C14.9 7.09998 17.1 9.29998 17.1 12.1ZM12.1 10.1C11 10.1 10.1 11 10.1 12.1C10.1 13.2 11 14.1 12.1 14.1C13.2 14.1 14.1 13.2 14.1 12.1C14.1 11 13.2 10.1 12.1 10.1Z"
                          fill="currentColor"
                        />
                      </svg>
                    </div>
                  </div>
                  <div className="card-body py-2 pt-0">
                    <div className="table-responsive">
                      {tableLoading && (
                        <TableLoading
                          id="test-table"
                          columns={[1, 2, 3, 4, 5]}
                          checkBoxVal={true}
                          actions={[1, 2]}
                        />
                      )}

                      {!tableLoading &&
                        Object?.keys(tableList)?.length !== 0 && (
                          <Table
                            rowSelection={rowSelection}
                            scroll={{
                              x: "max-content",
                            }}
                            rowKey="campaign_id"
                            dataSource={tableData}
                            columns={[
                              {
                                title: "#",
                                align: "left",
                                show: true,
                                render: (text, d, i) => {
                                  return <span>{i + 1}</span>;
                                },
                              },
                              ...(configList?.length === 0
                                ? columns?.map((d) => ({
                                    ...d,

                                    title: d?.title || d?.defaultTitle,
                                  }))
                                : columns
                                    ?.map((d) => {
                                      const index = configList?.findIndex(
                                        (f) => f === d?.keyTitle
                                      );

                                      return {
                                        ...d,
                                        show:
                                          d?.title === "#"
                                            ? true
                                            : index !== -1,
                                        title: d?.title || d?.defaultTitle,
                                        sortkey: index,
                                      };
                                    })
                                    ?.filter((d) => d?.show)
                              )?.sort((a, b) => a.sortkey - b.sortkey),
                            ]?.filter((d) => {
                              return filters?.advertising_type?.includes("SB")
                                ? d
                                : !hideColumns?.includes(d?.keyTitle);
                            })}
                          />
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </Wrapper>
          </div>
        </div>
      </div>
      {configModal && (
        <ColumnDrawer
          visible={configModal}
          configLoading={configLoading}
          configList={configList}
          onClose={() => {
            setConfigModal(false);
          }}
          hideColumns={hideColumns}
          columnsList={columns?.filter((d) => {
            return filters?.advertising_type?.includes("SB")
              ? d
              : !hideColumns?.includes(d?.keyTitle);
          })}
          advertising_type={filters?.advertising_type}
          {...props}
        />
      )}
      <Targeting
        visible={filtersModals?.Targeting_Type}
        type={filtersModals?.type}
        data={filtersModals?.data}
        selectedMarketplace={selectedMarketplace}
        onClose={() => {
          setFiltersModals({
            ...filtersModals,
            Targeting_Type: false,
          });
        }}
        onApply={() =>
          setFiltersModals({
            ...filtersModals,
            Targeting_Type: false,
          })
        }
      />

      {filtersModals?.show && (
        <FilterModal
          setFiltersModals={setFiltersModals}
          labelGet={setFilterLabel}
          SIGN={SIGN}
          filterLabel={filterLabel}
          filterValues={filterValues_}
          setFilterValues={setFilterValues_}
          setFilterData={(e) => {
            getFilterTable(e);
            setFilterValues(e);
          }}
          {...filtersModals}
        />
      )}
      {actionView?.show && (
        <UpdateModal
          SIGN={SIGN}
          close={() => setSelectedRowKeys([])}
          portfoliosList={portfoliosList}
          setActionView={setActionView}
          selectedMarketplace={selectedMarketplace}
          selectedRowKeys={selectedRowKeys}
          {...actionView}
          {...props}
        />
      )}
      <BudgetModal
        visible={filtersModals?.Budget}
        type={filtersModals?.type}
        data={filtersModals?.data}
        onClose={() => {
          setFiltersModals({
            ...filtersModals,
            Budget: false,
          });
        }}
        onApply={() =>
          setFiltersModals({
            ...filtersModals,
            Budget: false,
          })
        }
      />
    </>
  );
}

export default CampaignsAnalyticsModule;
