import React, { useState } from "react";

function NegativeAsin() {
  const [negativeTab, setNegativeTab] = useState("search");
  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row gy-5 g-xl-5">
              <div className="col-xxl-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">
                      Add Negative ASINs Targeting
                    </h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body py-2">
                    <div className="mt-5 mb-5">
                      <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6 border-bottom-dashed border-gray-300">
                        <li className="nav-item cursor-pointer">
                          <a
                            onClick={() => setNegativeTab("search")}
                            className={`nav-link w-75px text-center ${
                              negativeTab === "search" ? "active" : ""
                            }`}
                          >
                            Search
                          </a>
                        </li>
                        <li className="nav-item cursor-pointer">
                          <a
                            onClick={() => setNegativeTab("list")}
                            className={`nav-link w-75px text-center ${
                              negativeTab === "list" ? "active" : ""
                            }`}
                          >
                            Enter List
                          </a>
                        </li>
                        <li className="nav-item cursor-pointer">
                          <a
                            onClick={() => setNegativeTab("upload")}
                            className={`nav-link w-75px text-center ${
                              negativeTab === "upload" ? "active" : ""
                            }`}
                          >
                            Upload
                          </a>
                        </li>
                      </ul>
                    </div>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className={`tab-pane fade ${
                          negativeTab === "search" ? "show active" : ""
                        }`}
                      >
                        <div>
                          <div className="position-relative mr-4">
                            <span className="svg-icon svg-icon-2 position-absolute top-50 translate-middle-y ms-4">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width={24}
                                height={24}
                                viewBox="0 0 24 24"
                                fill="none"
                              >
                                <rect
                                  opacity="0.5"
                                  x="17.0365"
                                  y="15.1223"
                                  width="8.15546"
                                  height={2}
                                  rx={1}
                                  transform="rotate(45 17.0365 15.1223)"
                                  fill="black"
                                />
                                <path
                                  d="M11 19C6.55556 19 3 15.4444 3 11C3 6.55556 6.55556 3 11 3C15.4444 3 19 6.55556 19 11C19 15.4444 15.4444 19 11 19ZM11 5C7.53333 5 5 7.53333 5 11C5 14.4667 7.53333 17 11 17C14.4667 17 17 14.4667 17 11C17 7.53333 14.4667 5 11 5Z"
                                  fill="black"
                                />
                              </svg>
                            </span>
                            <input
                              type="text"
                              data-kt-table-widget-4="search"
                              className="form-control text-dark fs-6 ps-12 all-search"
                              name="all_search"
                              placeholder="Search by product name or ASIN"
                            />
                          </div>
                          <p className="mb-0 mt-3 text-muted">
                            Search for products you want to exclude.
                          </p>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          negativeTab === "list" ? "show active" : ""
                        }`}
                      >
                        <div className>
                          <textarea
                            className="form-control fs-7"
                            name
                            id
                            cols={30}
                            rows={10}
                            placeholder="Enter ASINs seprated by comma, space or new line."
                            defaultValue={""}
                          />
                          <div className="mt-3 text-end">
                            <button className="btn btn-primary fs-7">
                              Exclude
                            </button>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          negativeTab === "upload" ? "show active" : ""
                        }`}
                      >
                        <p className="mb-3 mt-2 text-muted">
                          Use the template to make sure your information uploads
                          accurately.
                        </p>
                        <div className="mb-5">
                          <a href className="fw-bold text-primary">
                            <i className="bi bi-download text-primary" />{" "}
                            Download the csv template
                          </a>
                        </div>
                        <form className="form" action="#" method="post">
                          <div className="fv-row">
                            <div
                              className="dropzone"
                              id="kt_dropzonejs_example_1"
                            >
                              <div className="dz-message needsclick">
                                <i className="ki-duotone ki-file-up fs-3x text-primary">
                                  <span className="path1" />
                                  <span className="path2" />
                                </i>
                                <div className="ms-4">
                                  <h3 className="fs-5 fw-bold text-gray-900 mb-1">
                                    Drop files here or click to upload.
                                  </h3>
                                  <span className="fs-7 fw-semibold text-gray-400">
                                    Accepted formats: CSV, TSV, XLSX
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-6">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">
                      Negative ASINs Targeting
                    </h3>
                    <div className="card-toolbar">
                      <a className="btn fs-7 btn-light-danger fw-bold btn-sm">
                        Remove All
                      </a>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bolder text-dark">
                            <th className>Brand &amp; Products</th>
                            <th className=" ">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <div className="d-flex flex-column">
                                <span className="fs-7 fw-bolder text-dark">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </span>
                                <span className="text-gray-700 fs-7">
                                  <b>ASIN</b>: BOB7N4KYL9
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex flex-column">
                                <span className="fs-7 fw-bolder text-dark">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </span>
                                <span className="text-gray-700 fs-7">
                                  <b>ASIN</b>: BOB7N4KYL9
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <div className="d-flex flex-column">
                                <span className="fs-7 fw-bolder text-dark">
                                  Lorem Ipsum is simply dummy text of the
                                  printing and typesetting industry.
                                </span>
                                <span className="text-gray-700 fs-7">
                                  <b>ASIN</b>: BOB7N4KYL9
                                </span>
                              </div>
                            </td>
                            <td>
                              <div className="d-flex flex-shrink-0">
                                <a className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm">
                                  <i className="ki-outline ki-trash fs-3 text-danger" />
                                </a>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default NegativeAsin;
