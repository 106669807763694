import { DatePicker, Input, Select } from "antd";
import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
// import { SIGN } from "../../../../../../config";

const disabledDate = (current) => {
  // Disable dates that are in the past or today
  return current && current.valueOf() <= Date.now() - 86400000;
};
const CampaignSpecifications = (props) => {
  const {
    basicDetails,
    setBasicDetails,
    CampaignsPortfoliosListAction,
    fakeActionCampaignsManagementAnalytics,
    loading,
    selectedMarketplaceCreate,
  } = props;
  const [portfloioList, setPortfloioList] = useState([]);
  const [portfloioLoading, setPortfloioLoading] = useState(true);

  const GetPortfoliosListResponse = useSelector(
    (state) => state.CampaignManagement.GetPortfoliosListResponse || {}
  );

  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  useEffect(() => {
    if (GetPortfoliosListResponse?.status === true) {
      setPortfloioList(GetPortfoliosListResponse?.data);
      setPortfloioLoading(false);
      fakeActionCampaignsManagementAnalytics("GetPortfoliosListResponse");
    } else if (GetPortfoliosListResponse?.status === false) {
      setPortfloioList([]);
      setPortfloioLoading(false);
      fakeActionCampaignsManagementAnalytics("GetPortfoliosListResponse");
    }
  }, [GetPortfoliosListResponse]);

  useEffect(() => {
    setPortfloioLoading(true);
    setPortfloioList([]);
    CampaignsPortfoliosListAction({ id: selectedMarketplaceCreate });
    return () => {};
  }, []);
  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

  const disabledDateEnd = (current) => {
    const endDateToDisable = new Date(basicDetails?.campaign_data?.start_date); // Replace with your custom end date
    return current && current.valueOf() < endDateToDisable.valueOf();
  };
  return (
    <div className="campaign-specifications">
      <div className="row d-flex align-items-end">
        <div className="col-8">
          <div className="Campaign-Name">
            <label>Campaign Name Options</label>
            <small className="mb-3">
              Choose a prefix or suffix, these will be added to all campaigns
              that will be created.
            </small>
            <div className="row">
              <div className="col-6">
                <div className="d-grid mb-5 position-relative">
                  <label>
                    Name<small style={{ color: "red" }}>&nbsp;*</small>
                  </label>

                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Prefix"
                    value={basicDetails?.campaign_data?.name || null}
                    onChange={(e) => {
                      setBasicDetails({
                        ...basicDetails,
                        campaign_data: {
                          ...basicDetails?.campaign_data,
                          name: e.target.value,
                        },
                      });
                    }}
                    addonAfter={
                      <Input
                        placeholder="Suffix"
                        onBlur={(e) => {
                          if (!e.target.value) {
                            setBasicDetails({
                              ...basicDetails,
                              campaign_data: {
                                ...basicDetails?.campaign_data,
                                suffix: dayjs(new Date()).format(
                                  "YYYY-MM-DD hh:mm:ss"
                                ),
                              },
                            });
                          }
                        }}
                        onChange={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            campaign_data: {
                              ...basicDetails?.campaign_data,
                              suffix: e.target.value || "",
                            },
                          });
                        }}
                        value={basicDetails?.campaign_data?.suffix || null}
                        className="w-175px"
                      />
                    }
                  />
                  {loading ? (
                    !basicDetails?.campaign_data?.name ||
                    !basicDetails?.campaign_data?.suffix ? (
                      <small
                        style={{
                          color: "#ff2424",
                          position: "relative",
                          top: "5px",
                        }}
                      >
                        Campaign Name is required
                      </small>
                    ) : (
                      <small style={{ opacity: 0 }}>-</small>
                    )
                  ) : (
                    <small style={{ opacity: 0 }}>-</small>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="d-grid mb-5">
                  <label>
                    Portfolio<small style={{ color: "red" }}>&nbsp;*</small>
                  </label>
                  <Select
                    style={{ width: "100%" }}
                    allowClear
                    placeholder="Select Portfolio"
                    loading={portfloioLoading}
                    options={portfloioList?.map((d) => {
                      return {
                        label: d?.name,
                        value: d?.portfolioId?.toString(),
                      };
                    })}
                    size="large"
                    showSearch
                    optionFilterProp="children"
                    filterOption={filterOption}
                    onChange={(e) => {
                      setBasicDetails({
                        ...basicDetails,
                        campaign_data: {
                          ...basicDetails?.campaign_data,
                          portfolio_id: e,
                        },
                      });
                    }}
                    value={basicDetails?.campaign_data?.portfolio_id || null}
                  />
                  {loading ? (
                    !basicDetails?.campaign_data?.portfolio_id ? (
                      <small
                        style={{
                          color: "#ff2424",
                          position: "relative",
                          top: "5px",
                        }}
                      >
                        Portfolio is required
                      </small>
                    ) : (
                      <small style={{ opacity: 0 }}>-</small>
                    )
                  ) : (
                    <small style={{ opacity: 0 }}>-</small>
                  )}
                </div>
              </div>
              <div className="col-6">
                <div className="d-grid mb-5">
                  <div className="row">
                    <div className="col-6">
                      <label>
                        Start Date
                        <small style={{ color: "red" }}>&nbsp;*</small>
                      </label>
                      <DatePicker
                        disabledDate={disabledDate}
                        placeholder="Start Date"
                        onChange={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            campaign_data: {
                              ...basicDetails?.campaign_data,
                              start_date: e
                                ? dayjs(e).format("YYYY-MM-DD")
                                : null,
                            },
                          });
                        }}
                        value={
                          basicDetails?.campaign_data?.start_date
                            ? dayjs(
                                new Date(
                                  basicDetails?.campaign_data?.start_date
                                )
                              )
                            : null
                        }
                        className="date-range"
                        size="large"
                        style={{ width: "100%" }}
                      />
                      {loading ? (
                        !basicDetails?.campaign_data?.start_date ? (
                          <small
                            style={{
                              color: "#ff2424",
                              position: "relative",
                              top: "5px",
                            }}
                          >
                            Start Date is required
                          </small>
                        ) : (
                          <small style={{ opacity: 0 }}>-</small>
                        )
                      ) : (
                        <small style={{ opacity: 0 }}>-</small>
                      )}
                    </div>
                    <div className="col-6">
                      <label>End Date</label>
                      <DatePicker
                        disabledDate={disabledDateEnd}
                        placeholder="End Date"
                        onChange={(e) => {
                          setBasicDetails({
                            ...basicDetails,
                            campaign_data: {
                              ...basicDetails?.campaign_data,

                              end_date: e
                                ? dayjs(e).format("YYYY-MM-DD")
                                : null,
                            },
                          });
                        }}
                        value={
                          basicDetails?.campaign_data?.end_date
                            ? dayjs(
                                new Date(basicDetails?.campaign_data?.end_date)
                              )
                            : null
                        }
                        className="date-range"
                        style={{ width: "100%" }}
                        size="large"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-6">
                <div className="d-grid mb-5">
                  <label>
                    Daily budget<small style={{ color: "red" }}>&nbsp;*</small>
                  </label>
                  <Input
                    size="large"
                    style={{ width: "100%" }}
                    placeholder="Daily budget"
                    addonBefore={SIGN()}
                    onChange={(e) => {
                      setBasicDetails({
                        ...basicDetails,
                        campaign_data: {
                          ...basicDetails?.campaign_data,
                          daily_budget: e.target.value || "",
                        },
                      });
                    }}
                    value={basicDetails?.campaign_data?.daily_budget || null}
                  />

                  {loading ? (
                    !basicDetails?.campaign_data?.daily_budget ? (
                      <small
                        style={{
                          color: "#ff2424",
                          position: "relative",
                          top: "5px",
                        }}
                      >
                        Daily budget is required
                      </small>
                    ) : parseFloat(basicDetails?.campaign_data?.daily_budget) <
                      1 ? (
                      <div
                        style={{
                          position: "relative",

                          top: "5px",
                        }}
                        className="d-flex align-items-center"
                      >
                        <small
                          style={{
                            color: "#ff2424",
                            // position: "relative",
                            // top: "5px",
                          }}
                        >
                          !Enter a budget of at least {SIGN()}1.00
                        </small>
                        <div
                          style={{
                            color: "#1e93c6",
                            cursor: "pointer",

                            marginLeft: "10px",
                          }}
                          onClick={() => {
                            setBasicDetails({
                              ...basicDetails,
                              campaign_data: {
                                ...basicDetails?.campaign_data,
                                daily_budget: 1,
                              },
                            });
                          }}
                        >
                          Quick fix
                        </div>
                      </div>
                    ) : (
                      <small style={{ opacity: 0 }}>-</small>
                    )
                  ) : parseFloat(basicDetails?.campaign_data?.daily_budget) <
                    1 ? (
                    <div
                      style={{
                        position: "relative",

                        top: "5px",
                      }}
                      className="d-flex align-items-center"
                    >
                      <small
                        style={{
                          color: "#ff2424",
                        }}
                      >
                        !Enter a budget of at least {SIGN()}1.00
                      </small>
                      <div
                        style={{
                          color: "#1e93c6",
                          cursor: "pointer",

                          marginLeft: "10px",
                        }}
                        onClick={() => {
                          setBasicDetails({
                            ...basicDetails,
                            campaign_data: {
                              ...basicDetails?.campaign_data,
                              daily_budget: 1,
                            },
                          });
                        }}
                      >
                        Quick fix
                      </div>
                    </div>
                  ) : (
                    <small style={{ opacity: 0 }}>-</small>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-4 other-filed" style={{ height: "fit-content" }}>
          <div className="row">
            <div className="d-grid mb-6">
              <label>Top of search (first page)</label>
              <Input
                size="large"
                className="mw-350px antd-border-add"
                placeholder="Top of search"
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    campaign_data: {
                      ...basicDetails?.campaign_data,
                      top_of_search_placement: e.target.value,
                    },
                  });
                }}
                value={
                  basicDetails?.campaign_data?.top_of_search_placement || null
                }
              />
              <small style={{ opacity: 0 }}>-</small>
            </div>
          </div>
          <div className="row">
            <div className="d-grid mb-5">
              <label>Product pages</label>
              <Input
                size="large"
                className="mw-350px antd-border-add"
                placeholder="Product pages"
                onChange={(e) => {
                  setBasicDetails({
                    ...basicDetails,
                    campaign_data: {
                      ...basicDetails?.campaign_data,
                      product_pages_placement: e.target.value,
                    },
                  });
                }}
                value={
                  basicDetails?.campaign_data?.product_pages_placement || null
                }
              />
              <small style={{ opacity: 0 }}>-</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignSpecifications;
