export default (state, action) => {
  if (typeof state === "undefined") {
    return [];
  }
  switch (action.type) {
    case "GET_APPLICATION_LOG_SYSTEM_LOG_SUCCESS":
    case "GET_APPLICATION_LOG_SYSTEM_LOG_ERROR":
      return {
        ...state,
        GetApplicationLogSystemResponse: action.updatePayload,
      };
    case "FAKE_ACTION_USER_CENTRAL_LOG":
      return { ...state, [action?.state]: {} };
    default:
      return state;
  }
};
