import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, DatePicker, Input, InputNumber, theme } from "antd";
import React from "react";
import AddProduct from "./lib/add-product";
import SelectType from "./lib/select-type";
import Icons from "../../../../../../../components/icons";
import dayjs from "dayjs";
const fakeData = {
  name: null,
  sufix_date: dayjs(new Date()).format("YYYY-MM-DD"),
  default_bid: null,
  state: "ENABLED",
  product_ads: [],
  product_targeting: [],
  keyword_targeting: [],
  product_targeting_manual: [],
};
const AddGroupSpecifications = (props) => {
  const { setBasicDetails, basicDetails, loading } = props;
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 15,
    background: token.colorFillAlter,
    borderRadius: "rgb(255 255 255)",
    border: "none",
  };

  const removeGroup = (index) => {
    if (index >= 0 && index < basicDetails.ad_groups_data.length) {
      setBasicDetails({
        ...basicDetails,
        ad_groups_data: basicDetails.ad_groups_data.filter(
          (_, i) => i !== index
        ),
      });
    }
  };
  const updateGroup = (index, value, type) => {
    if (
      index >= 0 &&
      index < basicDetails.ad_groups_data.length &&
      value !== undefined
    ) {
      const updatedAdGroups = [...basicDetails.ad_groups_data];
      updatedAdGroups[index] = {
        ...updatedAdGroups[index],
        [type]: value,
      };

      setBasicDetails({
        ...basicDetails,
        ad_groups_data: updatedAdGroups,
      });
    }
  };
  const updateName = (index, value, name) => {
    if (
      index >= 0 &&
      index < basicDetails.ad_groups_data.length &&
      name !== undefined
    ) {
      const updatedAdGroups = [...basicDetails.ad_groups_data];
      updatedAdGroups[index] = {
        ...updatedAdGroups[index],
        [name]: value,
      };

      setBasicDetails({
        ...basicDetails,
        ad_groups_data: updatedAdGroups,
      });
    }
  };

  return (
    <Collapse
      bordered={false}
      expandIcon={({ isActive }) => (
        <CaretRightOutlined rotate={isActive ? 90 : 0} />
      )}
      style={{
        background: "transparent",
      }}
      items={basicDetails?.ad_groups_data?.map((d, i) => {
        return {
          key: (i + 1).toString(),
          label: `Group ${i + 1}`,
          children: (
            <div className="position-relative group-view">
              <div className="campaign-specifications mb-3">
                <div className="Campaign-Name">
                  <label>Ad Group Name Options</label>
                  <small className="mb-3">
                    Choose a prefix or suffix, these will be added to all Ad
                    Group that will be created.
                  </small>
                  <div className="row">
                    <div className="col-auto">
                      <div className="d-grid mb-5">
                        <label>
                          Name<small style={{ color: "red" }}>&nbsp;*</small>
                        </label>
                        <Input
                          size="large"
                          className="mw-350px"
                          placeholder="Prefix"
                          value={d?.name}
                          onChange={(e) =>
                            updateName(i, e.target.value, "name")
                          }
                          addonAfter={
                            <DatePicker
                              value={
                                d?.date ? dayjs(d?.date, "YYYY-MM-DD") : null
                              }
                              onChange={(e) =>
                                updateName(
                                  i,
                                  e ? dayjs(e).format("YYYY-MM-DD") : null,
                                  "date"
                                )
                              }
                              className="w-150px"
                            />
                          }
                        />
                        {loading ? (
                          !d?.name || !d?.date ? (
                            <small
                              style={{
                                color: "#ff2424",
                                position: "relative",
                                top: "5px",
                              }}
                            >
                              Group Name is required
                            </small>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div className="d-grid mb-5">
                        <label>
                          Default Bid
                          <small style={{ color: "red" }}>&nbsp;*</small>
                        </label>
                        <InputNumber
                          className="mw-350px min-w-250px"
                          style={{ width: "100%" }}
                          placeholder="Default Bid"
                          value={d?.default_bid}
                          onChange={(e) => {
                            updateGroup(i, e, "default_bid");
                          }}
                          size="large"
                        />
                        {loading ? (
                          !d?.default_bid ? (
                            <small
                              style={{
                                color: "#ff2424",
                                position: "relative",
                                top: "5px",
                              }}
                            >
                              Default Bid is required
                            </small>
                          ) : (
                            ""
                          )
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <AddProduct
                groupIndex={i}
                setBasicDetails={setBasicDetails}
                basicDetails={basicDetails}
                {...props}
              />
              <SelectType groupIndex={i} {...props} />
            </div>
          ),
          style: panelStyle,
          extra: (
            <div className="action-buttons">
              <Icons
                onClick={(e) => {
                  e.stopPropagation();
                  setBasicDetails({
                    ...basicDetails,
                    ...basicDetails?.ad_groups_data.push(fakeData),
                  });
                }}
                type="add-group"
              />
              {basicDetails.ad_groups_data?.length > 1 && (
                <Icons
                  onClick={(e) => {
                    e.stopPropagation();
                    removeGroup(i);
                  }}
                  type="remove-group"
                />
              )}
            </div>
          ),
        };
      })}
      defaultActiveKey={["1"]}
      accordion
    />
  );
};

export default AddGroupSpecifications;
