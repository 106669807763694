import { Button, Input, Table, Tag } from "antd";
import React from "react";
import { SIGN } from "../../../../../../../../config";

const SelectedKeyword = (props) => {
  const {
    basicDetails,
    setBasicDetails,
    groupIndex,
    selectedMarketplaceCreate,
  } = props;
  const RemoveAsin = (productIndex) => {
    if (groupIndex < 0 || groupIndex >= basicDetails.ad_groups_data.length)
      return;

    const adGroup = basicDetails.ad_groups_data[groupIndex];

    if (productIndex < 0 || productIndex >= adGroup.keyword_targeting.length)
      return;

    adGroup.keyword_targeting.splice(productIndex, 1);
    setBasicDetails({ ...basicDetails });
  };

  const onBidChange = (e, row) => {
    // Create a copy of the ad group data
    const updatedAdGroupData = { ...basicDetails };

    // Update the Bid value in the specified keyword targeting
    updatedAdGroupData.ad_groups_data[groupIndex].keyword_targeting[
      row?.key
    ].Bid = e;

    // Update the state with the modified data
    setBasicDetails(updatedAdGroupData);
  };
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };
  const columns = [
    {
      title: "Keyword",
      width: 200,
      render: (e) => {
        return (
          <div className="row">
            <div
              className="col-12 text-overflow"
              style={{ fontWeight: "700", marginBottom: "10px" }}
            >
              {e?.title || "-"}
            </div>
            <div className="col-12">
              <div className="row">
                <div className="col-6">
                  <div className="row">
                    <div className="col">IS&nbsp;:</div>
                    <div className="col">{e?.IS || 0}%</div>
                  </div>
                </div>

                <div className="col-6">
                  <div className="row" style={{ borderLeft: "1px solid grey" }}>
                    <div className="col">IR&nbsp;:</div>
                    <div className="col">{e?.IR || 0}%</div>
                  </div>
                </div>
                <div className="col-6"></div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Match type",
      width: 130,
      render: (e) => {
        return (
          <div className="d-flex">
            <Tag color="blue">{e?.MatchType}</Tag>
          </div>
        );
      },
    },
    {
      title: "Sugg. bid",
      width: 150,
      render: (e) => {
        return (
          <div className="row">
            <div className="col-12">
              {SIGN()}
              {e?.SuggBid?.suggested_bid || 0}
            </div>
            <div>
              {SIGN()}
              {e?.SuggBid?.suggested_bid_range?.start_range}
              {` - `}
              {SIGN()}
              {e?.SuggBid?.suggested_bid_range?.end_range}
            </div>
          </div>
        );
      },
    },
    {
      title: "Bid",
      width: 150,
      render: (row) => {
        return (
          <div className="row">
            <Input
              onChange={(e) => onBidChange(e.target.value, row)}
              prefix={SIGN()}
              value={row?.Bid}
            />
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      width: 100,
      render: (e) => (
        <span
          onClick={() => RemoveAsin(e.key)}
          className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
        >
          <i className="ki-outline ki-trash fs-3 text-danger" />
        </span>
      ),
    },
  ];

  const removeAll = (obj) => {
    const updatedData = { ...basicDetails };
    updatedData.ad_groups_data[groupIndex].keyword_targeting = [];

    setBasicDetails({ ...updatedData });
  };

  return (
    <>
      {basicDetails?.ad_groups_data?.[groupIndex]?.keyword_targeting?.length !==
        0 && (
        <div
          className="row mx-1 p-1 mb-5"
          style={{
            background: "#e4f3fc",
            borderRadius: 7,
            border: "1px dashed #22a9e6",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div className="col-auto">
            {
              basicDetails?.ad_groups_data?.[groupIndex]?.keyword_targeting
                ?.length
            }{" "}
            Selectd Row
          </div>
          <div className="col-auto">
            <Button
              type="ghost"
              onClick={() => {
                removeAll();
              }}
              className="text-danger cursor-pointer text-hover-warning"
            >
              Remove All
            </Button>
          </div>
        </div>
      )}
      <div className="row">
        <Table
          scroll={{ x: "max-content", y: "calc(100vh - 330px)" }}
          dataSource={basicDetails?.ad_groups_data?.[
            groupIndex
          ]?.keyword_targeting?.map((d, i) => {
            return { ...d, key: i };
          })}
          columns={columns}
          rowKey={"key"}
          pagination={false}
        />
      </div>
    </>
  );
};

export default SelectedKeyword;
