import axiosCall from "../../configurations/network-services/axiosCall";

export const GetRuleManagementListAction = (data) => {
  const path = `rule-management/get-rules?page=${data?.page || 1}&per-page=${
    data?.perPage || 10
  }&rule_name=${data?.rule_name || ""}&rule_type=${
    data?.rule_type || ""
  }&rule_status=${data?.rule_status || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "GET_RULE_MANAGEMENT_LIST";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const GetRuleManagementDropdownListAction = () => {
  const path = `rule-management/get-create-rule-dd-values`;
  const responseType = "GET_RULE_MANAGEMENT_DROPDOWN";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const CreateRuleAction = (data) => {
  const path = `rule-management/create-rule`;
  const responseType = "CREATE_NEW_RULE";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateRuleAction = (id, data) => {
  const path = `rule-management/${id}`;
  const responseType = "UPDATE_RULE";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateRuleStatusAction = (id, data) => {
  const path = `rule-management/master-rule-status/${id}`;
  const responseType = "UPDATE_STATUS_RULE";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateSubRuleStatusAction = (id, data) => {
  const path = `rule-management/sub-rule/${id}`;
  const responseType = "UPDATE_SUB_STATUS_RULE";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DeleteRuleAction = (id, type) => {
  const path =
    type === "sub"
      ? `rule-management/${id}`
      : `rule-management/master-rule/${id}`;
  const responseType = "DELETE_RULE";
  return axiosCall("delete", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const getRecommendedEventsAction = (data) => {
  const path = `rule-management/budget/get-recommended-events`;
  const responseType = "GET_RECOMMENDED_EVENT_LIST";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const UpdateMasterRuleAction = (id, data) => {
  const path = `rule-management/${id}`;
  const responseType = "UPDATE_MASTER_RULE";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const GetAppliedRuleAction = (type, data) => {
  const path = `rule-management/get-applied-rule-logs?master_rule_id=${
    data?.id || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 10}`;
  const Rule = `rule-management/get-applied-rule-logs?rule_id=${
    data?.id || ""
  }&page=${data?.page || 1}&per-page=${data?.perPage || 10}`;
  const responseType = "RULE_MANAGEMENT_GET_APPLIED_RULE_LOG";
  return axiosCall("get", type === "master" ? path : Rule, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const fakeActionRuleManagement = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_RULE_MANAGEMENT", state: data });
};
