import React, { useState } from "react";
import { Modal, Form, Input, Select, Button } from "antd";

const { Option } = Select;

const EditModal = ({
  visible,
  onCancel,
  onSave,
  data,
  selectedMarketplaceCreate,
}) => {
  const [form] = Form.useForm();

  // Function to handle form submission
  const handleSave = () => {
    form.validateFields().then((values) => {
      //   onSave(values);
      //   form.resetFields();
    });
  };

  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  return (
    <Modal
      visible={visible}
      title="Edit Campaign Strategy"
      onCancel={onCancel}
      maskClosable={false}
      footer={[
        <Button key="cancel" onClick={onCancel}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical" initialValues={data}>
        <Form.Item
          name="strategy_name"
          label="Strategy Name"
          rules={[{ required: true, message: "Please enter a strategy name" }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="budget_type"
          label="Budget Type"
          rules={[{ required: true, message: "Please select a budget type" }]}
        >
          <Select>
            <Option value="AMOUNT">Amount</Option>
            {/* Add other budget types if needed */}
          </Select>
        </Form.Item>
        <Form.Item
          name="budget_amount"
          label="Budget Amount"
          rules={[{ required: true, message: "Please enter a budget amount" }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="budget_currency"
          label="Budget Currency"
          rules={[
            { required: true, message: "Please select a budget currency" },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="bidding_strategy_type"
          label="Bidding Strategy Type"
          rules={[
            {
              required: true,
              message: "Please select a bidding strategy type",
            },
          ]}
        >
          <Select>
            <Option value="AUTO_FOR_SALES">Auto for Sales</Option>
            {/* Add other bidding strategy types if needed */}
          </Select>
        </Form.Item>
        <Form.Item
          name="top_of_search_placement"
          label="Top of Search Placement"
          rules={[{ required: true, message: "Please enter a value" }]}
        >
          <Input type="number" />
        </Form.Item>
        <Form.Item
          name="product_pages_placement"
          label="Product Pages Placement"
          rules={[{ required: true, message: "Please enter a value" }]}
        >
          <Input type="number" />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditModal;
