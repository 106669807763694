import React, { useEffect, useState } from "react";
import {
  DeleteTwoTone,
  EditTwoTone,
  FundViewOutlined,
} from "@ant-design/icons";
import { DefaultPerPage } from "../../../../../config";
import { useSelector } from "react-redux";
import Pagination from "../../../../../components/pagination";
import { Button, Input, Popconfirm, Select, Table, Tag, message } from "antd";
import { NothingToShow } from "../../../../../components/not-found";
import { TableLoading } from "../../../../../components/table-animation";
import CampaignForm from "./lib/update";
import { Link, useLocation } from "react-router-dom";
import AdGroup from "./lib/adGroup";

const CreationList = (props) => {
  const {
    CampaignListAction,
    CampaignsPortfoliosListAction,
    fakeActionCampaignsManagementAnalytics,
    DeleteCampaignAction,

    marketplaceLoading,
    marketplaceList,
    selectedMarketplaceList,
    setSelectedMarketplaceList,
  } = props;
  const location = useLocation();
  const tableHeaderStyle = {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  };

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignLoading, setCampaignLoading] = useState(true);

  const [campaignName, setCampaignName] = useState("");
  const [filters, setFilters] = useState({
    targeting_type: null,
    campaign_state: null,
  });

  const [updateModal, setUpdateModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [modalType, setModalType] = useState("edit");

  const [portfloioList, setPortfloioList] = useState([]);
  const [portfloioLoading, setPortfloioLoading] = useState(true);

  const ListCampaignsResponse = useSelector(
    (state) => state.CampaignManagement.ListCampaignsResponse || {}
  );
  const CampaignsDeleteResponse = useSelector(
    (state) => state.CampaignManagement.CampaignsDeleteResponse || {}
  );

  const GetPortfoliosListResponse = useSelector(
    (state) => state.CampaignManagement.GetPortfoliosListResponse || {}
  );

  useEffect(() => {
    if (GetPortfoliosListResponse?.status === true) {
      setPortfloioList(GetPortfoliosListResponse?.data);
      setPortfloioLoading(false);
      fakeActionCampaignsManagementAnalytics("GetPortfoliosListResponse");
    } else if (GetPortfoliosListResponse?.status === false) {
      setPortfloioList([]);
      setPortfloioLoading(false);
      fakeActionCampaignsManagementAnalytics("GetPortfoliosListResponse");
    }
  }, [GetPortfoliosListResponse]);

  useEffect(() => {
    if (ListCampaignsResponse?.status === true) {
      setCampaignList(ListCampaignsResponse?.data?.records);
      setTotalPage(ListCampaignsResponse.data.pagination.totalCount);
      setCampaignLoading(false);
      fakeActionCampaignsManagementAnalytics("ListCampaignsResponse");
    } else if (ListCampaignsResponse?.status === false) {
      setCampaignLoading(false);
      fakeActionCampaignsManagementAnalytics("ListCampaignsResponse");
    }
  }, [ListCampaignsResponse]);

  const getTable = () => {
    setCampaignLoading(true);
    setCampaignList([]);
    setPage(1);
    setPageSize(DefaultPerPage);
    CampaignListAction({
      page: 1,
      perPage: DefaultPerPage,
      marketplace_id: selectedMarketplaceList,
      campaign_name: campaignName,
      ...filters,
    });
  };

  useEffect(() => {
    if (CampaignsDeleteResponse?.status === true) {
      message.destroy();
      message.success(CampaignsDeleteResponse?.message);
      getTable();
      fakeActionCampaignsManagementAnalytics("CampaignsDeleteResponse");
    } else if (CampaignsDeleteResponse?.status === false) {
      message.destroy();
      message.error(CampaignsDeleteResponse?.message);
      fakeActionCampaignsManagementAnalytics("CampaignsDeleteResponse");
    }
  }, [CampaignsDeleteResponse]);

  useEffect(() => {
    if (!selectedMarketplaceList) return;
    getTable();
    setPortfloioLoading(true);
    setPortfloioList([]);
    CampaignsPortfoliosListAction({ id: selectedMarketplaceList });
  }, [selectedMarketplaceList]);

  useEffect(() => {
    return () => {};
  }, []);

  const onPageNo = (e) => {
    setCampaignLoading(true);
    setCampaignList([]);
    CampaignListAction({
      page: e,
      perPage: pageSize,
      marketplace_id: selectedMarketplaceList,
      campaign_name: campaignName,
      ...filters,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setCampaignLoading(true);
    CampaignListAction({
      page: 1,
      perPage: e,
      marketplace_id: selectedMarketplaceList,
      campaign_name: campaignName,
      ...filters,
    });
  };
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[selectedMarketplaceList] ||
        ""
      );
    } catch (error) {
      return "$";
    }
  };
  const columns = [
    {
      title: "#",

      width: "25px",
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },

    {
      title: "Campaign Name",
      render: (text) => {
        return <span>{text?.campaign_name || "-"}</span>;
      },
      width: "150px",
    },
    {
      title: "Campaign ID",
      render: (text) => {
        return (
          // <Link to={`/campaign-management`}>
          <Link
            to={`/campaign-management?id=${text?.campaign_id}&name=${text?.campaign_name}`}
          >
            {text?.campaign_id || "-"}
          </Link>
        );
      },
      width: "150px",
    },
    {
      title: "Campaign State",
      render: (text) => {
        return (
          <Tag
            color={
              text?.campaign_state === "ENABLED"
                ? "green"
                : text?.campaign_state === "ARCHIVED"
                ? "red"
                : "yellow"
            }
          >
            {text?.campaign_state || "-"}
          </Tag>
        );
      },
      width: "100px",
    },
    // {
    //   title: "Budget",
    //   render: (text) => {
    //     return <span>{text?.budget_type || "-"}</span>;
    //   },
    //   width: "100px",
    // },
    {
      title: "Advertising type",
      render: (text) => {
        return <span>{text?.advertising_type || "-"}</span>;
      },
      width: "100px",
    },

    {
      title: "Targeting",
      render: (text) => {
        return <span>{text?.targeting_type || "-"}</span>;
      },
      width: "100px",
    },
    {
      title: "Campaign Bidding Strategy",
      render: (text) => {
        return <span>{text?.bidding_strategy_type || "-"}</span>;
      },
      width: "200px",
    },
    {
      title: "Start Date",

      render: (text) => {
        return <span>{text?.start_date || "-"}</span>;
      },
    },
    {
      title: "End Date",

      render: (text) => {
        return <span>{text?.end_date || "-"}</span>;
      },
    },
    {
      title: "Portfolio",
      render: (text) => {
        return <span>{text?.portfolio_id || "-"}</span>;
      },
      width: "100px",
    },

    {
      title: "Budget Amount",
      render: (text) => {
        return (
          <span>
            {SIGN()}
            {text?.budget_amount?.toLocaleString() || "-"}
          </span>
        );
      },
      width: "100px",
    },
    {
      title: "Budget Currency",
      render: (text) => {
        return <span>{text?.budget_currency || "-"}</span>;
      },
      width: "100px",
    },
    {
      title: "Top of search placement",
      render: (text) => {
        return (
          <span>{text?.top_of_search_placement?.toLocaleString() || "-"}</span>
        );
      },
      width: "100px",
    },
    {
      title: "Product Pages Placement",
      render: (text) => {
        return (
          <span>{text?.product_pages_placement?.toLocaleString() || "-"}</span>
        );
      },
      width: "100px",
    },

    {
      title: "Marketplace name",
      render: (text) => {
        return <span>{text?.marketplace_name || "-"}</span>;
      },
      width: "100px",
    },
    // {
    //   title: "Is fully created",
    //   render: (text) => {
    //     return <span>{text?.is_fully_created || "-"}</span>;
    //   },
    //   width: "100px",
    // },
    // {
    //   title: "Status",
    //   render: (text) => {
    //     return <span>{text?.status || "-"}</span>;
    //   },
    //   width: "100px",
    // },
    {
      title: "Action",
      align: "left",
      render: (text) => {
        return (
          <div className="d-flex justify-content-center" id="userActionIcons">
            <div
              className="Edit-Icon-Antd d-flex"
              onClick={() => {
                setModalType("view");
                setSelectedRow(text);
                setUpdateModal(true);
              }}
            >
              <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3">
                <i className="ki-outline ki-eye fs-2 text-primary" />
              </button>
              {/* <Button type="dashed">
                <FundViewOutlined style={{ position: "relative", top: -3 }} />
              </Button> */}
            </div>
            <div
              style={{ opacity: text?.campaign_state === "ARCHIVED" ? 0.5 : 1 }}
              className="Edit-Icon-Antd d-flex"
              onClick={() => {
                if (text?.campaign_state !== "ARCHIVED") {
                  setModalType("edit");
                  setSelectedRow(text);
                  setUpdateModal(true);
                }
              }}
            >
              <button className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-3">
                <i className="ki-outline ki-pencil fs-2 text-primary" />
              </button>
            </div>
            <Popconfirm
              title="Are you sure to delete this campaign?"
              placement="left"
              cancelText="No"
              okText="Yes"
              onConfirm={() => {
                if (text?.campaign_state === "ARCHIVED") return;
                message.loading("Deleting...", 0);
                DeleteCampaignAction({
                  marketplace_id: text?.marketplace_id,
                  advertising_type: text?.advertising_type,
                  campaign_ids: text?.campaign_id,
                });
              }}
            >
              <div
                style={{
                  opacity: text?.campaign_state === "ARCHIVED" ? 0.5 : 1,
                }}
                className="Delete-Icon-Antd d-flex"
              >
                <button
                  disabled={text?.campaign_state === "ARCHIVED"}
                  className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
                >
                  <i className="ki-outline ki-trash fs-2 text-danger" />
                </button>
              </div>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  if (location?.search?.split("=")?.[1]) {
    return <AdGroup SIGN={SIGN} {...props} />;
  }

  return (
    <div className="row gy-5 g-xl-5">
      {/*begin::Col*/}
      <div className="col-xxl-12">
        {/*begin::Tables Widget 9*/}
        <div className="card card-xxl-stretch mb-5 mb-xl-8">
          {/*begin::Header*/}
          <div className="card-header border-bottom-dashed">
            <h3 className="card-title fw-bold">Campaign List</h3>
            <div className="card-toolbar">
              <Input
                size="large"
                className="me-3 w-200px"
                placeholder="Search By Campaign Name, ID"
                onPressEnter={() => {
                  getTable();
                }}
                onChange={(e) => setCampaignName(e.target.value)}
                value={campaignName}
              />
              <Select
                size="large"
                className="me-3 w-150px"
                value={filters?.campaign_state}
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    campaign_state: e,
                  });
                  setCampaignLoading(true);
                  setCampaignList([]);
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  CampaignListAction({
                    page: 1,
                    perPage: DefaultPerPage,
                    marketplace_id: selectedMarketplaceList,
                    campaign_name: campaignName,
                    ...filters,
                    campaign_state: e,
                  });
                }}
                allowClear
                placeholder="Select Campaign State"
                options={[
                  {
                    label: "Enabled",
                    value: "ENABLED",
                  },
                  {
                    label: "Archived",
                    value: "ARCHIVED",
                  },
                  {
                    label: "Incomplete",
                    value: "INCOMPLETE",
                  },
                  {
                    label: "Paused",
                    value: "PAUSED",
                  },
                ]}
              />
              <Select
                size="large"
                className="me-3 w-150px"
                value={filters?.targeting_type}
                allowClear
                onChange={(e) => {
                  setFilters({
                    ...filters,
                    targeting_type: e,
                  });
                  setCampaignLoading(true);
                  setCampaignList([]);
                  setPage(1);
                  setPageSize(DefaultPerPage);
                  CampaignListAction({
                    page: 1,
                    perPage: DefaultPerPage,
                    marketplace_id: selectedMarketplaceList,
                    campaign_name: campaignName,
                    ...filters,
                    targeting_type: e,
                  });
                }}
                placeholder="Select Targeting Type"
                options={[
                  {
                    label: "Auto",
                    value: "Auto",
                  },
                  {
                    label: "Manual",
                    value: "Manual",
                  },
                ]}
              />
              <Select
                size="large"
                placeholder="Select Marketplace"
                loading={marketplaceLoading}
                value={selectedMarketplaceList || null}
                onChange={(e) => {
                  setSelectedMarketplaceList(e);
                }}
                style={{
                  width: "200px",
                }}
                options={marketplaceList?.map((d) => ({
                  label: d?.name,
                  value: d?.id,
                }))}
                className="fs-7 me-3"
              />
            </div>
          </div>
          {/*end::Header*/}
          {/*begin::Body*/}
          <div className="card-body py-2 pt-0">
            {/*begin::Table container*/}
            {campaignLoading ? (
              <TableLoading
                id="test-table"
                columns={[1, 2, 3, 4, 5]}
                checkBoxVal={true}
                actions={[1, 2]}
              />
            ) : (
              <div
                className="table-responsive"
                style={{ maxWidth: "100%", overflowX: "auto" }}
              >
                <Table
                  columns={columns}
                  dataSource={campaignList}
                  locale={{ emptyText: <NothingToShow /> }}
                  pagination={false}
                  scroll={{ x: "max-content" }}
                  components={{
                    header: {
                      cell: () => null, // Hide the original header cell
                      row: () => null, // Hide the original header row
                      wrapper: () => (
                        <thead className="ant-table-thead">
                          <tr className="ant-table-row">
                            {columns.map((column) => (
                              <th
                                key={column.key}
                                style={tableHeaderStyle}
                                className="ant-table-cell"
                              >
                                {column.title}
                              </th>
                            ))}
                          </tr>
                        </thead>
                      ),
                    },
                  }}
                />
              </div>
            )}

            <Pagination
              loading={campaignLoading || campaignList?.length === 0}
              pageSize={pageSize}
              page={page}
              totalPage={totalPage}
              onPerPage={onPerPage}
              onPageNo={onPageNo}
            />
          </div>
          {/*begin::Body*/}
        </div>
        {/*end::Tables Widget 9*/}
      </div>
      {/*end::Col*/}
      {updateModal && (
        <CampaignForm
          type={modalType}
          visible={updateModal}
          selectedRow={selectedRow}
          portfloioList={portfloioList}
          SIGN={SIGN}
          portfloioLoading={portfloioLoading}
          setVisible={(e) => setUpdateModal(e)}
          getTable={getTable}
          {...props}
        />
      )}
    </div>
  );
};

export default CreationList;
