import React from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

function SearchTerm() {
  const { RangePicker } = DatePicker;

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
  ];

  const onRangeChange = (dates, dateStrings) => {};

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row gy-5 g-xl-5 ">
              <div className="col-xl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bolder" />
                    <div className="card-toolbar my-0">
                      <RangePicker
                        size="large"
                        presets={rangePresets}
                        format="DD/MM/YYYY"
                        onChange={onRangeChange}
                      />
                      {/* <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left" data-kt-daterangepicker-range="today" className="btn btn-sm btn-light d-flex align-items-center px-4">
                                                <div className="text-gray-600 fw-bold">
                                                    Loading date range...
                                                </div>
                                                <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                            </div> */}
                      <button
                        type="button"
                        className="btn  fs-7 btn-light btn-active-light-primary ms-3 fw-bold"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        Import
                      </button>
                      <div
                        className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        id="kt_menu_63be7b6fcb7e6"
                      >
                        <div className="px-7 py-5">
                          <div className="fs-5 text-dark fw-bold">
                            Filter Options
                          </div>
                        </div>
                        <div className="separator border-gray-200" />
                        <div className="px-7 py-5">
                          <div className="mb-5">
                            <a href className="fw-bold text-primary">
                              <i className="bi bi-download text-primary" />{" "}
                              Download an import schema
                            </a>
                          </div>
                          <div className="mb-10">
                            <input
                              className="form-control form-control-sm form-control-solid"
                              placeholder
                              type="file"
                              id="formFile"
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-menu-dismiss="true"
                            >
                              Reset
                            </button>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              data-kt-menu-dismiss="true"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-light-primary fs-7 fw-bold ms-3">
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="table-responsive">
                      <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                        <thead>
                          <tr className="fw-bolder text-dark">
                            <th className="min-w-55px">Sr. No.</th>
                            <th className="min-w-150px">Customer Search</th>
                            <th className="min-w-125px">Keyword</th>
                            <th className="min-w-100px">Current Bid</th>
                            <th className="min-w-100px">Type</th>
                            <th className="min-w-75px">Match</th>
                            <th className="min-w-175px">Campaign</th>
                            <th className="min-w-100px">AdGroup</th>
                            <th className="min-w-75px">Impr.</th>
                            <th className="min-w-75px">Clk.</th>
                            <th className="min-w-75px">CTR</th>
                            <th className="min-w-75px">Spend</th>
                            <th className="min-w-75px">CPC</th>
                            <th className="min-w-75px">Sales</th>
                            <th className="min-w-75px">Orders</th>
                            <th className="min-w-100px">Sale Units</th>
                            <th className="min-w-75px">CVR</th>
                            <th className="min-w-75px">ACOS</th>
                            <th className="min-w-75px">AOV</th>
                            <th className="min-w-75px">ASP</th>
                            <th className="min-w-75px">Impr.Share</th>
                            <th className="min-w-75px">Impr.Rank</th>
                            <th className="min-w-75px">Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>1</td>
                            <td>
                              <a href className="fw-bold">
                                Beauty Blender
                              </a>
                            </td>
                            <td>Beauty Blender</td>
                            <td>$2.63</td>
                            <td>SP-Manual</td>
                            <td>Exact</td>
                            <td>SP | Branded | Gel</td>
                            <td>Exact</td>
                            <td>429.6K</td>
                            <td>5471</td>
                            <td>1.27%</td>
                            <td>$11.7K</td>
                            <td>$2.14</td>
                            <td>$49.4K</td>
                            <td>2427</td>
                            <td>2545</td>
                            <td>44.36%</td>
                            <td>23.76%</td>
                            <td>$20.34</td>
                            <td>$19.4</td>
                            <td>--</td>
                            <td>--</td>
                            <td />
                          </tr>
                          <tr>
                            <td>2</td>
                            <td>
                              <a href className="fw-bold">
                                Beauty Blender
                              </a>
                            </td>
                            <td>Beauty Blender</td>
                            <td>$2.63</td>
                            <td>SP-Manual</td>
                            <td>Exact</td>
                            <td>SP | Branded | Gel</td>
                            <td>Exact</td>
                            <td>429.6K</td>
                            <td>5471</td>
                            <td>1.27%</td>
                            <td>$11.7K</td>
                            <td>$2.14</td>
                            <td>$49.4K</td>
                            <td>2427</td>
                            <td>2545</td>
                            <td>44.36%</td>
                            <td>23.76%</td>
                            <td>$20.34</td>
                            <td>$19.4</td>
                            <td>--</td>
                            <td>--</td>
                            <td />
                          </tr>
                          <tr>
                            <td>3</td>
                            <td>
                              <a href className="fw-bold">
                                Beauty Blender
                              </a>
                            </td>
                            <td>Beauty Blender</td>
                            <td>$2.63</td>
                            <td>SP-Manual</td>
                            <td>Exact</td>
                            <td>SP | Branded | Gel</td>
                            <td>Exact</td>
                            <td>429.6K</td>
                            <td>5471</td>
                            <td>1.27%</td>
                            <td>$11.7K</td>
                            <td>$2.14</td>
                            <td>$49.4K</td>
                            <td>2427</td>
                            <td>2545</td>
                            <td>44.36%</td>
                            <td>23.76%</td>
                            <td>$20.34</td>
                            <td>$19.4</td>
                            <td>--</td>
                            <td>--</td>
                            <td />
                          </tr>
                          <tr>
                            <td>4</td>
                            <td>
                              <a href className="fw-bold">
                                Beauty Blender
                              </a>
                            </td>
                            <td>Beauty Blender</td>
                            <td>$2.63</td>
                            <td>SP-Manual</td>
                            <td>Exact</td>
                            <td>SP | Branded | Gel</td>
                            <td>Exact</td>
                            <td>429.6K</td>
                            <td>5471</td>
                            <td>1.27%</td>
                            <td>$11.7K</td>
                            <td>$2.14</td>
                            <td>$49.4K</td>
                            <td>2427</td>
                            <td>2545</td>
                            <td>44.36%</td>
                            <td>23.76%</td>
                            <td>$20.34</td>
                            <td>$19.4</td>
                            <td>--</td>
                            <td>--</td>
                            <td />
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SearchTerm;
