import { Modal, Radio, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Icons from "../../../../components/icons";

const AutoStrategy = (props) => {
  const {
    visible,
    handleCancel,
    CreateCampaignStrategyAction,
    UpdateStrategiesAction,
    fakeActionCampaignsManagementAnalytics,
    getList,
    selectedRow,
    selectedMarketplaceStrategy,
    SIGN,
    configsData,
    marketplace_signs,
  } = props;

  const CreateCampaignsStrategyResponse = useSelector(
    (state) => state.CampaignManagement.CreateCampaignsStrategyResponse || {}
  );
  const CampaignsStrategiesUpdateResponse = useSelector(
    (state) => state.CampaignManagement.CampaignsStrategiesUpdateResponse || {}
  );

  useEffect(() => {
    if (CampaignsStrategiesUpdateResponse?.status === true) {
      message.destroy();
      message.success(CampaignsStrategiesUpdateResponse?.message);
      getList();
      fakeActionCampaignsManagementAnalytics(
        "CampaignsStrategiesUpdateResponse"
      );
      handleCancel();
    } else if (CampaignsStrategiesUpdateResponse?.status === false) {
      message.destroy();

      message.error(
        Object.values(CampaignsStrategiesUpdateResponse?.error)?.[0]?.[0] ||
          CampaignsStrategiesUpdateResponse?.message
      );
      fakeActionCampaignsManagementAnalytics(
        "CampaignsStrategiesUpdateResponse"
      );
    }
  }, [CampaignsStrategiesUpdateResponse]);

  useEffect(() => {
    if (CreateCampaignsStrategyResponse?.status === true) {
      message.destroy();
      message.success(CreateCampaignsStrategyResponse?.message);
      getList();
      fakeActionCampaignsManagementAnalytics("CreateCampaignsStrategyResponse");
      handleCancel();
    } else if (CreateCampaignsStrategyResponse?.status === false) {
      message.destroy();

      message.error(
        Object.values(CreateCampaignsStrategyResponse?.error)?.[0]?.[0] ||
          CreateCampaignsStrategyResponse?.message
      );
      fakeActionCampaignsManagementAnalytics("CreateCampaignsStrategyResponse");
    }
  }, [CreateCampaignsStrategyResponse]);

  const [data, setData] = useState({
    strategy_name: "",
    advertising_type: "SP",
    budget_type: "AMOUNT",
    budget_amount: 0,
    budget_currency: marketplace_signs(),
    bidding_strategy_type: "AUTO_FOR_SALES",
    top_of_search_placement: 0,
    product_pages_placement: 0,
    marketplace_id: selectedMarketplaceStrategy,
  });
  const outputObject = {};

  for (const key in configsData?.biddingStrategyTypes) {
    const value = configsData?.biddingStrategyTypes[key];
    outputObject[value] = key;
  }

  useEffect(() => {
    if (Object.keys(selectedRow)?.length !== 0) {
      const {
        strategy_name,
        budget_type,
        budget_amount,
        budget_currency,
        bidding_strategy_type,
        product_pages_placement,
        top_of_search_placement,
      } = selectedRow;
      setData({
        strategy_name: strategy_name,
        budget_type: budget_type,
        budget_amount: budget_amount,
        budget_currency: budget_currency,
        bidding_strategy_type: outputObject?.[bidding_strategy_type],
        top_of_search_placement: top_of_search_placement,
        product_pages_placement: product_pages_placement,
        marketplace_id: selectedMarketplaceStrategy,
      });
    }
  }, [selectedRow]);

  const onChangeData = (type, value) => {
    setData({
      ...data,
      [type]: value,
    });
  };

  const tooltipData = {
    AUTO_FOR_SALES:
      "Raise your bids real time when your ad may be more likely to convert to a sale, and lower your bids when less likely to convert to a sale.",
    LEGACY_FOR_SALES:
      "Lower your bids in real time when your ad may be less likely to convert to a sale.",
    MANUAL: `Use your exact bid and any manual adjustments you set, and won't change your bids based on likelihood of a sale.`,
  };

  return (
    <Modal
      title={
        <>
          {Object?.keys(selectedRow)?.length === 0
            ? "Create Strategy"
            : "Update Strategy"}
        </>
      }
      centered
      open={visible}
      maskClosable={false}
      onOk={() => handleCancel()}
      wrapClassName="custom-modal" // Apply custom CSS class
      onCancel={() => handleCancel()}
      closeIcon={<Icons type="close" />}
      width={700}
      footer={[
        <button
          type="button"
          onClick={() => handleCancel()}
          className="btn btn-light fs-7"
          data-bs-dismiss="modal"
        >
          Close
        </button>,
        <button
          onClick={() => {
            if (parseFloat(data?.budget_amount) < 1) {
              message.destroy();
              return message.warning(
                `!Enter a budget of at least ${SIGN()}1.00`
              );
            }
            message.destroy();
            message.loading("Loading...", 0);
            if (Object?.keys(selectedRow)?.length === 0) {
              CreateCampaignStrategyAction(data);
            } else {
              UpdateStrategiesAction(selectedRow?.id, data);
            }
          }}
          type="button"
          className="btn btn-dark fs-7 ms-4"
        >
          {Object?.keys(selectedRow)?.length === 0
            ? "Create Strategy"
            : "Update Strategy"}
        </button>,
      ]}
    >
      <div
        className="scroll-y pe-7"
        id="kt_modal_add_customer_scroll"
        style={{ maxHeight: 789 }}
      >
        <div className="row mb-4 g-5 mb-5">
          <div className="col-md-6">
            <div className="position-relative mr-4">
              <label htmlFor className="fw-bolder mb-1">
                Strategy Name
              </label>
              <input
                type="text"
                placeholder="Enter Strategy Name"
                value={data?.strategy_name || null}
                onChange={(e) => onChangeData("strategy_name", e.target.value)}
                className="form-control form-control-solid fs-7"
              />
            </div>
          </div>

          <div className="col-md-6">
            <label htmlFor className="fw-bolder mb-1">
              Daily Budget
            </label>
            <div className="input-group input-group-solid d-grid">
              <div className="d-flex">
                <span className="input-group-text fw-bolder" id="basic-addon2">
                  {SIGN()}
                </span>
                <input
                  type="text"
                  className="form-control fs-7 border-0"
                  placeholder="Enter Daily Budget"
                  value={data?.budget_amount || null}
                  onChange={(e) =>
                    onChangeData("budget_amount", e.target.value)
                  }
                />
              </div>
              {parseFloat(data?.budget_amount) < 1 ? (
                <div
                  style={{
                    position: "relative",
                    width: "253px",
                    top: "5px",
                  }}
                  className="d-flex align-items-center"
                >
                  <small
                    style={{
                      color: "#ff2424",
                      width: "186px",
                    }}
                  >
                    !Enter a budget of at least {SIGN()}1.00
                  </small>
                  <div
                    style={{
                      color: "#1e93c6",
                      cursor: "pointer",

                      marginLeft: "10px",
                    }}
                    onClick={() => {
                      onChangeData("budget_amount", 1);
                    }}
                  >
                    Quick fix
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>

          <div className="col-md-12 ">
            <div className>
              <label
                htmlFor
                className="fw-bolder mb-1 fs-6 d-flex align-items-center"
              >
                Campaign Bidding Strategy
                <Tooltip title="Choose how you want to pay for clicks on your ads.">
                  <i className="ki-outline ki-information-4 fs-3 ms-2" />
                </Tooltip>
              </label>
            </div>
            <div className=" d-flex gap-5 mt-5">
              <Radio.Group
                className="d-flex flex-wrap gap-5"
                value={data?.bidding_strategy_type || null}
                onChange={(e) =>
                  onChangeData("bidding_strategy_type", e.target.value)
                }
              >
                {Object.entries(configsData?.biddingStrategyTypes)?.map(
                  ([key, value], i) => (
                    <Radio key={i} value={key}>
                      <div
                        className="form-check-label fw-bold text-dark d-flex align-items-center fs-7"
                        htmlFor="flexCheckDefault"
                      >
                        {value}
                        <Tooltip title={tooltipData?.[key]}>
                          <i className="ki-outline ki-information-4 fs-3 ms-2" />
                        </Tooltip>
                      </div>
                    </Radio>
                  )
                )}
              </Radio.Group>
            </div>
          </div>
          <div className="col-md-12 mt-8">
            <div className>
              <label
                htmlFor
                className="fw-bolder mb-1 fs-6  d-flex align-items-center"
              >
                Adjust Bids by Placement{" "}
                <Tooltip
                  title="Apply different bids by placement by entering a percentage increase to your base for 2 placements:
								top of search and product pages."
                >
                  <i className="ki-outline ki-information-4 fs-3 ms-2" />
                </Tooltip>
              </label>
            </div>
            <div className="row mt-3">
              <div className="col-md-6">
                <div className="position-relative mr-4">
                  <label htmlFor className="fw-bolder mb-1">
                    Top of Search
                  </label>
                  <div className="input-group input-group-solid">
                    <input
                      type="text"
                      className="form-control fs-7"
                      placeholder="Enter Top of Search"
                      value={data?.top_of_search_placement || null}
                      onChange={(e) =>
                        onChangeData("top_of_search_placement", e.target.value)
                      }
                      aria-describedby="basic-addon2"
                    />
                    <span
                      className="input-group-text fw-bolder"
                      id="basic-addon2"
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="position-relative mr-4">
                  <label htmlFor className="fw-bolder mb-1">
                    Product Page
                  </label>
                  <div className="input-group input-group-solid">
                    <input
                      type="text"
                      className="form-control fs-7"
                      placeholder="Enter Product Page"
                      value={data?.product_pages_placement || null}
                      onChange={(e) =>
                        onChangeData("product_pages_placement", e.target.value)
                      }
                      aria-describedby="basic-addon2"
                    />
                    <span
                      className="input-group-text fw-bolder"
                      id="basic-addon2"
                    >
                      %
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AutoStrategy;
