export const userMenus = [
  {
    title: "Dashboard",
    icon: <i className="ki-outline ki-notification-status fs-1" />,
    id: "dashboard",
    child: [],
    link: "/",
  },
  {
    title: "Campaigns Analytics",
    icon: <i className="ki-outline ki-graph-3 fs-1" />,
    id: "campaigns-analytics",
    child: [],
    link: "/campaigns-analytics",
  },
  {
    title: "Rule Management",
    icon: <i className="ki-outline ki-data fs-1" />,
    id: "rule-management",
    child: [],
    link: "/rule-management",
  },
  // {
  //   title: "Campaigns Portfolio",
  //   icon: <i className="ki-outline ki-chart-pie-3 fs-1" />,
  //   id: "campaigns-portfolio",
  //   child: [],
  //   link: "/campaigns-portfolio",
  // },
  // {
  //   title: "Product Data",
  //   icon: <i className="ki-outline ki-document fs-1" />,
  //   id: "product-data",
  //   child: [],
  //   link: "/product-data",
  // },
  // {
  //   title: "Keywords List",
  //   icon: <i className="ki-outline ki-burger-menu fs-1" />,
  //   id: "keyword-list",
  //   child: [],
  //   link: "/keyword-list",
  // },
  // {
  //   title: "Negative Keywords",
  //   icon: <i className="ki-outline ki-tablet-down fs-1" />,
  //   id: "negative-keywords",
  //   child: [],
  //   link: "/negative-keywords",
  // },
  // {
  //   title: "Negative ASINs",
  //   icon: <i className="ki-outline ki-graph-4 fs-1" />,
  //   id: "negative-asin",
  //   child: [],
  //   link: "/negative-asin",
  // },
  {
    title: "Campaign Management",
    icon: <i className="ki-outline ki-abstract-26 fs-1" />,
    id: "campaign-management",
    child: [],
    link: "/campaign-management",
  },
  // {
  //   title: "Search Term Data",
  //   icon: <i className="ki-outline ki-search-list fs-1" />,
  //   id: "search-term",
  //   child: [],
  //   link: "/search-term",
  // },
  // {
  //   title: "Organic & Spon. Ranking",
  //   icon: <i className="ki-outline ki-ranking fs-1" />,
  //   id: "organic-spon-ranking",
  //   child: [],
  //   link: "/organic-spon-ranking",
  // },
  {
    title: "Settings",
    icon: <i className="ki-outline ki-gear  fs-2" />,
    id: "settings",
    child: [
      {
        title: "My Profile",
        icon: <span className="bullet bullet-dot" />,
        id: "my-profile",
        link: "/setting/my-profile",
      },
      // {
      //   title: "Manage User",
      //   icon: <span className="bullet bullet-dot" />,
      //   id: "manage-user",
      //   link: "/setting/manage-user",
      // },
      {
        title: "Marketplace Credentials",
        icon: <span className="bullet bullet-dot" />,
        id: "marketplace-credentials",
        link: "/setting/marketplace-credentials",
      },
    ],
  },
  {
    title: "Application Logs",
    icon: <i className="ki-outline ki-chart-simple-2 fs-2" />,
    id: "application_logs",
    child: [
      {
        title: "Central Log",
        icon: <span className="bullet bullet-dot" />,
        id: "central-log",
        link: "/application-logs/central-log",
      },
      {
        title: "System Error Log",
        icon: <span className="bullet bullet-dot" />,
        id: "system-log",
        link: "/application-logs/system-log",
      },
    ],
  },
];
export const adminMenus = [
  {
    title: "Manage Seller Users",
    icon: <i className="ki-outline ki-user fs-1" />,
    id: "manage-seller-users",
    child: [],
    link: "/manage-seller-users",
  },
];
