import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
  signUpUserAction,
  fakeActionAuth,
} from "../../redux/modules/auth/auth.action";
import SignUp from "../../modules/pages/sign-up/SignUp";

const mapStateToProps = (state) => ({
  SignUpUserResponse: state.Auth.SignUpUserResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      signUpUserAction,
      fakeActionAuth,
    },
    dispatch
  );

const SignUp_ = connect(mapStateToProps, mapDispatchToProps)(SignUp);

export default SignUp_;
