import {
  Button,
  Input,
  InputNumber,
  Segmented,
  Select,
  Table,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import {
  FileSearchOutlined,
  BulbOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { useSelector } from "react-redux";
import { EndPoint, SIGN } from "../../../../../../../../config";

const Categories = (props) => {
  const {
    setSelectedFilter,
    groupIndex,
    setBasicDetails,
    basicDetails,
    selectedFilter,
    fakeActionCampaignsManagementAnalytics,
    selectedMarketplaceCreate,
  } = props;

  const [selectedBidType, setSelectedBidType] = useState("Suggested Bid");
  const [customBidValue, setCustomBidValue] = useState(0);

  const [searchText, setSearchText] = useState("");

  const CreateCampaignsTargetionSuggestionResponse = useSelector(
    (state) =>
      state.CampaignManagement.CreateCampaignsTargetionSuggestionResponse || {}
  );

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[
          selectedMarketplaceCreate
        ] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  useEffect(() => {
    if (CreateCampaignsTargetionSuggestionResponse?.status === true) {
      setList(CreateCampaignsTargetionSuggestionResponse?.data || []);
      setLoading(false);
      message.destroy();
      fakeActionCampaignsManagementAnalytics(
        "CreateCampaignsTargetionSuggestionResponse"
      );
    } else if (CreateCampaignsTargetionSuggestionResponse?.status === false) {
      setLoading(false);
      setList([]);
      message.destroy();
      message.error(CreateCampaignsTargetionSuggestionResponse?.message);
      fakeActionCampaignsManagementAnalytics(
        "CreateCampaignsTargetionSuggestionResponse"
      );
    }
  }, [CreateCampaignsTargetionSuggestionResponse]);

  const addCategory = (obj, Index_) => {
    const updatedData = { ...basicDetails };

    const index = updatedData.ad_groups_data[
      Index_
    ].product_targeting_manual?.findIndex(
      (d) => d?.category_id === obj?.category_id
    );

    if (index !== -1) return message.warning("Already added");
    updatedData.ad_groups_data[Index_].product_targeting_manual.push({
      ...obj,
      expression_type: "ASIN_CATEGORY_SAME_AS",
      expression_value: obj?.category_id,
      bid:
        selectedBidType === "Custom Bid" ? customBidValue : obj?.suggested_bid,
      state: "ENABLED",
      Type: "Category",
      keyrow:
        updatedData.ad_groups_data[Index_].product_targeting_manual?.length + 1,
    });

    setBasicDetails({ ...updatedData });
  };

  const columns = [
    {
      title: "Suggestions",
      width: 300,
      render: (e) => {
        return (
          <div className="row">
            <div
              className="col-12 text-overflow fw-500"
              style={{ fontWeight: 700 }}
            >
              {e?.category_path || "-"}
            </div>
            <div className="col-12 mt-3">
              <div className="row">
                <div className="col-6">Category&nbsp;:</div>
                <div className="col-6" style={{ fontWeight: 500 }}>
                  {e?.category_name || "-"}
                </div>
              </div>
              <div className="row">
                <div className="col-6">Products&nbsp;:</div>
                <div className="col-6" style={{ fontWeight: 500 }}>
                  {e?.products?.min || "0"}
                  {` - `}
                  {e?.products?.max}
                </div>
              </div>
            </div>
          </div>
        );
      },
    },
    {
      title: "Sugg. bid",
      render: (e) => {
        return (
          <div className="row">
            <div className="col-12">
              {SIGN()}
              {e?.suggested_bid || 0}
            </div>
            <div className="col-12">
              {SIGN()}
              {e?.min_bid || 0}&nbsp;&nbsp;-&nbsp;&nbsp;{SIGN()}
              {e?.max_bid || 0}
            </div>
          </div>
        );
      },
    },

    {
      title: "Action",
      key: "Action",
      width: 130,
      render: (e) => {
        const index = basicDetails.ad_groups_data[
          groupIndex
        ].product_targeting_manual?.findIndex(
          (d) => d?.category_id === e?.category_id
        );
        return (
          <Button
            disabled={index !== -1}
            onClick={() => {
              if (index !== -1) return;
              addCategory(e, groupIndex);
            }}
            type="primary"
          >
            {index !== -1 ? "Added" : "Add"}
          </Button>
        );
      },
    },
  ];

  const searchCategory = () => {
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      `Bearer ${localStorage.getItem("token")}`
    );

    var raw = JSON.stringify({
      marketplace_id: basicDetails?.marketplace_id,
      asins: searchText?.split(","),
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
    };

    fetch(
      `${EndPoint}api/v1/campaigns/get-categories-targeting-suggestions`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        // addCategory

        result?.data?.forEach((e) => {
          const index = basicDetails?.ad_groups_data?.[
            groupIndex
          ]?.product_targeting_manual?.findIndex(
            (r) => r?.category_name === e?.category_name
          );

          if (index === -1) {
            addCategory(
              {
                ...e,
                expression_type: "ASIN_CATEGORY_SAME_AS",
                expression_value: e?.category_id,
                bid:
                  selectedBidType === "Custom Bid"
                    ? customBidValue
                    : e?.suggested_bid,
                state: "ENABLED",
                Type: "Category",
                keyrow:
                  basicDetails.ad_groups_data[groupIndex]
                    .product_targeting_manual?.length + 1,
              },
              groupIndex
            );
          }
        });
        setSearchText("");
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className="row">
      <div className=" col-12 add-products-title d-flex align-items-center justify-content-between">
        <h3>
          <b>Categories List</b>
        </h3>
        <div className="add-products-filters">
          <Segmented
            className="step-1-segment-view"
            style={{ marginBottom: ".5rem" }}
            value={selectedFilter}
            onChange={(e) => setSelectedFilter(e)}
            options={[
              {
                value: `suggested`,
                label: "Suggested",
                icon: <BulbOutlined />,
              },
              {
                value: `search`,
                label: "Search",
                icon: <FileSearchOutlined />,
              },
            ]}
          />
        </div>
      </div>
      <div className="col-12">
        <div className="row mt-5 d-flex align-items-center">
          <div className="col-4">Bid</div>
          <div className="col-8 d-flex align-items-center">
            <Select
              onChange={(e) => setSelectedBidType(e)}
              value={selectedBidType}
              options={["Suggested Bid", "Custom Bid"]?.map((d) => {
                return { label: d, value: d };
              })}
              placeholder="Select Bid"
              style={{ width: "100%" }}
            />
            {selectedBidType === "Custom Bid" && (
              <InputNumber
                className="ms-5"
                onChange={(e) => setCustomBidValue(e)}
                value={customBidValue}
                style={{ width: "100%" }}
                placeholder="Enter Custom Bid"
              />
            )}
          </div>
        </div>
        {selectedFilter === "search" && (
          <div className="row mt-4">
            <div className="filter-asin ">
              <Input
                placeholder="Search by Product name or ASIN"
                size="large"
                onPressEnter={() => searchCategory()}
                className="antd-border-add"
                onChange={(e) => setSearchText(e.target.value)}
                value={searchText}
                addonBefore={<SearchOutlined />}
              />
              <small>Search for products you want to all asins.</small>
            </div>
          </div>
        )}
      </div>
      {selectedFilter !== "search" && (
        <>
          <div className="col-12 mt-5">
            <Table
              scroll={{ x: "max-content", y: "calc(100vh - 500px)" }}
              dataSource={list?.map((d, i) => {
                return { ...d, key: i };
              })}
              rowKey="key"
              loading={loading}
              columns={columns}
              pagination={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Categories;
