export const userList = {
  code: 200,
  status: true,
  message: "User data found.",
  data: {
    records: [
      {
        id: 2,
        name: "User",
        seller_name: "",
        user_type: 2,
        email: "user@gmail.com",
        photo: null,
        db_name: null,
        contact_no: null,
        address: null,
        status: 1,
        pending_reports: 0,
        user_status: 0,
        created_at: 1687764816,
        updated_at: 1687764816,
        deleted_at: null,
        deleted_by: null,
      },
      {
        id: 1,
        name: "Admin",
        seller_name: "",
        user_type: 1,
        email: "admin@vf.com",
        photo: null,
        db_name: null,
        contact_no: null,
        address: null,
        status: 1,
        pending_reports: 0,
        user_status: 0,
        created_at: 1687764816,
        updated_at: 1687764816,
        deleted_at: null,
        deleted_by: null,
      },
    ],
    pagination: {
      totalCount: 2,
      page: 1,
      page_size: 20,
      page_count: 0,
    },
    queryParams: {
      page: "1",
      "per-page": "20",
    },
  },
  error: [],
};
export const switchUser = {
  code: 200,
  status: true,
  message: "User data found.",
  data: {
    auth_token_data: {
      access_token:
        "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiIsImp0aSI6IiJ9.eyJpc3MiOiIiLCJhdWQiOiIiLCJqdGkiOiIiLCJpYXQiOjE2OTIzMzI5MjYsImV4cCI6MTY5MjQxOTMyNiwidWlkIjoyfQ.in5g37ZUIDSgLMUz39Ezsjqn5EP9FTAxgE8iKGCDdqs",
      expire_in: 1692419326,
    },
    user_data: {
      id: 2,
      name: "User 1",
      seller_name: "User 1",
      user_type: 2,
      email: "user@gmail.com",
      photo: null,
      db_name: "vf_db_n6gm2kqrgp_user_1",
      contact_no: null,
      address: null,
      status: 1,
      pending_reports: 464,
      user_status: 0,
      created_at: 1687764888,
      updated_at: 1692259236,
      deleted_at: null,
      deleted_by: null,
    },
  },
  error: [],
};
