import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import User from "../../../modules/pages/admin/user";
import {
  getUserAction,
  switchUserAction,
  addUserAction,
  updateUserAction,
  deleteUserAction,
  fakeActionUser,
} from "../../../redux/modules/admin/user/index.action";
import {
  signUpUserAction,
  fakeActionAuth,
} from "../../../redux/modules/auth/auth.action";

const mapStateToProps = (state) => ({
  UserListResponse: state.User.UserListResponse,
  SwitchUserResponse: state.User.SwitchUserResponse,
  AddUserResponse: state.User.AddUserResponse,
  UpdateUserResponse: state.User.UpdateUserResponse,
  DeleteUserResponse: state.User.DeleteUserResponse,

  SignUpUserResponse: state.Auth.SignUpUserResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getUserAction,
      switchUserAction,
      addUserAction,
      updateUserAction,
      deleteUserAction,
      fakeActionUser,

      signUpUserAction,
      fakeActionAuth,
    },
    dispatch
  );

const User_ = connect(mapStateToProps, mapDispatchToProps)(User);

export default User_;
