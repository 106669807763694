import axiosCall from "../../configurations/network-services/axiosCall";

export const getOptionListAction = (data) => {
  const path = `dashboard/filters`;
  const responseType = "OPTIONS_GET";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const getDashboardCardsDataAction = (data) => {
  const path = `dashboard/dashboard-cards-data?match_type=${
    data?.match_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&metrics_type=${data?.metrics_type || ""}&request_type=${
    data?.request_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}`;
  const responseType = "CARDS_DATA_GET";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const getDashboardPerformanceDataAction = (data) => {
  const path = `dashboard/performance-metrics?advertising_type=${
    data?.advertising_type || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&request_type=${data?.request_type || ""}&marketplace_id=${
    data?.marketplace_id || ""
  }`;
  const responseType = "DASHBOARD_PERFORMANCE_METRICS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const getDashboardGraphAction = (data) => {
  const path = `dashboard/daily-performance-trend?metrics=${
    data?.metrics || ""
  }&start_date=${data?.start_date || ""}&end_date=${
    data?.end_date || ""
  }&advertising_type=${data?.advertising_type || ""}&request_type=${
    data?.request_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}`;
  const responseType = "DASHBOARD_PERFORMANCE_GRAPH";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DashboardActiveCampaignsAction = (data) => {
  const path = `dashboard/active-campaigns?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&request_type=${
    data?.request_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}&advertising_type=${
    data?.advertising_type || ""
  }`;
  const responseType = "DASHBOARD_ACTIVE_CAMPAIGNS";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const DashboardTargetingCardDataAction = (data) => {
  const path = `dashboard/targeting-card-data?start_date=${
    data?.start_date || ""
  }&end_date=${data?.end_date || ""}&request_type=${
    data?.request_type || ""
  }&marketplace_id=${data?.marketplace_id || ""}&advertising_type=${
    data?.advertising_type || ""
  }`;
  const responseType = "DASHBOARD_TARGETING_CARD";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionDashboard = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_DASHBOARD", state: data });
};
