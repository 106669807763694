import Wrapper from "../style";
import React from "react";
import {
  Button,
  InputNumber,
  Segmented,
  Select,
  Table,
  Tabs,
  message,
} from "antd";
import { useState } from "react";
import Categories from "./categories";
import SelectedProdcut from "./selected-prodcut";
import IndividualProducts from "./Individual-products";
import KeywordTargeting from "./keyword-targeting";
import SelectedKeyword from "./selected-keyword";
import AutoBid from "./auto-bid";

const SelectType = (props) => {
  const {
    basicDetails,
    GetProductTargetingAction,
    groupIndex,
    CreateCampaignTargetingSuggestionsAction,
    setBasicDetails,
    GetKeywordsTargetingAction,
  } = props;
  const [selectdType, setSelectdType] = useState("product");
  const [selectedFilter, setSelectedFilter] = useState("suggested");

  const UpdateManulType = (e) => {
    setBasicDetails({
      ...basicDetails,
      manualType: e,
    });
  };
  const ChangeTabs = (e) => {
    if (
      e === "keyword" &&
      basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    ) {
      message.destroy();
      message.loading("Loading...", 0);
      GetKeywordsTargetingAction({
        marketplace_id: basicDetails?.marketplace_id,
        bidding_strategy: "AUTO_FOR_SALES",
        sort_dimension: "CLICKS",
        limit: 30,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
    } else if (
      e === "product" &&
      basicDetails.ad_groups_data[groupIndex].product_ads?.length !== 0
    ) {
      message.destroy();
      message.loading("Loading...", 0);
      CreateCampaignTargetingSuggestionsAction({
        marketplace_id: basicDetails?.marketplace_id,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });

      GetProductTargetingAction({
        marketplace_id: basicDetails?.marketplace_id,
        asins: basicDetails.ad_groups_data[groupIndex].product_ads?.map(
          (d) => d?.asin1
        ),
      });
    }
    setBasicDetails({
      ...basicDetails,
      manualType: e,
    });
  };

  const selectedModule = {
    product: <SelectedProdcut {...props} />,
    keyword: <SelectedKeyword {...props} />,
  };
  if (basicDetails.ad_groups_data[groupIndex].product_ads?.length === 0) {
    return <></>;
  }
  if (basicDetails?.campaign_data?.targeting_type === "AUTO") {
    return <AutoBid {...props} />;
  }

  return (
    <Wrapper className="campaign-specifications mt-3">
      <div className="d-flex justify-content-end">
        {ToggleComponents(ChangeTabs, basicDetails)}
      </div>

      <div className="row">
        <div className="col-6">
          <Tabs
            type="card"
            onChange={(e) => {
              if (parseInt(e) === 1) {
                message.destroy();
                message.loading("Loading...", 0);
                CreateCampaignTargetingSuggestionsAction({
                  marketplace_id: basicDetails?.marketplace_id,
                  asins: basicDetails.ad_groups_data[
                    groupIndex
                  ].product_ads?.map((d) => d?.asin1),
                });
              } else {
                message.destroy();
                message.loading("Loading...", 0);
                GetProductTargetingAction({
                  marketplace_id: basicDetails?.marketplace_id,
                  asins: basicDetails.ad_groups_data[
                    groupIndex
                  ].product_ads?.map((d) => d?.asin1),
                });
              }
            }}
            items={
              basicDetails.manualType === "product"
                ? [
                    {
                      label: "Categories",
                      key: "1",
                      children: (
                        <Categories
                          setSelectedFilter={setSelectedFilter}
                          selectedFilter={selectedFilter}
                          groupIndex={groupIndex}
                          {...props}
                        />
                      ),
                    },
                    {
                      label: "Individual Products",
                      key: "2",
                      children: (
                        <IndividualProducts
                          setSelectedFilter={setSelectedFilter}
                          selectedFilter={selectedFilter}
                          groupIndex={groupIndex}
                          {...props}
                        />
                      ),
                    },
                  ]
                : [
                    {
                      label: "Keyword List",
                      key: "3",
                      children: (
                        <KeywordTargeting
                          setSelectedFilter={setSelectedFilter}
                          selectedFilter={selectedFilter}
                          groupIndex={groupIndex}
                          {...props}
                        />
                      ),
                    },
                  ]
            }
          />
        </div>
        <div className="col-6 px-3">
          {selectedModule?.[basicDetails?.manualType]}
        </div>
      </div>
    </Wrapper>
  );
};

export default SelectType;
function ToggleComponents(ChangeTabs, basicDetails) {
  return (
    <Segmented
      className="step-1-segment-view"
      style={{ marginBottom: ".5rem" }}
      onChange={(e) => ChangeTabs(e)}
      value={basicDetails?.manualType}
      options={[
        {
          value: `product`,
          label: "Product",
        },
        {
          value: `keyword`,
          label: "Keyword",
        },
      ]}
    />
  );
}
