import React, { Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import User_ from "../containers/admin/user";

import CentralLog_ from "../containers/application-log/central-log";
import SystemLog_ from "../containers/application-log/system-log";
import CampaignsAnalytics_ from "../containers/campaigns-analytics";
import CampaignManagement_ from "../containers/campaigns-management";
import Dashboard_ from "../containers/dashboard";
import MarketplaceCredentials_ from "../containers/marketplace-credentials";
import Profile_ from "../containers/profile";
import RuleManagement_ from "../containers/rule-management";
import CampaignsPortfolio_ from "../modules/pages/campaigns-portfolio/CampaignsPortfolio";
import KeywordList_ from "../modules/pages/keyword-list/KeywordList";
import NegativeAsin_ from "../modules/pages/negative-asin/NegativeAsin";
import NegativeKeywords_ from "../modules/pages/negative-keywords/NegativeKeywords";
import OrganicSponRanking_ from "../modules/pages/organic-spon-ranking/OrganicSponRanking";
import ProductData_ from "../modules/pages/product-data/ProductData";
import SearchTerm_ from "../modules/pages/search-term/SearchTerm";
import ManageUser_ from "../modules/pages/settings/manage-user/ManageUser";

function PageRouter() {
  if (localStorage.getItem("userType") == 1) {
    return (
      <Suspense
        fallback={
          <div className="overlayLoading">
            <div className="loader"></div>
          </div>
        }
      >
        <Switch>
          <Route path="/manage-seller-users" component={User_} exact />
        </Switch>
      </Suspense>
    );
  }
  return (
    <Suspense
      fallback={
        <div className="overlayLoading">
          <div className="loader"></div>
        </div>
      }
    >
      <Switch>
        <Route path="/" component={Dashboard_} exact />
        <Route path="/dashboard" component={Dashboard_} exact />
        <Route
          path="/campaigns-analytics"
          component={CampaignsAnalytics_}
          exact
        />
        <Route path="/rule-management" component={RuleManagement_} exact />
        <Route
          path="/campaigns-portfolio"
          component={CampaignsPortfolio_}
          exact
        />
        <Route path="/product-data" component={ProductData_} exact />
        <Route path="/keyword-list" component={KeywordList_} exact />
        <Route path="/negative-keywords" component={NegativeKeywords_} exact />
        <Route path="/negative-asin" component={NegativeAsin_} exact />
        <Route
          path="/campaign-management"
          component={CampaignManagement_}
          exact
        />
        <Route path="/search-term" component={SearchTerm_} exact />
        <Route
          path="/organic-spon-ranking"
          component={OrganicSponRanking_}
          exact
        />
        <Route path="/setting/manage-user" component={ManageUser_} exact />
        <Route path="/setting/my-profile" component={Profile_} exact />
        <Route path="/callback_sp" component={MarketplaceCredentials_} exact />
        <Route path="/callbackads" component={MarketplaceCredentials_} exact />
        <Route
          path="/setting/marketplace-credentials"
          component={MarketplaceCredentials_}
          exact
        />
        <Route
          path="/application-logs/central-log"
          component={CentralLog_}
          exact
        />
        <Route
          path="/application-logs/system-log"
          component={SystemLog_}
          exact
        />
        <Route path="*" render={() => <div>404</div>} />
      </Switch>
    </Suspense>
  );
}

export default PageRouter;
