import React, { useState } from "react";
import { Modal, Table } from "antd";
import Icons from "../../../../components/icons";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { DefaultPerPage } from "../../../../config";
import Pagination from "../../../../components/pagination";
import moment from "moment-timezone";

const ActionLog = (props) => {
  const {
    visible,
    handleCancel,
    selectedActionLog,
    GetAppliedRuleAction,
    timezone,
    fakeActionRuleManagement,
  } = props;

  console.log(selectedActionLog?.rule_type === "budget", "selectedActionLog");
  const RuleManagementGetAppliedRuleLogResponse = useSelector(
    (state) =>
      state.RuleManagement.RuleManagementGetAppliedRuleLogResponse || {}
  );

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [loading, setLoading] = useState(true);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (RuleManagementGetAppliedRuleLogResponse?.status === true) {
      setList(RuleManagementGetAppliedRuleLogResponse?.data?.records);
      setTotalPage(
        RuleManagementGetAppliedRuleLogResponse?.data?.pagination?.totalCount
      );
      setLoading(false);
      fakeActionRuleManagement("RuleManagementGetAppliedRuleLogResponse");
    } else if (RuleManagementGetAppliedRuleLogResponse?.status === false) {
      setLoading(false);
      setList([]);
      fakeActionRuleManagement("RuleManagementGetAppliedRuleLogResponse");
    }
  }, [RuleManagementGetAppliedRuleLogResponse]);

  useEffect(() => {
    setLoading(true);
    setList([]);
    setPage(1);
    setPageSize(DefaultPerPage);
    GetAppliedRuleAction(selectedActionLog?.type_action, {
      id:
        selectedActionLog?.type_action === "master"
          ? parseInt(selectedActionLog?.master_rule_id || selectedActionLog?.id)
          : parseInt(selectedActionLog?.id),
      page: page,
      perPage: pageSize,
    });
  }, [selectedActionLog]);

  const columns = [
    {
      title: "",
      width: 80,
      render: (e) => {
        return (
          <div>
            {e?.new_value > e?.old_value ? (
              <svg
                width={30}
                height={30}
                fill="#179d06"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M17.591 11.009a.767.767 0 0 0 1.084-1.084L12.543 3.79c-.3-.3-.785-.3-1.084 0L5.325 9.925a.767.767 0 1 0 1.084 1.084L12 5.418l5.591 5.59Zm0 9.2a.767.767 0 0 0 1.084-1.084l-6.133-6.134c-.3-.3-.785-.3-1.084 0l-6.133 6.134a.767.767 0 0 0 1.084 1.084L12 14.618l5.591 5.59Z"
                  clipRule="evenodd"
                />
              </svg>
            ) : (
              <svg
                width={30}
                height={30}
                fill="#9d0606"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M6.409 3.791a.767.767 0 1 0-1.084 1.084l6.133 6.134c.3.3.785.3 1.084 0l6.133-6.134a.767.767 0 0 0-1.084-1.084L12 9.382l-5.591-5.59Zm0 9.2a.767.767 0 0 0-1.084 1.084l6.133 6.134c.3.3.785.3 1.084 0l6.133-6.134a.767.767 0 0 0-1.084-1.084L12 18.582l-5.591-5.59Z"
                  clipRule="evenodd"
                />
              </svg>
            )}
          </div>
        );
      },
    },
    {
      title: "Date",
      dataIndex: "date",
      key: "date",
      width: 120,
    },
    selectedActionLog?.rule_type === "budget"
      ? {}
      : {
          title: "Target Name",
          dataIndex: "targeting_text",
          key: "targeting_text",
          width: 260,
          render: (e) => {
            return <span>{e || "-"}</span>;
          },
        },
    selectedActionLog?.rule_type === "budget"
      ? {}
      : {
          title: "Target Type",
          dataIndex: "match_type",
          key: "match_type",
          width: 260,
          render: (e) => {
            return <span>{e || "-"}</span>;
          },
        },
    selectedActionLog?.rule_type === "budget"
      ? {}
      : {
          title: "Change",
          dataIndex: "change_type",
          key: "change_type",
          width: 260,
          render: (e) => {
            return <span>{e || "-"}</span>;
          },
        },
    {
      title: "Campaign",
      dataIndex: "campaign_name",
      key: "campaign_name",
      width: 360,
      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    selectedActionLog?.rule_type === "budget"
      ? {}
      : {
          title: "AdGroup",
          dataIndex: "ad_group_name",
          key: "ad_group_name",
          width: 360,
          render: (e) => {
            return <span>{e || "-"}</span>;
          },
        },
    {
      title: "Rule",
      dataIndex: "rule_name",
      key: "rule_name",
      width: 260,
      render: (e) => {
        return <span>{e || "-"}</span>;
      },
    },
    {
      title: "Rule Applied Time",
      dataIndex: "rule_applied_time",
      key: "rule_applied_time",
      width: 190,
      render: (e) => {
        return (
          <span>
            {moment(new Date(e * 1000))
              .tz(timezone)
              ?.format("MMM DD, YYYY hh:mm A")}
          </span>
        );
      },
    },

    {
      title: "Old Value",
      dataIndex: "old_value",
      key: "old_value",
      width: 120,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "New Value",
      dataIndex: "new_value",
      key: "new_value",
      width: 120,
      render: (e) => {
        return e || "-";
      },
    },
    {
      title: "Remarks",
      dataIndex: "remark",
      key: "remark",
      width: 230,
      render: (e) => {
        return e || "-";
      },
    },
  ];

  const onPageNo = (e) => {
    setLoading(true);
    setList([]);

    GetAppliedRuleAction(selectedActionLog?.type_action, {
      id:
        selectedActionLog?.type_action === "master"
          ? parseInt(selectedActionLog?.master_rule_id || selectedActionLog?.id)
          : parseInt(selectedActionLog?.id),
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setList([]);
    setPageSize(e);
    setLoading(true);

    GetAppliedRuleAction(selectedActionLog?.type_action, {
      id:
        selectedActionLog?.type_action === "master"
          ? parseInt(selectedActionLog?.master_rule_id || selectedActionLog?.id)
          : parseInt(selectedActionLog?.id),
      page: 1,
      perPage: e,
    });
  };

  return (
    <Modal
      title="Action Logs"
      centered
      maskClosable={false}
      open={visible}
      onOk={() => handleCancel()}
      wrapClassName="custom-modal" // Apply custom CSS class
      onCancel={() => handleCancel()}
      closeIcon={<Icons type="close" />}
      width={850}
      footer={[
        <button
          type="button"
          onClick={() => handleCancel()}
          className="btn btn-primary fs-7"
          data-bs-dismiss="modal"
        >
          Close
        </button>,
      ]}
    >
      <div className="table-responsive">
        <Table
          dataSource={list}
          loading={loading}
          columns={columns}
          scroll={{ y: "600px" }}
          pagination={false}
        />
      </div>
      <Pagination
        loading={loading || list?.length === 0}
        pageSize={pageSize}
        page={page}
        totalPage={totalPage}
        onPerPage={onPerPage}
        onPageNo={onPageNo}
      />
    </Modal>
  );
};

export default ActionLog;
