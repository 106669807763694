import React, { useState } from "react";
import {
  Modal,
  Form,
  Input,
  Switch,
  Select,
  InputNumber,
  Button,
  message,
} from "antd";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const { Option } = Select;

const UpdateAdGroup = (props) => {
  const {
    show,
    onClose,
    SIGN,
    data,
    UpdateAdGroupAction,
    fakeActionCampaignsManagementAnalytics,
    getTable,
  } = props;
  const [form] = Form.useForm();

  const UpdateAdGroupResponse = useSelector(
    (state) => state.CampaignManagement.UpdateAdGroupResponse || {}
  );

  useEffect(() => {
    if (UpdateAdGroupResponse?.status === true) {
      message.destroy();
      message.success(UpdateAdGroupResponse?.message);
      getTable();
      fakeActionCampaignsManagementAnalytics("UpdateAdGroupResponse");
      onClose();
    } else if (UpdateAdGroupResponse?.status === false) {
      message.destroy();
      message.error(UpdateAdGroupResponse?.status);
      fakeActionCampaignsManagementAnalytics("UpdateAdGroupResponse");
    }
  }, [UpdateAdGroupResponse]);
  const handleOk = () => {
    onClose();
  };

  const handleCancel = () => {
    onClose();
  };

  const onFinish = (values) => {
    console.log("Received values:", values);
    onClose();
    // Add your logic to handle the form values
  };

  const okCC = () => {
    form.setFieldsValue({
      new_bid: 1,
    });
  };

  const validateBudget = (_, value) => {
    if ((value && parseFloat(value) < 1) || value === 0) {
      return Promise.reject(
        <>
          <div
            style={{
              position: "relative",

              top: "5px",
            }}
            className="d-flex align-items-center"
          >
            <small
              style={{
                color: "#ff2424",
              }}
            >
              !Enter a budget of at least {SIGN()}1.00
            </small>

            <div
              style={{
                color: "#1e93c6",
                cursor: "pointer",

                marginLeft: "10px",
              }}
              onClick={() => {
                form.setFieldsValue({
                  new_bid: 1,
                });
              }}
            >
              Quick fix
            </div>
          </div>
        </>
      );
    }
    return Promise.resolve();
  };

  return (
    <Modal
      title="Add Ad Group"
      open={show}
      onOk={handleOk}
      onCancel={handleCancel}
      maskClosable={false}
      footer={[
        <Button key="back" onClick={handleCancel}>
          Cancel
        </Button>,
        <Button
          key="submit"
          type="primary"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                const obj = {
                  marketplace_id: data?.marketplace_id,
                  advertising_type: data?.advertising_type,
                  campaign_id: data?.campaign_id,
                  ad_group_id: data?.ad_group_id,
                  new_bid: values?.new_bid,
                  old_bid: data?.marketplace_id,
                  ad_group_name: values?.ad_group_name,
                  ad_group_state: values?.ad_group_state,
                };
                message.destroy();
                message.loading("Loading...", 0);
                UpdateAdGroupAction(data?.id, obj);
              })
              .catch((info) => {
                console.log("Validate Failed:", info);
              });
          }}
        >
          Save
        </Button>,
      ]}
    >
      <Form
        form={form}
        layout="vertical"
        name="ad-group-form"
        id="ad-group-form"
        initialValues={{
          new_bid: data?.default_bid,

          ad_group_name: data?.ad_group_name,
          ad_group_state: data?.ad_group_state,
        }}
      >
        <div className="row">
          <div className="col-8">
            <Form.Item
              label="Ad Group Name"
              name="ad_group_name"
              rules={[
                {
                  required: true,
                  message: "Please input the Ad Group Name!",
                },
              ]}
            >
              <Input />
            </Form.Item>
          </div>
          <div className="col-4">
            <Form.Item
              label="Status"
              name="ad_group_state"
              rules={[
                {
                  required: true,
                  message: "Please select the status!",
                },
              ]}
            >
              <Select>
                <Option value="ENABLED">PAUSED</Option>
                <Option value="PAUSED">Inactive</Option>
              </Select>
            </Form.Item>
          </div>
        </div>
        <div className="row">
          <div className="col-7">
            <Form.Item
              label="Default Bid"
              name="new_bid"
              rules={[
                {
                  required: true,
                  message: "Please input the Default Bid!",
                },
                { validator: validateBudget },
              ]}
            >
              <InputNumber style={{ width: "100%" }} prefix={SIGN()} />
            </Form.Item>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default UpdateAdGroup;
