import axiosCall from "../../../configurations/network-services/axiosCall";

export const ApplicationLogsSystemLogAction = (data) => {
  const path = `application-logs/user-system-logs?page=${
    data?.page || 1
  }&per-page=${data?.perPage || 10}`;
  const responseType = "GET_APPLICATION_LOG_SYSTEM_LOG";
  return axiosCall("get", path, responseType, null, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};
export const fakeActionUserSystemLog = (data) => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_USER_SYSTEM_LOG", state: data });
};
