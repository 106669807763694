import React, { useState } from "react";
import { Modal, Select, Input, Button, Radio } from "antd";

const { Option } = Select;

const Targeting = ({
  visible,
  onClose,
  onApply,
  type,
  data,
  selectedMarketplace,
}) => {
  const [selectedCategory, setSelectedCategory] = useState("1");
  const [budgetAmount, setBudgetAmount] = useState("");

  const handleCategoryChange = (value) => {
    setSelectedCategory(value);
  };

  const handleBudgetChange = (e) => {
    setBudgetAmount(e.target.value);
  };

  const SIGN = () => {
    if (!localStorage.getItem("signs")) return "";
    try {
      return (
        JSON.parse(localStorage.getItem("signs"))?.[selectedMarketplace] || ""
      );
    } catch (error) {
      return "$";
    }
  };

  const handleApply = () => {
    onApply({
      category: selectedCategory,
      amount: budgetAmount,
    });
    onClose();
  };

  return (
    <Modal
      title={type}
      maskClosable={false}
      getPopupContainer={(triggerNode) =>
        document.getElementById("kt_app_content_container").parentNode
      }
      open={visible}
      onCancel={onClose}
      footer={[
        <Button key="close" onClick={onClose}>
          Close
        </Button>,
        <Button key="apply" type="primary" onClick={handleApply}>
          Apply
        </Button>,
      ]}
    >
      <div style={{ maxHeight: "calc(100vh - 350px)", overflow: "auto" }}>
        <Radio.Group>
          {data?.map((d, i) => (
            <Radio value={i}>{d || d?.name}</Radio>
          ))}
        </Radio.Group>
      </div>
    </Modal>
  );
};

export default Targeting;
