import React, { useContext, useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { GlobalContext } from "../commonContext";
import PageRouter from "../routes";
import Footer from "./layouts/footer/Footer";
import Header from "./layouts/header/Header";
import Sidebar from "./layouts/sidebar/Sidebar";
import Wrapper from "./style";
import { EndPoint } from "../config";

function Page() {
  const history = useHistory();
  const contextVar = useContext(GlobalContext);
  const [sidebarHide, setSidebarHide] = useState(false);
  const [screenSize, setScreenSize] = useState(false);

  function updateSize() {
    if (window.innerWidth < 992) {
      setScreenSize(true);
      setSidebarHide(true);
    } else {
      setScreenSize(screenSize);
      setSidebarHide(sidebarHide);
    }
  }

  const findSign = () => {
    const apiUrl = `${EndPoint}api/v1/user-currency-setting`; // Replace with your API URL
    const token = localStorage.getItem("token"); // Replace with your actual bearer token

    // Define the headers with the Authorization header
    const headers = {
      Authorization: `Bearer ${token}`,
    };

    fetch(apiUrl, { headers })
      .then((response) => response.json())
      .then((result) => {
        contextVar.updateCommonGlobalVal("timezone", result?.data?.timezones);
        localStorage.setItem(
          "signs",
          JSON.stringify({
            marketplace_signs: {
              ...result?.data?.currency_codes_by_marketplace,
            },

            ...result?.data?.currency_signs,
            ...result?.data?.currency_signs_by_marketplace,
          })
        );
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };

  useEffect(() => {
    window.addEventListener("resize", updateSize);
    updateSize();
    findSign();

    return () => window.removeEventListener("resize", updateSize);
  }, []);

  if (!localStorage.getItem("token")) {
    return <Redirect to="/login" />;
  }

  return (
    <Wrapper className="d-flex flex-column flex-root">
      <div className="app-page flex-column flex-column-fluid">
        <Header />
        <div
          className="app-wrapper flex-column flex-row-fluid"
          id="kt_app_wrapper"
        >
          <Sidebar hide={sidebarHide} screenSize={screenSize} />
          <div className="app-main flex-column flex-row-fluid" id="kt_app_main">
            <PageRouter />
            <Footer />
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export default Page;
