import Auth from "../../modules/auth/auth.reducer";
import Dashboard from "../../modules/dashboard/dashboard.reducer";
import CampaignsAnalytics from "../../modules/campaigns-analytics/index.reducer";
import CentralLog from "../../modules/application-log/central-log/index.reducer";
import SystemLog from "../../modules/application-log/system-log/index.reducer";
import CampaignManagement from "../../modules/campaign-management/index.reducer";
import User from "../../modules/admin/user/index.reducer";
import MarketplaceCredential from "../../modules/marketplace-credentials/index.reducer";
import RuleManagement from "../../modules/rule-management/index.reducer";
import profileReducer from "../../modules/profile/profile.reducer";

const reducers = {
  Auth,
  Dashboard,
  CampaignsAnalytics,
  CentralLog,
  SystemLog,
  CampaignManagement,
  User,
  MarketplaceCredential,
  RuleManagement,
  profileReducer,
};
export default reducers;
