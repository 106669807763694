import { Input, Select, message } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { SPAPI } from "../../../../config";

export default function (props) {
  const {
    show,
    onHide,
    GetMarketplaceListAction,
    fakeActionMarketplace,
    selectedMarketplace,
    setSelectedMarketplace,
  } = props;

  const [marketPlaceList, setMarketPlaceList] = useState([]);
  const [marketPlaceLoading, setMarketPlaceLoading] = useState(true);

  const GetMarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredential.GetMarketplaceListResponse || {}
  );

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      let make_ = {};
      GetMarketplaceListResponse?.data.forEach((e) => {
        if (!make_[e.region]) {
          make_ = { ...make_, [e.region]: [] };
        }
        make_[e.region]?.push(e);
      });
      setMarketPlaceList(make_);

      setMarketPlaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketPlaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    GetMarketplaceListAction();
    return () => {};
  }, []);

  const getEndPoint = () =>
    selectedMarketplace?.seller_type === "seller"
      ? selectedMarketplace?.sellercentral_url
      : `https://vendorcentral${selectedMarketplace?.sellercentral_url
          ?.split("https://sellercentral")
          .join("")}`;

  return (
    <Modal
      centered
      show={show}
      maskClosable={false}
      onHide={onHide}
      size="lg"
      style={{ zIndex: 9999 }}
    >
      <Modal.Header className="px-5" closeButton>
        <Modal.Title>Add New</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="pe-7"
          id="kt_modal_add_customer_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_customer_header"
          data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
          data-kt-scroll-offset="300px"
          style={{}}
        >
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">
                Seller Account Name<small>*</small>
              </label>
            </div>
            <div className="col-md-8 fv-row">
              <Input
                placeholder="Enter Seller Account Name"
                style={{ height: "38px" }}
                className="ant_common_input"
                size="large"
                id="seller_account_name"
                value={selectedMarketplace?.sellername || ""}
                onChange={(e) => {
                  setSelectedMarketplace({
                    ...selectedMarketplace,
                    sellername: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">
                Seller Type<small>*</small>
              </label>
            </div>
            <div className="col-md-8 fv-row">
              <Select
                size="large"
                style={{ width: "100%" }}
                allowClear={false}
                placeholder="Seller Type"
                value={selectedMarketplace?.seller_type || null}
                onChange={(e) => {
                  setSelectedMarketplace({
                    ...selectedMarketplace,
                    seller_type: e,
                  });
                }}
                options={[
                  {
                    value: "seller",
                    label: "Seller",
                  },
                  {
                    value: "vendor",
                    label: "Vendor",
                  },
                ]}
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">
                Marketplace<small>*</small>
              </label>
            </div>
            <div className="col-md-8 fv-row">
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Select Marketplace"
                loading={marketPlaceLoading}
                value={selectedMarketplace?.marketplace_id || null}
                onChange={(e, _) => {
                  setSelectedMarketplace({
                    ...selectedMarketplace,
                    region_name: _?.region_name,
                    marketplace_id: e,
                    sellercentral_url: _?.sellercentral_url,
                  });
                }}
                options={
                  marketPlaceList &&
                  Object.keys(marketPlaceList)?.length !== 0 &&
                  Object.keys(marketPlaceList)?.map((d, i) => {
                    return {
                      label: d,
                      options: marketPlaceList[d]?.map((r) => {
                        return { label: r?.name, value: r?.id, ...d, ...r };
                      }),
                    };
                  })
                }
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="reset"
          id="kt_modal_add_customer_cancel"
          className="btn btn-light me-3"
          onClick={() => {
            onHide();
          }}
        >
          Discard
        </button>

        <Link
          type="submit"
          id="kt_modal_add_customer_submit"
          className="btn btn-primary"
          target="_blank"
          style={{
            pointerEvents:
              Object.values(selectedMarketplace)?.filter((d) => !d)?.length !==
              0
                ? "none"
                : "auto",
          }}
          disabled={
            Object.values(selectedMarketplace)?.filter((d) => !d)?.length !== 0
          }
          to={{
            pathname: `${getEndPoint()}/apps/authorize/consent?application_id=${SPAPI}&state=${
              selectedMarketplace?.email
            }!!${selectedMarketplace?.sellername.replace(
              /\s/g,
              ""
            )}!!${selectedMarketplace?.region_name.replace(/\s/g, "")}!!${
              selectedMarketplace?.marketplace_id
            }!!${selectedMarketplace?.seller_type}&version=beta`,
          }}
          onClick={() => {
            if (
              Object.values(selectedMarketplace)?.filter((d) => !d)?.length ===
              0
            ) {
              onHide();
              return message.destroy();
            }
            message.destroy();
            return message.error(
              "please make sure that all required fields are not-empty"
            );
          }}
        >
          <span className="indicator-label">Login With Amazon</span>
        </Link>
      </Modal.Footer>
    </Modal>
  );
}
