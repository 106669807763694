import React, { useEffect, useState } from "react";
import { MinusOutlined } from "@ant-design/icons";
import moment from "moment";
import {
  Modal,
  Collapse,
  theme,
  TimePicker,
  Select,
  Input,
  InputNumber,
  DatePicker,
  Radio,
  message,
  Tooltip,
} from "antd";
import dayjs from "dayjs";
import { useSelector } from "react-redux";

function BidManagement({
  handleCancel,
  visible,
  dropdownList,
  allCampaignListLoading,
  allCampaignList,
  fakeActionRuleManagement,
  CreateRuleAction,
  getList,
  selectedMarketplace,
  selectedRow,
  isCloneStatus,
  isUpdateStatus,
  UpdateMasterRuleAction,
  GetAdGroupAllListAction,
  fakeActionCampaignsManagementAnalytics,
  SIGN,
}) {
  const { Panel } = Collapse;

  const CreateNewRuleResponse = useSelector(
    (state) => state.RuleManagement.CreateNewRuleResponse || {}
  );
  const UpdateMasterRuleResponse = useSelector(
    (state) => state.RuleManagement.UpdateMasterRuleResponse || {}
  );
  const GetAdGroupAllListResponse = useSelector(
    (state) => state.CampaignManagement.GetAdGroupAllListResponse || {}
  );

  const [adGroupListLoading, setAdGroupListLoading] = useState(false);
  const [adGroupList, setAdGroupList] = useState([]);

  useEffect(() => {
    if (GetAdGroupAllListResponse?.status === true) {
      setAdGroupListLoading(false);
      setAdGroupList(
        GetAdGroupAllListResponse?.data?.map((d) => ({
          label: d?.ad_group_name,
          value: d?.ad_group_id,
          ...d,
        }))
      );
      fakeActionCampaignsManagementAnalytics("GetAdGroupAllListResponse");
    } else if (GetAdGroupAllListResponse?.status === false) {
      setAdGroupListLoading(false);
      setAdGroupList([]);
      fakeActionCampaignsManagementAnalytics("GetAdGroupAllListResponse");
    }
  }, [GetAdGroupAllListResponse]);
  useEffect(() => {
    if (UpdateMasterRuleResponse?.status === true) {
      message.destroy();
      message.success(UpdateMasterRuleResponse?.message);
      fakeActionRuleManagement("UpdateMasterRuleResponse");
      getList();
      handleCancel();
    } else if (UpdateMasterRuleResponse?.status === false) {
      message.destroy();
      if (Object.keys(UpdateMasterRuleResponse?.error || {})?.length > 0) {
        if (
          typeof Object.values(UpdateMasterRuleResponse?.error)?.[0]?.[0] ===
          "string"
        ) {
          message.error(
            `${Object.values(UpdateMasterRuleResponse?.error)?.[0] || ""}`
          );
        } else {
          message.error(
            `Sub rule - ${
              Object?.values(
                Object.values(UpdateMasterRuleResponse?.error)?.[0]
              )?.[0]?.[0] || ""
            }`
          );
        }
      } else {
        message.error(UpdateMasterRuleResponse?.message);
      }

      fakeActionRuleManagement("UpdateMasterRuleResponse");
    }
  }, [UpdateMasterRuleResponse]);

  useEffect(() => {
    if (CreateNewRuleResponse?.status === true) {
      message.destroy();
      message.success(CreateNewRuleResponse?.message);
      fakeActionRuleManagement("CreateNewRuleResponse");
      getList();
      handleCancel();
    } else if (CreateNewRuleResponse?.status === false) {
      message.destroy();
      if (Object.keys(CreateNewRuleResponse?.error || {})?.length > 0) {
        if (
          typeof Object.values(CreateNewRuleResponse?.error)?.[0]?.[0] ===
          "string"
        ) {
          message.error(
            `${Object.values(CreateNewRuleResponse?.error)?.[0] || ""}`
          );
        } else {
          message.error(
            `Sub rule - ${
              Object?.values(
                Object.values(CreateNewRuleResponse?.error)?.[0]
              )?.[0]?.[0] || ""
            }`
          );
        }
      } else {
        message.error(CreateNewRuleResponse?.message);
      }

      fakeActionRuleManagement("CreateNewRuleResponse");
    }
  }, [CreateNewRuleResponse]);

  const frequencyType = {
    day_type: {
      every_days_count: 1,
      time: [dayjs("10:25", "HH:mm").format("HH:mm")],
    },
    week_type: [
      {
        day_name: "monday",
        time: [dayjs("10:25", "HH:mm").format("HH:mm")],
      },
    ],
    month_type: [
      {
        date_number: 1,
        time: [dayjs("10:25", "HH:mm").format("HH:mm")],
      },
    ],
  };
  const disabledDate = (current) => {
    // Disable past dates
    return current && current > moment().startOf("day");
  };

  const defaultData = {
    rule_name: null,
    rule_status: "ACTIVE",

    bid_change_type: null,
    bid_value: null,
    bid_value_type: null,
    min_bid: null,
    max_bid: null,
    max_afford_bid: null,

    criteria: [
      {
        attribute: null,
        operator: null,
        value: null,
      },
    ],
    frequency: {
      type: null,
      month_type: [
        {
          date_number: null,
          time: [],
        },
      ],
    },

    time_periods: {
      start_date: dayjs(),
      end_date: dayjs(),
      look_back_period: null,
      exclude_day_order: null,
      exclude_days: null,
    },
    exclude_campaigns: [
      {
        exclude_type: null,
        operator: null,
        value: null,
      },
    ],
  };
  const [details, setDetails] = useState({
    marketplace_id: selectedMarketplace,
    move_to_negative_ad_group_ids: null,
    name: null,
    rule_type: "bid",
    rule_status: "ACTIVE",
    campaign_list: [],
    group_id_list: [],
  });

  const [basicInfo, setBasicInfo] = useState([defaultData]);

  useEffect(() => {
    if (Object?.keys(selectedRow)?.length !== 0) {
      const { updated_at, created_at, ...rest } = selectedRow;

      GetAdGroupAllListAction(
        JSON.parse(rest?.group_id_list)?.map((d) => d?.campaign_id)
      );
      setAdGroupListLoading(true);
      setDetails({
        marketplace_id: rest?.marketplace_id,
        name: rest?.name + (isCloneStatus ? " - clone" : ""),
        rule_type: rest?.rule_type,
        rule_status: rest?.rule_status,
        group_id_list: JSON.parse(rest?.group_id_list),
        campaign_list: JSON.parse(rest?.campaign_list),
      });

      setBasicInfo(
        selectedRow?.sub_rule_list?.map((d) => {
          const { created_at, updated_at, ...rest } = d;
          return {
            ...rest,
            campaign_list: JSON.parse(d?.campaign_list),
            criteria: JSON.parse(d?.criteria),
            exclude_campaigns: JSON.parse(d?.exclude_campaigns),
            frequency: JSON.parse(d?.frequency),
            time_periods: JSON.parse(d?.time_periods),
            rule_name: d?.rule_name + (isCloneStatus ? " - clone" : ""),
          };
        })
      );
    }
  }, [selectedRow, isCloneStatus, isUpdateStatus]);
  const updateTimePeriodsStartDate = (gIndex, date) => {
    setBasicInfo((prevBasicInfo) => {
      const updatedBasicInfo = [...prevBasicInfo];
      updatedBasicInfo[gIndex].time_periods.start_date = date;
      return updatedBasicInfo;
    });
  };
  const updateTimePeriodsEndDate = (gIndex, date) => {
    setBasicInfo((prevBasicInfo) => {
      const updatedBasicInfo = [...prevBasicInfo];
      updatedBasicInfo[gIndex].time_periods.end_date = date;
      return updatedBasicInfo;
    });
  };

  const updateRunEvery = (gIndex, type, value) => {
    setBasicInfo((prev) => {
      return prev?.map((d, i) =>
        i === gIndex
          ? { ...d, frequency: { ...d?.frequency, [type]: value } }
          : d
      );
    });
  };

  // Define a function to update max_bid in basicInfo
  const updateMaxAffordBid = (gIndex, newMaxBid) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the max_bid in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].max_afford_bid = newMaxBid;

      // Return the updated state
      return updatedBasicInfo;
    });
  };
  // Define a function to update max_bid in basicInfo
  const updateMaxBid = (gIndex, newMaxBid) => {
    setBasicInfo((prevBasicInfo) => {
      return prevBasicInfo?.map((d, i) => ({
        ...d,
        max_bid: i === gIndex ? newMaxBid : d?.max_bid,
      }));
    });
  };

  // Define a function to update min_bid in basicInfo
  const updateMinBid = (gIndex, newMinBid) => {
    setBasicInfo((prevBasicInfo) => {
      return prevBasicInfo?.map((d, i) => ({
        ...d,
        min_bid: i === gIndex ? newMinBid : d?.min_bid,
      }));
    });
  };

  // Define a function to update the bid_value_type in basicInfo
  const updateBidValueType = (gIndex, newBidValueType) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the bid_value_type in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].bid_value_type = newBidValueType;

      // Return the updated state
      return updatedBasicInfo;
    });
  };

  // Define a function to update the bid_value in basicInfo
  const updateBidValue = (gIndex, newBidValue) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the bid_value in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].bid_value = newBidValue;

      // Return the updated state
      return updatedBasicInfo;
    });
  };

  // Define a function to update the bid_change_type in basicInfo
  const updateBidChangeType = (gIndex, newBidChangeType) => {
    setBasicInfo((prevBasicInfo) => {
      // Create a copy of the previous state
      const updatedBasicInfo = [...prevBasicInfo];

      // Update the bid_change_type in the first element of the array (assuming you want to update the first element)
      updatedBasicInfo[gIndex].bid_change_type = newBidChangeType;

      // Return the updated state
      return updatedBasicInfo;
    });
  };
  // Function to update the 'value' field
  const updateValue = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'value' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].value = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  // Function to update the 'operator' field
  const updateOperator = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'operator' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].operator = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };
  // Function to update the 'attribute' field
  const updateAttribute = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'attribute' field in the first criteria object
    updatedBasicInfo[gIndex].criteria[index].attribute = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };
  // Function to remove an existing criteria object at a specific index
  const removeCriteria = (gIndex, index) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Remove the criteria object at the specified index
    updatedBasicInfo[gIndex].criteria.splice(index, 1);

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };
  // Function to add a new criteria object
  const addCriteria = (index) => {
    const newCriteria = {
      attribute: null,
      operator: null,
      value: null,
    };

    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Add the new criteria object to the cloned array
    updatedBasicInfo[index].criteria.push(newCriteria);

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const addExclude = (index) => {
    const newCriteria = {
      exclude_type: null,
      operator: null,
      value: null,
    };
    const updatedBasicInfo = [...basicInfo];
    updatedBasicInfo[index].exclude_campaigns.push(newCriteria);

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };

  const removeExclude = (gIndex, index) => {
    const updatedBasicInfo = [...basicInfo];
    updatedBasicInfo[gIndex].exclude_campaigns.splice(index, 1);
    setBasicInfo(updatedBasicInfo);
  };
  const updateExcludeCampaignsValue = (gIndex, index, newValue) => {
    // Clone the existing basicInfo array
    const updatedBasicInfo = [...basicInfo];

    // Update the 'attribute' field in the first criteria object
    updatedBasicInfo[gIndex].exclude_campaigns[index].value = newValue;

    // Update the state with the modified array
    setBasicInfo(updatedBasicInfo);
  };
  const updateRuleNameByIndex = (index, newRuleName) => {
    // Create a copy of the basicInfo array to avoid mutating state directly
    const updatedBasicInfo = [...basicInfo];

    // Check if the index is within bounds
    if (index >= 0 && index < updatedBasicInfo.length) {
      updatedBasicInfo[index].rule_name = newRuleName;

      // Update the state with the new array
      setBasicInfo(updatedBasicInfo);
    } else {
      console.error(`Invalid index: ${index}`);
    }
  };

  const onSubmit = () => {
    message.destroy();
    message.loading("Loading...", 0);

    if (isCloneStatus || Object?.keys(selectedRow)?.length === 0) {
      if (details?.group_id_list?.length === 0) {
        message.destroy();
        message.warning("Select Add Group");
        return;
      }
      if (
        basicInfo?.filter(
          (d) => !d?.max_bid || !d?.min_bid || !d?.max_afford_bid
        )?.length !== 0
      ) {
        message.destroy();
        message.warning("Min Bid and Max Bid is required");
        return;
      }
      CreateRuleAction({
        ...details,
        marketplace_id: selectedMarketplace,
        sub_rules: basicInfo,
      });
    } else {
      if (details?.group_id_list?.length === 0) {
        message.destroy();
        message.warning("Select Add Group");
        return;
      }
      if (
        basicInfo?.filter(
          (d) => !d?.max_bid || !d?.min_bid || !d?.max_afford_bid
        )?.length !== 0
      ) {
        message.destroy();
        message.warning("Min Bid and Max Bid is required");
        return;
      }
      UpdateMasterRuleAction(selectedRow?.id, {
        ...details,
        marketplace_id: selectedMarketplace,
        sub_rules: basicInfo,
      });
    }
  };

  const minits = ["00", "05", 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

  function addUniqueGroupId(campaigns, entry) {
    return campaigns.map((campaign) => {
      const uniqueGroupIds = Array.from(
        new Set(
          (campaign.group_ids = [
            ...entry
              ?.filter((e) => e.campaign_id === campaign.campaign_id)
              .map((e) => e.ad_group_id),
          ])
        )
      );

      return {
        ...campaign,
        group_ids: uniqueGroupIds,
      };
    });
  }

  return (
    <Modal
      centered
      maskClosable={false}
      open={visible}
      onOk={() => handleCancel(false)}
      onCancel={() => handleCancel(false)}
      width={750}
      wrapClassName="custom-modal_"
      id="custom-modal_big_rule"
      footer={
        !isUpdateStatus &&
        !isCloneStatus &&
        Object?.keys(selectedRow)?.length !== 0
          ? []
          : [
              <div className="modal-footer">
                <button
                  className="btn btn-light fs-7 me-3"
                  onClick={() => handleCancel(false)}
                >
                  Close
                </button>
                <button
                  onClick={() => onSubmit()}
                  type="button"
                  class="btn btn-primary fs-7"
                >
                  {Object?.keys(selectedRow)?.length === 0
                    ? "Create Bid"
                    : isUpdateStatus
                    ? "Update Bid"
                    : isCloneStatus
                    ? "Clone Bid"
                    : ""}
                </button>
              </div>,
            ]
      }
    >
      <div className="modal-content" id="custom-modal_big_rule">
        <div
          className="modal-header"
          style={{ position: "relative", width: "95%", top: "-20px" }}
        >
          <h2 className="modal-title fs-2 fw-bold">
            {Object?.keys(selectedRow)?.length === 0
              ? "Bid Add"
              : isUpdateStatus
              ? `Bid ${selectedRow?.name || "-"}`
              : isCloneStatus
              ? "Bid Clone"
              : `Bid ${selectedRow?.name || "-"}`}
          </h2>
        </div>
        <div
          className="modal-body pb-7 pt-1 cus-acc"
          style={{
            minHeight: "530px",
            maxHeight: "530px",
            overflow: "auto",
            overflowX: "hidden",
          }}
        >
          <div>
            <div className="row mb-4 g-5 mb-5">
              <div className="col-12">
                <div className="position-relative mr-4">
                  <label className="fw-bolder mb-1">Master Rule Name</label>
                  <Input
                    placeholder="Enter Master Rule Name"
                    type="text"
                    className="form-control fs-7"
                    value={details?.name}
                    disabled={
                      !isUpdateStatus &&
                      !isCloneStatus &&
                      Object?.keys(selectedRow)?.length !== 0
                    }
                    onChange={(e) => {
                      setDetails({
                        ...details,
                        name: e.target.value,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="position-relative mr-4">
                  <label className="fw-bolder mb-1">Campaigns</label>
                  <Select
                    mode="multiple"
                    disabled={
                      !isUpdateStatus &&
                      !isCloneStatus &&
                      Object?.keys(selectedRow)?.length !== 0
                    }
                    maxTagCount="responsive"
                    loading={allCampaignListLoading}
                    filterOption={(input, option) =>
                      (option?.title ?? "")
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    style={{ width: "100%" }}
                    size="large"
                    value={
                      details?.campaign_list &&
                      details?.campaign_list?.map((d) => Object.keys(d)?.[0])
                    }
                    onChange={(e, _) => {
                      const newArray = [];

                      _.forEach((item) => {
                        newArray.push({
                          [item.value]: item.title,
                        });
                      });

                      const selectedData = e
                        ?.map((d) => ({
                          campaign_id: d,
                          group_ids:
                            details?.group_id_list?.find((d) =>
                              e?.includes(d?.campaign_id)
                            )?.group_ids || [],
                        }))
                        ?.reduce((accumulator, current) => {
                          if (
                            !accumulator.some(
                              (item) => item.campaign_id === current.campaign_id
                            )
                          ) {
                            accumulator.push(current);
                          }
                          return accumulator;
                        }, []);

                      const selectedCampaign_id = selectedData?.map(
                        (d) => d?.campaign_id
                      );

                      setAdGroupListLoading(true);
                      GetAdGroupAllListAction(selectedCampaign_id);
                      setDetails({
                        ...details,
                        campaign_list: newArray,
                        group_id_list: selectedData,
                      });
                    }}
                    placeholder="Select Campaigns"
                    // options={allCampaignList?.map((d) => {
                    //   return {
                    //     label: d?.campaign_name?.replace("Vendo |", ""),
                    //     value: d?.campaign_id,
                    //   };
                    // })}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues.map(({ title }) => (
                          <div
                            style={{
                              paddingBottom: "7px",
                              borderBottom: "1px solid #adadad32",
                            }}
                          >
                            {title} <br />
                          </div>
                        ))}
                      >
                        <span>
                          <i className="fa fa-info-circle"></i>
                        </span>
                      </Tooltip>
                    )}
                    options={allCampaignList?.map((d) => ({
                      value: d?.campaign_id,
                      title: d?.campaign_name?.replace("Vendo |", ""),
                      label: (
                        <div
                          style={{
                            maxWidth: "100%",
                            whiteSpace: "normal",
                            wordBreak: "break-all",
                            overflowWrap: "breakWord",
                            hyphens: "auto",
                            paddingBottom: "7px",
                            borderBottom: "1px solid #adadad32",
                          }}
                        >
                          <div>{d?.campaign_name?.replace("Vendo |", "")}</div>
                        </div>
                      ),
                    }))}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="position-relative mr-4 d-grid">
                  <label className="fw-bolder mb-1">Adgroup</label>
                  <Select
                    options={adGroupList}
                    loading={adGroupListLoading}
                    maxTagPlaceholder={(omittedValues) => (
                      <Tooltip
                        overlayStyle={{
                          pointerEvents: "none",
                        }}
                        title={omittedValues.map(({ label }) => (
                          <div
                            style={{
                              paddingBottom: "7px",
                              borderBottom: "1px solid #adadad32",
                            }}
                          >
                            {label} <br />
                          </div>
                        ))}
                      >
                        <span>
                          <i className="fa fa-info-circle"></i>
                        </span>
                      </Tooltip>
                    )}
                    onChange={(e, _, r) => {
                      setDetails({
                        ...details,

                        group_id_list: addUniqueGroupId(
                          details?.group_id_list,
                          _
                        ),
                      });
                    }}
                    value={
                      adGroupListLoading
                        ? []
                        : details?.group_id_list
                            ?.map((d) => d?.group_ids)
                            .flat()
                    }
                    placeholder="Select Adgroup"
                    maxTagCount="responsive"
                    size="large"
                    mode="multiple"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="separator separator-dashed my-4" />

          <div
            className="list_view"
            style={{ maxHeight: "470px", overflow: "auto" }}
          >
            {basicInfo?.map((d, i) => (
              <div
                className="accordion accordion-icon-collapse "
                id="kt_accordion_3"
                key={i}
                style={{
                  padding: "10px",
                  borderRadius: "7px",
                  border: "1px dashed #22a9e6",
                  marginBottom: "10px",
                }}
              >
                <div className="d-flex align-items-center justify-content-between mb-5">
                  <div className=" d-grid">
                    <label className="">
                      <b style={{ fontSize: "12px" }}>
                        Sub Rule Name&nbsp;{i + 1}
                      </b>
                    </label>

                    <Input
                      placeholder="Enter text"
                      disabled={
                        !isUpdateStatus &&
                        !isCloneStatus &&
                        Object?.keys(selectedRow)?.length !== 0
                      }
                      style={{ width: "200px" }}
                      value={d?.rule_name || null}
                      onChange={(e) => {
                        updateRuleNameByIndex(i, e.target.value);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    {" "}
                    {basicInfo?.length < 201 && (
                      <a
                        onClick={() => {
                          if (
                            !isUpdateStatus &&
                            !isCloneStatus &&
                            Object?.keys(selectedRow)?.length !== 0
                          )
                            return;
                          // if (
                          //   Object?.keys(selectedRow)?.length !== 0 &&
                          //   isUpdateStatus
                          // ) {
                          //   message.destroy();
                          //   return message.warning("Not allow");
                          // }

                          if (basicInfo?.length < 201) {
                            let basicInfo_ = basicInfo;
                            basicInfo_?.push(defaultData);
                            setBasicInfo([...basicInfo_]);
                          }
                        }}
                        className="btn btn-light-primary btn-sm btn-icon me-3"
                      >
                        <i className="fa fa-plus" />
                      </a>
                    )}
                    {basicInfo?.length > 1 && (
                      <a
                        href
                        className="btn btn-light-danger btn-sm btn-icon"
                        onClick={() => {
                          if (
                            !isUpdateStatus &&
                            !isCloneStatus &&
                            Object?.keys(selectedRow)?.length !== 0
                          )
                            return;
                          if (
                            Object?.keys(selectedRow)?.length !== 0 &&
                            isUpdateStatus
                          ) {
                            message.destroy();
                            return message.warning("Not allow");
                          }
                          setBasicInfo(basicInfo?.filter((d, o) => o !== i));
                        }}
                      >
                        <i className="fa fa-minus" />
                      </a>
                    )}
                  </div>
                </div>
                <div className="separator separator-dashed my-4" />
                <Collapse
                  size="large"
                  className=""
                  accordion
                  defaultActiveKey={"1"}
                  expandIcon={({ isActive }) =>
                    isActive ? (
                      <i className="ki-duotone ki-minus-square fs-2 accordion-icon-on">
                        <span className="path1" />
                        <span className="path2" />
                      </i>
                    ) : (
                      <i className="ki-duotone ki-plus-square fs-2 accordion-icon-off">
                        <span className="path1" />
                        <span className="path2" />
                        <span className="path3" />
                      </i>
                    )
                  }
                  ghost
                >
                  <Panel
                    header="Criteria"
                    className="collapsed fw-bold"
                    key="1"
                  >
                    <div className="mt-3">
                      {d?.criteria?.map((s, j) => (
                        <div className="row g-9 mb-3 align-items-center ">
                          <div className="col-md-1 text-center">
                            <label className=" fs-5 fw-bold mb-0 d-flex">
                              {j !== 0 && <div>And</div>}&nbsp;
                              <div>If</div>
                            </label>
                          </div>
                          <div className="col-md-3">
                            <Select
                              disabled={
                                !isUpdateStatus &&
                                !isCloneStatus &&
                                Object?.keys(selectedRow)?.length !== 0
                              }
                              allowClear
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              value={s?.attribute}
                              options={Object?.entries(
                                dropdownList?.attributes_dd_data || {}
                              )?.map(([key, value], i) => ({
                                label: value,
                                value: key,
                              }))}
                              onChange={(e) => updateAttribute(i, j, e)}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="col-md-3">
                            <Select
                              getPopupContainer={(triggerNode) =>
                                triggerNode.parentNode
                              }
                              disabled={
                                !isUpdateStatus &&
                                !isCloneStatus &&
                                Object?.keys(selectedRow)?.length !== 0
                              }
                              allowClear
                              value={s?.operator}
                              onChange={(e) => updateOperator(i, j, e)}
                              options={Object?.entries(
                                dropdownList?.operators_dd_data || {}
                              )?.map(([key, value], i) => ({
                                label: value,
                                value: key,
                              }))}
                              style={{ width: "100%" }}
                            />
                          </div>
                          <div className="col-md-3">
                            <Input
                              type="text"
                              disabled={
                                !isUpdateStatus &&
                                !isCloneStatus &&
                                Object?.keys(selectedRow)?.length !== 0
                              }
                              className="form-control form-control-sm fs-7"
                              value={s?.value}
                              onChange={(e) => {
                                const inputValue = e.target.value;

                                const isNonNegativeNumber =
                                  /^(\d*\.?\d+|\d+\.?\d*)$/.test(inputValue);

                                if (isNonNegativeNumber || inputValue === "") {
                                  updateValue(i, j, inputValue);
                                }
                              }}
                            />
                          </div>
                          <div className="col-md-2 px-0 ">
                            <div className="d-flex">
                              {d?.criteria?.length < 5 && (
                                <a
                                  onClick={() => {
                                    if (
                                      !isUpdateStatus &&
                                      !isCloneStatus &&
                                      Object?.keys(selectedRow)?.length !== 0
                                    )
                                      return;
                                    addCriteria(i);
                                  }}
                                  className="btn btn-light-primary btn-sm btn-icon me-3"
                                >
                                  <i className="fa fa-plus" />
                                </a>
                              )}
                              {d?.criteria?.length > 1 && (
                                <a
                                  href
                                  className="btn btn-light-danger btn-sm btn-icon"
                                  onClick={() => {
                                    if (
                                      !isUpdateStatus &&
                                      !isCloneStatus &&
                                      Object?.keys(selectedRow)?.length !== 0
                                    )
                                      return;
                                    removeCriteria(i, j);
                                  }}
                                >
                                  <i className="fa fa-minus" />
                                </a>
                              )}
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </Panel>
                  <Panel header="Action" className="collapsed fw-bold" key="2">
                    <div className="mt-5">
                      <div className="row mb-5">
                        <div className="col-md-12">
                          <div className="d-flex gap-5 mt-0">
                            <div>
                              <label className="fw-bolder mb-0 fs-6 w-150px">
                                Bid Change Type
                              </label>
                            </div>
                            <Radio.Group
                              disabled={
                                !isUpdateStatus &&
                                !isCloneStatus &&
                                Object?.keys(selectedRow)?.length !== 0
                              }
                              size="large"
                              value={d?.bid_change_type}
                              onChange={(e) =>
                                updateBidChangeType(i, e.target.value)
                              }
                            >
                              <Radio value="INCREASE">Increase</Radio>
                              <Radio value="DECREASE">Decrease</Radio>
                            </Radio.Group>
                          </div>
                        </div>
                      </div>
                      <div className="row g-9 mb-5 align-items-center">
                        <div className="col-md-3 ">
                          <div>
                            <label className="fw-bolder mb-0 fs-6">
                              Set Bid
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="d-flex ">
                            <div className="me-4 min-w-100px">
                              <Input
                                disabled={
                                  !isUpdateStatus &&
                                  !isCloneStatus &&
                                  Object?.keys(selectedRow)?.length !== 0
                                }
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    inputValue === "" ||
                                    parseFloat(inputValue) >= 0
                                  ) {
                                    updateBidValue(i, inputValue);
                                  }
                                }}
                                value={d?.bid_value}
                                type="number"
                                placeholder="Daily Bid"
                              />
                            </div>
                            <div className="min-w-125px">
                              <Select
                                getPopupContainer={(triggerNode) =>
                                  triggerNode.parentNode
                                }
                                disabled={
                                  !isUpdateStatus &&
                                  !isCloneStatus &&
                                  Object?.keys(selectedRow)?.length !== 0
                                }
                                onChange={(e) => updateBidValueType(i, e)}
                                options={[
                                  { label: "Amount", value: "AMOUNT" },
                                  { label: "Percentage", value: "PERCENTAGE" },
                                ]}
                                value={d?.bid_value_type}
                                style={{ width: "100%" }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mt-0">
                            <small className="fs-8">
                              {d?.bid_change_type === "INCREASE"
                                ? `Increase current bid by ${
                                    d?.bid_value_type === "AMOUNT"
                                      ? SIGN()
                                      : "%"
                                  }`
                                : `Reduce current bid by ${
                                    d?.bid_value_type === "AMOUNT"
                                      ? SIGN()
                                      : "%"
                                  }`}
                            </small>
                          </div>
                        </div>
                      </div>
                      <div className="row g-9 mb-5 align-items-center">
                        <div className="col-md-3 ">
                          <div>
                            <label className="fw-bolder mb-0 fs-6">
                              Set Bid Range<sug style={{ color: "red" }}>*</sug>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="d-flex">
                            <div className="me-4 min-w-100px">
                              <InputNumber
                                onChange={(value) => {
                                  if (value >= 0 || value === undefined) {
                                    updateMinBid(i, value);
                                  }
                                }}
                                disabled={
                                  !isUpdateStatus &&
                                  !isCloneStatus &&
                                  Object?.keys(selectedRow)?.length !== 0
                                }
                                value={d?.min_bid}
                                placeholder="Min"
                              />
                            </div>
                            <div className="min-w-100px">
                              <InputNumber
                                onChange={(value) => {
                                  if (value >= 0 || value === undefined) {
                                    updateMaxBid(i, value);
                                  }
                                }}
                                disabled={
                                  !isUpdateStatus &&
                                  !isCloneStatus &&
                                  Object?.keys(selectedRow)?.length !== 0
                                }
                                value={d?.max_bid}
                                placeholder="Max"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mt-0">
                            {/* Increase current bid by 20%,<br /> Reduce current bid by 20% */}
                          </div>
                        </div>
                      </div>
                      <div className="row g-9 mb-0 align-items-center">
                        <div className="col-md-3 ">
                          <div>
                            <label className="fw-bolder mb-0 fs-6">
                              Max. Affordable Bid
                              <sug style={{ color: "red" }}>*</sug>
                            </label>
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div>
                            <InputNumber
                              onChange={(value) => {
                                if (value >= 0 || value === undefined) {
                                  updateMaxAffordBid(i, value);
                                }
                              }}
                              value={d?.max_afford_bid}
                              disabled={
                                !isUpdateStatus &&
                                !isCloneStatus &&
                                Object?.keys(selectedRow)?.length !== 0
                              }
                              placeholder="Max Bid"
                            />
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mt-0">
                            {/* Increase current bid by 20%,<br /> Reduce current bid by 20% */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                  <Panel
                    header="Frequency"
                    className="collapsed fw-bold"
                    key="3"
                  >
                    <div className="row g-5 mb-5 align-items-center mt-5">
                      <div class="col-md-2">
                        <div>
                          <label className="fw-bolder mb-0 fs-6">
                            Run Every
                          </label>
                        </div>
                      </div>

                      <div class="col-3 d-flex align-items-center">
                        <Select
                          getPopupContainer={(triggerNode) =>
                            triggerNode.parentNode
                          }
                          style={{ width: "100%" }}
                          disabled={
                            !isUpdateStatus &&
                            !isCloneStatus &&
                            Object?.keys(selectedRow)?.length !== 0
                          }
                          className="fs-7"
                          onChange={(e) => {
                            setBasicInfo((prev) => {
                              return prev?.map((v, index) =>
                                index === i
                                  ? {
                                      ...v,
                                      frequency: {
                                        type: e,
                                        [`${e}_type`]:
                                          frequencyType[`${e}_type`],
                                      },
                                    }
                                  : v
                              );
                            });
                          }}
                          value={d?.frequency?.type || null}
                          options={[
                            {
                              label: "Days",
                              value: "day",
                            },
                            {
                              label: "Week",
                              value: "week",
                            },
                            {
                              label: "Months",
                              value: "month",
                            },
                          ]}
                        />
                        {d?.frequency?.type && d?.frequency?.type !== "day" && (
                          <Select
                            style={{ width: "100px", marginLeft: "8px" }}
                            disabled={
                              !isUpdateStatus &&
                              !isCloneStatus &&
                              Object?.keys(selectedRow)?.length !== 0
                            }
                            value={
                              d?.frequency?.month_type?.length ||
                              d?.frequency?.week_type?.length
                            }
                            getPopupContainer={(triggerNode) =>
                              triggerNode.parentNode
                            }
                            onChange={(e) => {
                              const listSize =
                                d?.frequency?.month_type?.length ||
                                d?.frequency?.week_type?.length;

                              if (listSize > e) {
                                return updateRunEvery(
                                  i,
                                  `${d?.frequency?.type}_type`,
                                  [
                                    ...d.frequency?.[
                                      `${d?.frequency?.type}_type`
                                    ].slice(0, e),
                                  ]
                                );
                              }
                              const copiedObjects = Array(
                                e -
                                  (d?.frequency?.month_type?.length ||
                                    d?.frequency?.week_type?.length)
                              )
                                .fill(null)
                                .map(() => ({
                                  ...frequencyType[
                                    `${d?.frequency?.type}_type`
                                  ]?.[0],
                                }));

                              updateRunEvery(i, `${d?.frequency?.type}_type`, [
                                ...d.frequency?.[`${d?.frequency?.type}_type`],
                                ...copiedObjects,
                              ]);
                            }}
                            options={Array(12)
                              .fill(1)
                              ?.map((_, i) => {
                                return { label: i + 1, value: i + 1 };
                              })}
                          />
                        )}
                      </div>
                      {d?.frequency?.type === "day" && (
                        <div
                          className="row mt-10"
                          style={{
                            background: "#dbf4ff",
                            padding: "10px",
                            borderRadius: "7px",
                          }}
                        >
                          <div className="col-12">
                            <div
                              className="row"
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <div className="col-2">
                                <Select
                                  style={{ width: "200px" }}
                                  disabled={
                                    !isUpdateStatus &&
                                    !isCloneStatus &&
                                    Object?.keys(selectedRow)?.length !== 0
                                  }
                                  value={
                                    d?.frequency?.day_type?.every_days_count
                                  }
                                  allowClear={false}
                                  getPopupContainer={(triggerNode) =>
                                    triggerNode.parentNode
                                  }
                                  onChange={(e) => {
                                    const newEveryDaysCount = e; // New value for every_days_count
                                    setBasicInfo((prev) => {
                                      return prev?.map((v, index) =>
                                        index === i
                                          ? {
                                              ...v,
                                              frequency: {
                                                ...v?.frequency,
                                                day_type: {
                                                  ...v.frequency.day_type,
                                                  every_days_count:
                                                    newEveryDaysCount,
                                                },
                                              },
                                            }
                                          : v
                                      );
                                    });
                                  }}
                                  options={Array(31)
                                    .fill("1")
                                    ?.map((_, i) => {
                                      return { label: i + 1, value: i + 1 };
                                    })}
                                />
                              </div>
                              <small
                                style={{
                                  fontSize: "14px",
                                  fontWeight: 600,
                                  display: "flex",
                                  alignItems: "center",
                                }}
                                className="col-8"
                              >
                                This rule will be executed every{" "}
                                {d?.frequency?.day_type?.every_days_count} days
                                on the number of times time has been added
                              </small>
                            </div>
                          </div>
                          <div
                            className="col-12 mt-5"
                            style={{
                              background: "rgb(161 216 244 / 21%)",
                              padding: "20px",
                              borderRadius: "7px",
                              overflow: "auto",
                              maxHeight: "170px",
                              border: "2px dashed #a1d8f4",
                            }}
                          >
                            <div className="row">
                              {d?.frequency?.day_type?.time?.map((w, v) => (
                                <div className="col-6 mb-2" key={v}>
                                  <div className="d-flex">
                                    <TimePicker
                                      disabled={
                                        !isUpdateStatus &&
                                        !isCloneStatus &&
                                        Object?.keys(selectedRow)?.length !== 0
                                      }
                                      style={{
                                        width: "150px",
                                      }}
                                      className="me-4"
                                      allowClear={false}
                                      minuteStep={5}
                                      format="HH:mm"
                                      value={dayjs(w, "HH:mm")}
                                      on
                                      onChange={(e) => {
                                        if (
                                          minits?.findIndex(
                                            (d) =>
                                              parseInt(d) ===
                                              parseInt(dayjs(e).format("mm"))
                                          ) === -1
                                        )
                                          return message.warning(
                                            "Select valid time"
                                          );

                                        const timeIndexToUpdate = v; // Index of the time entry to update
                                        const newTimeValue =
                                          dayjs(e).format("HH:mm"); // New time value you want to set

                                        setBasicInfo((prev) => {
                                          const updatedTimeArray = [
                                            ...d.frequency.day_type.time,
                                          ];
                                          updatedTimeArray[timeIndexToUpdate] =
                                            newTimeValue;

                                          return prev?.map((v, index) =>
                                            i === index
                                              ? {
                                                  ...v,
                                                  frequency: {
                                                    ...v?.frequency,
                                                    day_type: {
                                                      ...v.frequency.day_type,
                                                      time: updatedTimeArray,
                                                    },
                                                  },
                                                }
                                              : v
                                          );
                                        });
                                      }}
                                    />
                                    <div className="d-flex">
                                      {d?.frequency?.day_type?.time?.length >
                                        1 && (
                                        <a
                                          onClick={() => {
                                            if (
                                              !isUpdateStatus &&
                                              !isCloneStatus &&
                                              Object?.keys(selectedRow)
                                                ?.length !== 0
                                            )
                                              return;
                                            const timeIndexToRemove = v; // Index of the time entry to remove

                                            setBasicInfo((prev) => {
                                              const updatedTimeArray =
                                                d.frequency.day_type.time.filter(
                                                  (_, index) =>
                                                    index !== timeIndexToRemove
                                                );
                                              return prev?.map((v, index) =>
                                                i === index
                                                  ? {
                                                      ...v,
                                                      frequency: {
                                                        ...v?.frequency,
                                                        day_type: {
                                                          ...v.frequency
                                                            .day_type,
                                                          time: updatedTimeArray,
                                                        },
                                                      },
                                                    }
                                                  : v
                                              );
                                            });
                                          }}
                                          className="btn btn-light-danger btn-sm btn-icon me-3"
                                        >
                                          <i className="fa fa-minus" />
                                        </a>
                                      )}
                                      <a
                                        onClick={() => {
                                          if (
                                            !isUpdateStatus &&
                                            !isCloneStatus &&
                                            Object?.keys(selectedRow)
                                              ?.length !== 0
                                          )
                                            return;
                                          const newTime = dayjs(
                                            "10:25",
                                            "HH:mm"
                                          ).format("HH:mm"); // New time to add
                                          setBasicInfo((prev) => {
                                            return prev?.map((v, index) => {
                                              const updatedTimeArray = [
                                                ...v.frequency.day_type.time,
                                                newTime,
                                              ];
                                              return i === index
                                                ? {
                                                    ...v,
                                                    frequency: {
                                                      ...v?.frequency,
                                                      day_type: {
                                                        ...v.frequency.day_type,
                                                        time: updatedTimeArray,
                                                      },
                                                    },
                                                  }
                                                : v;
                                            });
                                          });
                                        }}
                                        className="btn btn-light-primary btn-sm btn-icon me-3"
                                      >
                                        <i className="fa fa-plus" />
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      )}
                      {d?.frequency?.type === "month" &&
                        d?.frequency?.month_type?.map((df, indexM) => (
                          <div
                            className="row mt-10"
                            key={indexM}
                            style={{
                              background: "#dbf4ff",
                              padding: "10px",
                              borderRadius: "7px",
                            }}
                          >
                            <div className="col-12">
                              <div
                                className="row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="col-2">
                                  <Select
                                    disabled={
                                      !isUpdateStatus &&
                                      !isCloneStatus &&
                                      Object?.keys(selectedRow)?.length !== 0
                                    }
                                    style={{ width: "120px" }}
                                    value={df?.date_number}
                                    allowClear={false}
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    onChange={(e) => {
                                      const updatedMonthType = [
                                        ...d.frequency.month_type,
                                      ];
                                      updatedMonthType[indexM] = {
                                        ...updatedMonthType[indexM],
                                        date_number: e,
                                      };

                                      const updatedFrequency = {
                                        ...d.frequency,
                                        month_type: updatedMonthType,
                                      };

                                      setBasicInfo((prev) => {
                                        return prev?.map((vf, index) =>
                                          i === index
                                            ? {
                                                ...vf,
                                                frequency: updatedFrequency,
                                              }
                                            : vf
                                        );
                                      });
                                    }}
                                    options={[
                                      { label: 1, value: 1 },
                                      { label: 2, value: 2 },
                                      { label: 3, value: 3 },
                                      { label: 4, value: 4 },
                                      { label: 5, value: 5 },
                                      { label: 6, value: 6 },
                                      { label: 7, value: 7 },
                                      { label: 8, value: 8 },
                                      { label: 9, value: 9 },
                                      { label: 10, value: 10 },
                                      { label: 11, value: 11 },
                                      { label: 12, value: 12 },
                                      { label: 13, value: 13 },
                                      { label: 14, value: 14 },
                                      { label: 15, value: 15 },
                                      { label: 16, value: 16 },
                                      { label: 17, value: 17 },
                                      { label: 18, value: 18 },
                                      { label: 19, value: 19 },
                                      { label: 20, value: 20 },
                                      { label: 21, value: 21 },
                                      { label: 22, value: 22 },
                                      { label: 23, value: 23 },
                                      { label: 24, value: 24 },
                                      { label: 25, value: 25 },
                                      { label: 26, value: 26 },
                                      { label: 27, value: 27 },
                                      { label: 28, value: 28 },
                                      { label: 29, value: 29 },
                                      { label: 30, value: 30 },
                                      { label: 31, value: 31 },
                                    ]}
                                  />
                                </div>
                                <small
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="col-7"
                                >
                                  This rule will be executed at{" "}
                                  {df?.date_number} of every month
                                </small>
                                <div
                                  className="col-2"
                                  style={{
                                    position: "relative",
                                    top: "-28px",
                                    left: "10px",
                                  }}
                                >
                                  <div className="d-flex">
                                    {d?.frequency?.month_type?.length > 1 && (
                                      <a
                                        onClick={() => {
                                          setBasicInfo((prev) => {
                                            return prev?.map((du, iu) => {
                                              if (iu === i) {
                                                const filterMonth =
                                                  du?.frequency?.month_type?.filter(
                                                    (w, aa) => aa !== indexM
                                                  );
                                                return {
                                                  ...du,
                                                  frequency: {
                                                    ...du?.frequency,
                                                    month_type: filterMonth,
                                                  },
                                                };
                                              }
                                              return du;
                                            });
                                          });
                                        }}
                                        className="btn btn-light-danger btn-sm btn-icon me-3"
                                      >
                                        <i className="fa fa-minus" />
                                      </a>
                                    )}
                                    <a
                                      onClick={() => {
                                        if (
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        )
                                          return;
                                        const newMonthTypeObject = {
                                          date_number: 1,
                                          time: [dayjs().format("HH:mm")],
                                        };

                                        const updatedFrequency = {
                                          ...d.frequency,
                                          month_type: [
                                            ...d.frequency.month_type,
                                            newMonthTypeObject,
                                          ],
                                        };

                                        setBasicInfo((prev) => {
                                          return prev?.map((vf, index) =>
                                            i === index
                                              ? {
                                                  ...vf,
                                                  frequency: updatedFrequency,
                                                }
                                              : vf
                                          );
                                        });
                                      }}
                                      className="btn btn-light-primary btn-sm btn-icon me-3"
                                    >
                                      <i className="fa fa-plus" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-12 mt-5"
                              style={{
                                background: "rgb(161 216 244 / 21%)",
                                padding: "20px",
                                borderRadius: "7px",
                                overflow: "auto",
                                maxHeight: "170px",
                                border: "2px dashed #a1d8f4",
                              }}
                            >
                              <div className="row">
                                {df?.time?.map((w, v) => (
                                  <div className="col-6 mb-2" key={v}>
                                    <div className="d-flex">
                                      <TimePicker
                                        disabled={
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        }
                                        style={{ width: "150px" }}
                                        minuteStep={5}
                                        allowClear={false}
                                        className="me-4"
                                        format="HH:mm"
                                        value={dayjs(w, "HH:mm")}
                                        onChange={(e) => {
                                          if (
                                            minits?.findIndex(
                                              (d) =>
                                                parseInt(d) ===
                                                parseInt(dayjs(e).format("mm"))
                                            ) === -1
                                          )
                                            return message.warning(
                                              "Select valid time"
                                            );
                                          const monthTypeIndexToUpdate = indexM; // Index of the month_type object to update
                                          const timeIndexToUpdate = v; // Index of the time entry to update
                                          const newTimeValue =
                                            dayjs(e).format("HH:mm"); // New time value you want to set

                                          setBasicInfo((prevData) => {
                                            return prevData?.map((td, ti) => {
                                              if (ti === i) {
                                                const updatedMonthType = [
                                                  ...td.frequency.month_type,
                                                ];
                                                const updatedTimeArray = [
                                                  ...updatedMonthType[
                                                    monthTypeIndexToUpdate
                                                  ].time,
                                                ];
                                                updatedTimeArray[
                                                  timeIndexToUpdate
                                                ] = newTimeValue;

                                                updatedMonthType[
                                                  monthTypeIndexToUpdate
                                                ] = {
                                                  ...updatedMonthType[
                                                    monthTypeIndexToUpdate
                                                  ],
                                                  time: updatedTimeArray,
                                                };
                                                return {
                                                  ...td,
                                                  frequency: {
                                                    ...td.frequency,
                                                    month_type:
                                                      updatedMonthType,
                                                  },
                                                };
                                              }
                                              return td;
                                            });
                                          });
                                        }}
                                      />
                                      <div className="d-flex">
                                        {df?.time?.length > 1 && (
                                          <a
                                            onClick={() => {
                                              if (
                                                !isUpdateStatus &&
                                                !isCloneStatus &&
                                                Object?.keys(selectedRow)
                                                  ?.length !== 0
                                              )
                                                return;
                                              const monthTypeIndexToRemove =
                                                indexM; // Index of the month_type object to update
                                              const timeIndexToRemove = v; // Index of the time entry to remove

                                              setBasicInfo((prevData) => {
                                                return prevData?.map(
                                                  (rdt, rdi) => {
                                                    if (rdi === i) {
                                                      const updatedMonthType = [
                                                        ...rdt.frequency
                                                          .month_type,
                                                      ];
                                                      const updatedTimeArray = [
                                                        ...updatedMonthType[
                                                          monthTypeIndexToRemove
                                                        ].time,
                                                      ];
                                                      updatedTimeArray.splice(
                                                        timeIndexToRemove,
                                                        1
                                                      );

                                                      updatedMonthType[
                                                        monthTypeIndexToRemove
                                                      ] = {
                                                        ...updatedMonthType[
                                                          monthTypeIndexToRemove
                                                        ],
                                                        time: updatedTimeArray,
                                                      };
                                                      return {
                                                        ...rdt,
                                                        frequency: {
                                                          ...rdt?.frequency,
                                                          month_type:
                                                            updatedMonthType,
                                                        },
                                                      };
                                                    }
                                                    return rdt;
                                                  }
                                                );
                                              });
                                            }}
                                            className="btn btn-light-danger btn-sm btn-icon me-3"
                                          >
                                            <i className="fa fa-minus" />
                                          </a>
                                        )}
                                        <a
                                          onClick={() => {
                                            if (
                                              !isUpdateStatus &&
                                              !isCloneStatus &&
                                              Object?.keys(selectedRow)
                                                ?.length !== 0
                                            )
                                              return;
                                            const currentTime = dayjs(
                                              "10:25",
                                              "HH:mm"
                                            ).format("HH:mm");

                                            setBasicInfo((prevData) => {
                                              return prevData?.map(
                                                (adt, adi) => {
                                                  if (adi === i) {
                                                    const updatedMonthType = [
                                                      ...adt.frequency
                                                        .month_type,
                                                    ];
                                                    updatedMonthType[
                                                      indexM
                                                    ].time.push(currentTime);
                                                    return {
                                                      ...adt,
                                                      frequency: {
                                                        ...adt.frequency,
                                                        month_type:
                                                          updatedMonthType,
                                                      },
                                                    };
                                                  }
                                                  return adt;
                                                }
                                              );
                                            });
                                          }}
                                          className="btn btn-light-primary btn-sm btn-icon me-3"
                                        >
                                          <i className="fa fa-plus" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}

                      {d?.frequency?.type === "week" &&
                        d?.frequency?.week_type?.map((df, indexM) => (
                          <div
                            className="row mt-10"
                            key={indexM}
                            style={{
                              background: "#dbf4ff",
                              padding: "10px",
                              borderRadius: "7px",
                            }}
                          >
                            <div className="col-12">
                              <div
                                className="row"
                                style={{
                                  display: "flex",
                                  justifyContent: "space-between",
                                }}
                              >
                                <div className="col-2">
                                  <Select
                                    style={{ width: "100px" }}
                                    value={df?.day_name}
                                    disabled={
                                      !isUpdateStatus &&
                                      !isCloneStatus &&
                                      Object?.keys(selectedRow)?.length !== 0
                                    }
                                    allowClear={false}
                                    getPopupContainer={(triggerNode) =>
                                      triggerNode.parentNode
                                    }
                                    onChange={(e) => {
                                      const updatedMonthType = [
                                        ...d.frequency.week_type,
                                      ];
                                      updatedMonthType[indexM] = {
                                        ...updatedMonthType[indexM],
                                        day_name: e,
                                      };

                                      const updatedFrequency = {
                                        ...d.frequency,
                                        week_type: updatedMonthType,
                                      };

                                      setBasicInfo((prev) => {
                                        return prev?.map((vf, index) =>
                                          i === index
                                            ? {
                                                ...vf,
                                                frequency: updatedFrequency,
                                              }
                                            : vf
                                        );
                                      });
                                    }}
                                    options={[
                                      { label: "Sunday", value: "sunday" },
                                      { label: "Monday", value: "monday" },
                                      { label: "Tuesday", value: "tuesday" },
                                      {
                                        label: "Wednesday",
                                        value: "wednesday",
                                      },
                                      {
                                        label: "Thursday",
                                        value: "thursday",
                                      },
                                      { label: "Friday", value: "friday" },
                                      {
                                        label: "Saturday",
                                        value: "saturday",
                                      },
                                    ]}
                                  />
                                </div>
                                <small
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: 600,
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                  className="col-7"
                                >
                                  This rule will be executed at {df?.day_name}{" "}
                                  of every week
                                </small>
                                <div
                                  className="col-2"
                                  style={{
                                    position: "relative",
                                    top: "-28px",
                                    left: "10px",
                                  }}
                                >
                                  <div className="d-flex">
                                    {d?.frequency?.week_type?.length > 1 && (
                                      <a
                                        onClick={() => {
                                          if (
                                            !isUpdateStatus &&
                                            !isCloneStatus &&
                                            Object?.keys(selectedRow)
                                              ?.length !== 0
                                          )
                                            return;
                                          setBasicInfo((prev) => {
                                            return prev?.map((du, iu) => {
                                              if (iu === i) {
                                                const filterMonth =
                                                  du?.frequency?.week_type?.filter(
                                                    (w, aa) => aa !== indexM
                                                  );
                                                return {
                                                  ...du,
                                                  frequency: {
                                                    ...du?.frequency,
                                                    week_type: filterMonth,
                                                  },
                                                };
                                              }
                                              return du;
                                            });
                                          });
                                        }}
                                        className="btn btn-light-danger btn-sm btn-icon me-3"
                                      >
                                        <i className="fa fa-minus" />
                                      </a>
                                    )}
                                    <a
                                      onClick={() => {
                                        if (
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        )
                                          return;
                                        const newMonthTypeObject = {
                                          day_name: "monday",
                                          time: [dayjs().format("HH:mm")],
                                        };

                                        const updatedFrequency = {
                                          ...d.frequency,
                                          week_type: [
                                            ...d.frequency.week_type,
                                            newMonthTypeObject,
                                          ],
                                        };

                                        setBasicInfo((prev) => {
                                          return prev?.map((vf, index) =>
                                            i === index
                                              ? {
                                                  ...vf,
                                                  frequency: updatedFrequency,
                                                }
                                              : vf
                                          );
                                        });
                                      }}
                                      className="btn btn-light-primary btn-sm btn-icon me-3"
                                    >
                                      <i className="fa fa-plus" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              className="col-12 mt-5"
                              style={{
                                background: "rgb(161 216 244 / 21%)",
                                padding: "20px",
                                borderRadius: "7px",
                                overflow: "auto",
                                maxHeight: "170px",
                                border: "2px dashed #a1d8f4",
                              }}
                            >
                              <div className="row">
                                {df?.time?.map((w, v) => (
                                  <div className="col-6 mb-2" key={v}>
                                    <div className="d-flex">
                                      <TimePicker
                                        style={{ width: "150px" }}
                                        minuteStep={5}
                                        className="me-4"
                                        allowClear={false}
                                        disabled={
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        }
                                        format="HH:mm"
                                        value={dayjs(w, "HH:mm")}
                                        onChange={(e) => {
                                          if (
                                            minits?.findIndex(
                                              (d) =>
                                                parseInt(d) ===
                                                parseInt(dayjs(e).format("mm"))
                                            ) === -1
                                          )
                                            return message.warning(
                                              "Select valid time"
                                            );
                                          const monthTypeIndexToUpdate = indexM; // Index of the month_type object to update
                                          const timeIndexToUpdate = v; // Index of the time entry to update
                                          const newTimeValue =
                                            dayjs(e).format("HH:mm"); // New time value you want to set

                                          setBasicInfo((prevData) => {
                                            return prevData?.map((td, ti) => {
                                              if (ti === i) {
                                                const updatedMonthType = [
                                                  ...td.frequency.week_type,
                                                ];
                                                const updatedTimeArray = [
                                                  ...updatedMonthType[
                                                    monthTypeIndexToUpdate
                                                  ].time,
                                                ];
                                                updatedTimeArray[
                                                  timeIndexToUpdate
                                                ] = newTimeValue;

                                                updatedMonthType[
                                                  monthTypeIndexToUpdate
                                                ] = {
                                                  ...updatedMonthType[
                                                    monthTypeIndexToUpdate
                                                  ],
                                                  time: updatedTimeArray,
                                                };
                                                return {
                                                  ...td,
                                                  frequency: {
                                                    ...td.frequency,
                                                    week_type: updatedMonthType,
                                                  },
                                                };
                                              }
                                              return td;
                                            });
                                          });
                                        }}
                                      />
                                      <div className="d-flex">
                                        {df?.time?.length > 1 && (
                                          <a
                                            onClick={() => {
                                              if (
                                                !isUpdateStatus &&
                                                !isCloneStatus &&
                                                Object?.keys(selectedRow)
                                                  ?.length !== 0
                                              )
                                                return;
                                              const monthTypeIndexToRemove =
                                                indexM; // Index of the month_type object to update
                                              const timeIndexToRemove = v; // Index of the time entry to remove

                                              setBasicInfo((prevData) => {
                                                return prevData?.map(
                                                  (rdt, rdi) => {
                                                    if (rdi === i) {
                                                      const updatedMonthType = [
                                                        ...rdt.frequency
                                                          .week_type,
                                                      ];
                                                      const updatedTimeArray = [
                                                        ...updatedMonthType[
                                                          monthTypeIndexToRemove
                                                        ].time,
                                                      ];
                                                      updatedTimeArray.splice(
                                                        timeIndexToRemove,
                                                        1
                                                      );

                                                      updatedMonthType[
                                                        monthTypeIndexToRemove
                                                      ] = {
                                                        ...updatedMonthType[
                                                          monthTypeIndexToRemove
                                                        ],
                                                        time: updatedTimeArray,
                                                      };
                                                      return {
                                                        ...rdt,
                                                        frequency: {
                                                          ...rdt?.frequency,
                                                          week_type:
                                                            updatedMonthType,
                                                        },
                                                      };
                                                    }
                                                    return rdt;
                                                  }
                                                );
                                              });
                                            }}
                                            className="btn btn-light-danger btn-sm btn-icon me-3"
                                          >
                                            <i className="fa fa-minus" />
                                          </a>
                                        )}
                                        <a
                                          onClick={() => {
                                            if (
                                              !isUpdateStatus &&
                                              !isCloneStatus &&
                                              Object?.keys(selectedRow)
                                                ?.length !== 0
                                            )
                                              return;
                                            const currentTime = dayjs(
                                              "10:25",
                                              "HH:mm"
                                            ).format("HH:mm");

                                            setBasicInfo((prevData) => {
                                              return prevData?.map(
                                                (adt, adi) => {
                                                  if (adi === i) {
                                                    const updatedMonthType = [
                                                      ...adt.frequency
                                                        .week_type,
                                                    ];
                                                    updatedMonthType[
                                                      indexM
                                                    ].time.push(currentTime);
                                                    return {
                                                      ...adt,
                                                      frequency: {
                                                        ...adt.frequency,
                                                        week_type:
                                                          updatedMonthType,
                                                      },
                                                    };
                                                  }
                                                  return adt;
                                                }
                                              );
                                            });
                                          }}
                                          className="btn btn-light-primary btn-sm btn-icon me-3"
                                        >
                                          <i className="fa fa-plus" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                  </Panel>
                  <Panel
                    header="Time Periods"
                    className="collapsed fw-bold"
                    key="4"
                  >
                    <div className="mt-5">
                      <Radio.Group
                        disabled={
                          !isUpdateStatus &&
                          !isCloneStatus &&
                          Object?.keys(selectedRow)?.length !== 0
                        }
                        value={
                          !d?.time_periods?.start_date ? "lookback" : "date"
                        }
                        onChange={(e) => {
                          if (e.target.value === "date") {
                            setBasicInfo((prevBasicInfo) => {
                              const updatedBasicInfo = [...prevBasicInfo];
                              updatedBasicInfo[
                                i
                              ].time_periods.look_back_period = null;
                              updatedBasicInfo[
                                i
                              ].time_periods.exclude_day_order = null;

                              updatedBasicInfo[i].time_periods.exclude_days =
                                null;
                              return updatedBasicInfo;
                            });
                          } else {
                            setBasicInfo((prevBasicInfo) => {
                              const updatedBasicInfo = [...prevBasicInfo];
                              updatedBasicInfo[
                                i
                              ].time_periods.look_back_period = null;
                              updatedBasicInfo[
                                i
                              ].time_periods.exclude_day_order = null;
                              updatedBasicInfo[i].time_periods.type =
                                e.target.value;
                              updatedBasicInfo[i].time_periods.exclude_days =
                                null;
                              return updatedBasicInfo;
                            });
                            setBasicInfo((prevBasicInfo) => {
                              const updatedBasicInfo = [...prevBasicInfo];
                              updatedBasicInfo[i].time_periods.start_date =
                                null;
                              updatedBasicInfo[i].time_periods.end_date = null;
                              return updatedBasicInfo;
                            });
                          }
                        }}
                        name="Time_Periods"
                      >
                        <Radio value={"date"}>
                          <div className="position-relative mb-7">
                            <DatePicker.RangePicker
                              disabled={
                                !isUpdateStatus &&
                                !isCloneStatus &&
                                Object?.keys(selectedRow)?.length !== 0
                              }
                              value={
                                d?.time_periods?.start_date &&
                                d?.time_periods?.end_date
                                  ? [
                                      dayjs(
                                        d?.time_periods?.start_date,
                                        "YYYY-MM-DD"
                                      ),
                                      dayjs(
                                        d?.time_periods?.end_date,
                                        "YYYY-MM-DD"
                                      ),
                                    ]
                                  : []
                              }
                              onChange={(e) => {
                                setBasicInfo((prevBasicInfo) => {
                                  const updatedBasicInfo = [...prevBasicInfo];
                                  updatedBasicInfo[i].time_periods.start_date =
                                    e ? dayjs(e?.[0]).format("YYYY-MM-DD") : "";
                                  updatedBasicInfo[i].time_periods.end_date = e
                                    ? dayjs(e?.[1]).format("YYYY-MM-DD")
                                    : "";
                                  updatedBasicInfo[
                                    i
                                  ].time_periods.exclude_day_order = null;
                                  updatedBasicInfo[
                                    i
                                  ].time_periods.look_back_period = null;
                                  updatedBasicInfo[
                                    i
                                  ].time_periods.exclude_day_order = null;
                                  return updatedBasicInfo;
                                });
                              }}
                              suffixIcon={
                                <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0">
                                  <span className="path1" />
                                  <span className="path2" />
                                  <span className="path3" />
                                  <span className="path4" />
                                  <span className="path5" />
                                  <span className="path6" />
                                </i>
                              }
                              className="w-md-250px text-gray-600 fw-bold"
                              size="large"
                            />
                          </div>
                        </Radio>
                        <Radio value={"lookback"}>
                          <div className="row-list kt_docs_repeater_basic3">
                            <div className="form-group">
                              <div data-repeater-list="kt_docs_repeater_basic">
                                <div
                                  className="add-row"
                                  data-repeater-item="true"
                                />
                              </div>
                            </div>
                            <div className="row g-9 mb-5 align-items-center">
                              <div className="col-md-6 px-5">
                                <div className="d-flex flex-wrap align-items-center">
                                  <label className="fw-bolder mb-0 fs-6 me-3">
                                    Lookback period
                                  </label>
                                  <div>
                                    <Select
                                      disabled={
                                        !isUpdateStatus &&
                                        !isCloneStatus &&
                                        Object?.keys(selectedRow)?.length !== 0
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                      onChange={(e) => {
                                        setBasicInfo((prevBasicInfo) => {
                                          const updatedBasicInfo = [
                                            ...prevBasicInfo,
                                          ];
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.look_back_period = e;
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.start_date = null;
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.end_date = null;

                                          return updatedBasicInfo;
                                        });
                                      }}
                                      value={
                                        d?.time_periods?.look_back_period ||
                                        null
                                      }
                                      style={{ width: "200px" }}
                                      placeholder="Select"
                                      options={Array(60)
                                        .fill(null)
                                        .map((d, i) => {
                                          return { label: i + 1, value: i + 1 };
                                        })}
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 px-5">
                                <div className="d-flex flex-wrap align-items-center">
                                  <label className="fw-bolder mb-0 fs-6 me-3">
                                    Exclude
                                  </label>
                                  <div>
                                    <Select
                                      disabled={
                                        !isUpdateStatus &&
                                        !isCloneStatus &&
                                        Object?.keys(selectedRow)?.length !== 0
                                      }
                                      style={{
                                        width: "80px",
                                        marginRight: "10px",
                                      }}
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                      placeholder="Select"
                                      options={[
                                        {
                                          label: "First",
                                          value: "first",
                                        },
                                        {
                                          label: "Last",
                                          value: "last",
                                        },
                                      ]}
                                      value={d?.time_periods?.exclude_day_order}
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                      onChange={(e) => {
                                        setBasicInfo((prevBasicInfo) => {
                                          const updatedBasicInfo = [
                                            ...prevBasicInfo,
                                          ];
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.exclude_day_order = e;
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.start_date = null;
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.end_date = null;

                                          return updatedBasicInfo;
                                        });
                                      }}
                                    />
                                    <Select
                                      disabled={
                                        !isUpdateStatus &&
                                        !isCloneStatus &&
                                        Object?.keys(selectedRow)?.length !== 0
                                      }
                                      style={{ width: "100px" }}
                                      placeholder="Select"
                                      getPopupContainer={(triggerNode) =>
                                        triggerNode.parentNode
                                      }
                                      onClick={(e) => {
                                        e.preventDefault();
                                      }}
                                      onChange={(e) => {
                                        setBasicInfo((prevBasicInfo) => {
                                          const updatedBasicInfo = [
                                            ...prevBasicInfo,
                                          ];
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.exclude_days = e;
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.start_date = null;
                                          updatedBasicInfo[
                                            i
                                          ].time_periods.end_date = null;

                                          return updatedBasicInfo;
                                        });
                                      }}
                                      value={
                                        d?.time_periods?.exclude_days || null
                                      }
                                      options={Array(60)
                                        .fill(null)
                                        .map((d, i) => {
                                          return { label: i + 1, value: i + 1 };
                                        })}
                                    />
                                    <b className="ms-2">Days</b>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Radio>
                      </Radio.Group>
                    </div>
                  </Panel>
                  <Panel
                    header="Exclude Campaigns / Ad Group"
                    className="collapsed fw-bold"
                    key="5"
                  >
                    <div className="mt-5">
                      <div id="kt_docs_repeater_basic1" className="row-list">
                        <div className="form-group">
                          <div data-repeater-list="kt_docs_repeater_basic1">
                            <div className="add-row" data-repeater-item="true">
                              <div
                                style={{
                                  maxHeight: "220px",
                                  overflowY: "auto",
                                  overflowX: "hidden",
                                }}
                              >
                                {d?.exclude_campaigns?.map((de, indexE) => (
                                  <div
                                    key={indexE}
                                    className="row g-9 mb-15 align-items-center row-repeater"
                                  >
                                    <div className="col-md-1 text-center">
                                      <label className=" fs-5 fw-bold mb-0 d-flex">
                                        {indexE !== 0 && <div>And</div>}&nbsp;
                                        <div>If</div>
                                      </label>
                                      {/* <label className=" fs-5 fw-bold mb-0">
                                        If
                                      </label> */}
                                    </div>
                                    <div className="col-md-4">
                                      <Select
                                        disabled={
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        }
                                        style={{ width: "100%" }}
                                        value={de?.exclude_type}
                                        getPopupContainer={(triggerNode) =>
                                          triggerNode.parentNode
                                        }
                                        onChange={(e) => {
                                          const updatedBasicInfo = [
                                            ...basicInfo,
                                          ];

                                          // Update the 'attribute' field in the first criteria object
                                          updatedBasicInfo[i].exclude_campaigns[
                                            indexE
                                          ].exclude_type = e;

                                          // Update the state with the modified array
                                          setBasicInfo(updatedBasicInfo);
                                        }}
                                        options={[
                                          {
                                            label: "Campaign Name",
                                            value: "campaign_name",
                                          },
                                          {
                                            label: "Ad Group Name",
                                            value: "ag_group_name",
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="col-md-3">
                                      <Select
                                        disabled={
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        }
                                        value={de?.operator}
                                        getPopupContainer={(triggerNode) =>
                                          triggerNode.parentNode
                                        }
                                        onChange={(e) => {
                                          const updatedBasicInfo = [
                                            ...basicInfo,
                                          ];

                                          // Update the 'attribute' field in the first criteria object
                                          updatedBasicInfo[i].exclude_campaigns[
                                            indexE
                                          ].operator = e;

                                          // Update the state with the modified array
                                          setBasicInfo(updatedBasicInfo);
                                        }}
                                        style={{ width: "100%" }}
                                        options={[
                                          {
                                            label: "Contains",
                                            value: "INCLUDES",
                                          },
                                          {
                                            label: `Doesn't contain`,
                                            value: "NOT_INCLUDES",
                                          },
                                        ]}
                                      />
                                    </div>
                                    <div className="col-md-2">
                                      <Input
                                        disabled={
                                          !isUpdateStatus &&
                                          !isCloneStatus &&
                                          Object?.keys(selectedRow)?.length !==
                                            0
                                        }
                                        value={de?.value}
                                        onChange={(e) => {
                                          const inputValue = e.target.value;

                                          updateExcludeCampaignsValue(
                                            i,
                                            indexE,
                                            inputValue
                                          );
                                        }}
                                        type="text"
                                        className="fs-7"
                                        placeholder="Enter value"
                                        size="large"
                                      />
                                    </div>
                                    <div className="col-md-2 px-0">
                                      <div className="d-flex justify-content-evenly">
                                        {d?.exclude_campaigns?.length > 1 && (
                                          <button
                                            type="text"
                                            onClick={() => {
                                              if (
                                                !isUpdateStatus &&
                                                !isCloneStatus &&
                                                Object?.keys(selectedRow)
                                                  ?.length !== 0
                                              )
                                                return;
                                              removeExclude(i, indexE);
                                            }}
                                            className="btn btn-light-danger data-delete btn-sm btn-icon"
                                          >
                                            <MinusOutlined />
                                          </button>
                                        )}
                                        <a
                                          href="javascript:;"
                                          onClick={() => {
                                            if (
                                              !isUpdateStatus &&
                                              !isCloneStatus &&
                                              Object?.keys(selectedRow)
                                                ?.length !== 0
                                            )
                                              return;
                                            addExclude(i);
                                          }}
                                          data-repeater-create="true"
                                          className="btn btn-light-primary data-add btn-sm btn-icon me-3"
                                        >
                                          <i className="fa fa-plus" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Panel>
                </Collapse>
              </div>
            ))}
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default BidManagement;
