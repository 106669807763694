import { Skeleton } from "antd";
import { DotChartOutlined } from "@ant-design/icons";
import React from "react";

const CardLoading = () => {
  return (
    <>
      <div className="card-header border-bottom-dashed pt-5 pb-4">
        <div className="card-title d-flex flex-column">
          <div className="d-flex align-items-center">
            <Skeleton.Button
              active
              size="small"
              shape="round"
              className="me-2"
            />
            <Skeleton.Avatar active size="small" shape="circle" />
          </div>
          <span className="text-gray-500 pt-1 fw-semibold fs-6"></span>
        </div>
        <div className="card-toolbar"></div>
      </div>
      <div className="card-body pt-0 pb-0 row">
        <>
          <div className="col-md-6 col-lg-6 pt-2">
            <div
              id="kt_card_widget_17_chart_as"
              className="d-flex justify-content-center"
              style={{
                minWidth: "100px",
                minHeight: "100px",
              }}
            >
              <Skeleton.Node active>
                <DotChartOutlined
                  style={{
                    fontSize: 40,
                    color: "#bfbfbf",
                  }}
                />
              </Skeleton.Node>
            </div>
          </div>
          <div className="col-md-6 col-lg-6 d-flex flex-column ">
            {Object.entries([1, 2, 3, 4]).map((d, i) => {
              return (
                <div
                  key={i}
                  className="d-flex fw-semibold align-items-center mb-3"
                >
                  <div className="text-gray-500 flex-grow-1 me-3">
                    <Skeleton.Input active size="small" shape="round" />
                  </div>
                  <div className="fw-bolder text-gray-700 text-xxl-end">
                    <Skeleton.Button active size="small" shape="round" />
                  </div>
                </div>
              );
            })}
          </div>
        </>
      </div>
    </>
  );
};

export default CardLoading;
