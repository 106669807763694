import React, { useState } from "react";
import { DatePicker } from "antd";
import Chart from "react-apexcharts";
import dayjs from "dayjs";

function KeywordList() {
  const [keywordTab, setKeywordTab] = useState("organic_ranking");

  const { RangePicker } = DatePicker;

  const rangePresets = [
    {
      label: "Today",
      value: [dayjs().add(0, "d"), dayjs()],
    },
    {
      label: "Yesterday",
      value: [dayjs().add(-1, "d"), dayjs()],
    },
    {
      label: "Last 7 Days",
      value: [dayjs().add(-7, "d"), dayjs()],
    },
    {
      label: "Last 30 Days",
      value: [dayjs().add(-30, "d"), dayjs()],
    },
    {
      label: "This Month",
      value: [dayjs().startOf("month"), dayjs().endOf("month")],
    },
    {
      label: "Last Month",
      value: [
        dayjs().subtract(1, "month").startOf("month"),
        dayjs().subtract(1, "month").endOf("month"),
      ],
    },
  ];

  const onRangeChange = (dates, dateStrings) => {};

  return (
    <>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div>
            <div className="row gx-5 gx-xl-5">
              <div className="col-sm-6 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  <div
                    className="card-header border-bottom-dashed"
                    style={{ minHeight: "50px", background: "#e1f6ff" }}
                  >
                    <h3 className="card-title fw-bold">Performance</h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body pt-2 pb-4 ">
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5">
                          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                            Spend
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                            559.5K{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder">
                              + 8.8%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-dark" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">$592.6K</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-2" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5">
                          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                            Sales
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                            4.5M{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 5.8%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">$4.9M</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-2" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5">
                          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                            ROAS
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                            12.40%{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                              + 0.1%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-warning" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">12.42%</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 mb-5 mb-xl-5">
                <div className="card card-flush h-lg-100">
                  <div
                    className="card-header border-bottom-dashed"
                    style={{ minHeight: "50px", background: "#e1f6ff" }}
                  >
                    <h3 className="card-title fw-bold">Conversion</h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body pt-2 pb-4 ">
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5">
                          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                            Impressions
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                            109.3M{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-dark">
                              + 2.5%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-dark" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">112.2M</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-2" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5">
                          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                            Clicks
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                            783.6K{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-success">
                              + 4.3%{" "}
                              <i className="ki-outline ki-arrow-up fs-3 text-success" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">816.8K</h4>
                      </div>
                    </div>
                    <div className="separator separator-dashed my-2" />
                    <div className="d-flex flex-stack">
                      <div className="d-flex align-items-center me-5">
                        <div className="me-5">
                          <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                            CTR
                          </a>
                          <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                            0.42%{" "}
                            <span className="d-flex align-items-center w-100 ms-2 fs-8 fw-bolder text-warning">
                              + 0.9%{" "}
                              <i className="ki-outline ki-arrow-down fs-3 text-warning" />
                            </span>
                          </span>
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <h4 className="fw-bold mb-0">0.42%</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-5">
              <div className="col-md-6 col-lg-6 col-xl-4 ">
                <div className="card card-flush  mb-5 mb-xl-5">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">Spend</h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body pt-0 pb-0 row">
                    <div className="col-md-6 col-lg-6 pt-2">
                      <div
                        id="kt_card_widget_17_chart_as"
                        style={{ minWidth: "100px", minHeight: "100px" }}
                        data-kt-size={100}
                        data-kt-line={11}
                      >
                        <Chart
                          options={{
                            chart: {
                              // width: '150px',
                              type: "donut",
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                              // width: '200px'
                            },
                            labels: ["Exact", "Phrase", "Broad", "Auto", "PAT"],
                            colors: [
                              "#f1416c",
                              "#22a9e6",
                              "#50cd89",
                              "#333333",
                              "#dddddd",
                            ],
                          }}
                          series={[7660, 2820, 5257, 5257, 1257]}
                          type="donut"
                          height={150}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 d-flex flex-column ">
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 bg-danger me-3" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Exact
                        </div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $7,660
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 bg-primary me-3" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Phrase
                        </div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $2,820
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 me-3 bg-success" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Broad
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          $5,257
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 me-3 bg-dark" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Auto
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          $5,257
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center">
                        <div
                          className="bullet w-8px h-3px rounded-2 me-3"
                          style={{ backgroundColor: "#E4E6EF" }}
                        />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          PAT
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          $1,257
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4 ">
                <div className="card card-flush  mb-5 mb-xl-5">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">Sales</h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body pt-0 pb-0 row">
                    <div className="col-md-6 col-lg-6 pt-2">
                      <div
                        id="kt_card_widget_17_chart_as"
                        style={{ minWidth: "100px", minHeight: "100px" }}
                        data-kt-size={100}
                        data-kt-line={11}
                      >
                        <Chart
                          options={{
                            chart: {
                              // width: '150px',
                              type: "donut",
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            legend: {
                              show: false,
                              // width: '200px'
                            },
                            labels: ["Exact", "Phrase", "Broad", "Auto", "PAT"],
                            colors: [
                              "#f1416c",
                              "#22a9e6",
                              "#50cd89",
                              "#333333",
                              "#dddddd",
                            ],
                          }}
                          series={[7660, 2820, 5257, 5257, 1257]}
                          type="donut"
                          height={150}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 d-flex flex-column ">
                      <div className="d-flex fw-semibold align-items-center">
                        <div className="bullet w-8px h-3px rounded-2 bg-danger me-3" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Exact
                        </div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $7,660
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center my-3">
                        <div className="bullet w-8px h-3px rounded-2 bg-primary me-3" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Phrase
                        </div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          $2,820
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 me-3 bg-success" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Broad
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          $5,257
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 me-3 bg-dark" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Auto
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          $5,257
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center">
                        <div
                          className="bullet w-8px h-3px rounded-2 me-3"
                          style={{ backgroundColor: "#E4E6EF" }}
                        />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          PAT
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          $1,257
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-lg-6 col-xl-4 ">
                <div className="card card-flush  mb-5 mb-xl-5">
                  <div className="card-header border-bottom-dashed">
                    <h3 className="card-title fw-bold">ROAS</h3>
                    <div className="card-toolbar"></div>
                  </div>
                  <div className="card-body pt-0 pb-0 row">
                    <div className="col-md-6 col-lg-6 pt-2">
                      <div
                        id="kt_card_widget_17_chart_as"
                        style={{
                          minHeight: "150px",
                          minWidth: "150px",
                          marginTop: "-20px",
                          marginLeft: "-10px",
                        }}
                        data-kt-size={100}
                        data-kt-line={11}
                      >
                        <Chart
                          options={{
                            chart: {
                              // width: 380,
                              type: "bar",
                              toolbar: {
                                show: false,
                              },
                            },
                            plotOptions: {
                              bar: {
                                distributed: true,
                                horizontal: true,
                                barHeight: "75%",
                                dataLabels: {
                                  position: "bottom",
                                },
                              },
                            },
                            yaxis: {
                              labels: {
                                show: false,
                              },
                            },
                            dataLabels: {
                              enabled: false,
                            },
                            grid: {
                              show: false,
                            },
                            legend: {
                              show: false,
                            },
                            labels: ["Exact", "Phrase", "Broad", "Auto", "PAT"],
                            colors: [
                              "#f1416c",
                              "#22a9e6",
                              "#50cd89",
                              "#333333",
                              "#dddddd",
                            ],
                          }}
                          series={[
                            {
                              data: [76, 88, 56, 25, 45],
                            },
                          ]}
                          type="bar"
                          height={150}
                        />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 d-flex flex-column ">
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 bg-danger me-3" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Exact
                        </div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          76%
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 bg-primary me-3" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Phrase
                        </div>
                        <div className="fw-bolder text-gray-700 text-xxl-end">
                          88%
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 me-3 bg-success" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Broad
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          56%
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center mb-3">
                        <div className="bullet w-8px h-3px rounded-2 me-3 bg-dark" />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          Auto
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          25%
                        </div>
                      </div>
                      <div className="d-flex fw-semibold align-items-center">
                        <div
                          className="bullet w-8px h-3px rounded-2 me-3"
                          style={{ backgroundColor: "#E4E6EF" }}
                        />
                        <div className="text-gray-500 flex-grow-1 me-4">
                          PAT
                        </div>
                        <div className=" fw-bolder text-gray-700 text-xxl-end">
                          45%
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row gy-5 g-xl-5 ">
              <div className="col-xl-12">
                <div className="card card-xxl-stretch mb-5 mb-xl-8">
                  <div className="card-header border-bottom-dashed">
                    <ul className="nav nav-stretch nav-pills nav-pills-custom d-flex mt-3">
                      <li className="nav-item p-0 ms-0 me-8">
                        <a
                          onClick={() => setKeywordTab("organic_ranking")}
                          className={`nav-link btn btn-gray-600 px-0 ${
                            keywordTab === "organic_ranking" ? "active" : ""
                          }`}
                        >
                          <span className="nav-text fw-semibold fs-4">
                            Improve Organic Ranking
                          </span>
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        </a>
                      </li>
                      <li className="nav-item p-0 ms-0 me-8">
                        <a
                          onClick={() => setKeywordTab("generic_keywords")}
                          className={`nav-link btn btn-gray-600 px-0 ${
                            keywordTab === "generic_keywords" ? "active" : ""
                          }`}
                        >
                          <span className="nav-text fw-semibold fs-4">
                            Generic Keywords
                          </span>
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        </a>
                      </li>
                      <li className="nav-item p-0 ms-0">
                        <a
                          onClick={() => setKeywordTab("brand_terms")}
                          className={`nav-link btn btn-gray-600 px-0 ${
                            keywordTab === "brand_terms" ? "active" : ""
                          }`}
                        >
                          <span className="nav-text fw-semibold fs-4">
                            Brand Terms
                          </span>
                          <span className="bullet-custom position-absolute z-index-2 w-100 h-2px top-100 bottom-n100 bg-primary rounded" />
                        </a>
                      </li>
                    </ul>
                    <div className="card-toolbar my-0">
                      <RangePicker
                        size="large"
                        presets={rangePresets}
                        format="DD/MM/YYYY"
                        onChange={onRangeChange}
                        style={{
                          width: "250px",
                        }}
                      />
                      {/* <div data-kt-daterangepicker="true" data-kt-daterangepicker-opens="left" data-kt-daterangepicker-range="today" className="btn btn-sm btn-light d-flex align-items-center px-4">
                                                <div className="text-gray-600 fw-bold">
                                                    Loading date range...
                                                </div>
                                                <i className="ki-duotone ki-calendar-8 fs-1 ms-2 me-0"><span className="path1" /><span className="path2" /><span className="path3" /><span className="path4" /><span className="path5" /><span className="path6" /></i>
                                            </div> */}
                      <button
                        type="button"
                        className="btn  fs-7 btn-light btn-active-light-primary ms-3 fw-bold"
                        data-kt-menu-trigger="click"
                        data-kt-menu-placement="bottom-end"
                      >
                        Import
                      </button>
                      <div
                        className="menu menu-sub menu-sub-dropdown w-250px w-md-300px"
                        data-kt-menu="true"
                        id="kt_menu_63be7b6fcb7e6"
                      >
                        <div className="px-7 py-5">
                          <div className="fs-5 text-dark fw-bold">
                            Filter Options
                          </div>
                        </div>
                        <div className="separator border-gray-200" />
                        <div className="px-7 py-5">
                          <div className="mb-5">
                            <a href className="fw-bold text-primary">
                              <i className="bi bi-download text-primary" />{" "}
                              Download an import schema
                            </a>
                          </div>
                          <div className="mb-10">
                            <input
                              className="form-control form-control-sm form-control-solid"
                              placeholder
                              type="file"
                              id="formFile"
                            />
                          </div>
                          <div className="d-flex justify-content-end">
                            <button
                              type="reset"
                              className="btn btn-sm btn-light btn-active-light-primary me-2"
                              data-kt-menu-dismiss="true"
                            >
                              Reset
                            </button>
                            <button
                              type="submit"
                              className="btn btn-sm btn-primary"
                              data-kt-menu-dismiss="true"
                            >
                              Apply
                            </button>
                          </div>
                        </div>
                      </div>
                      <button className="btn btn-light-primary fs-7 fw-bold ms-3">
                        Export
                      </button>
                    </div>
                  </div>
                  <div className="card-body py-2">
                    <div className="tab-content">
                      <div
                        className={`tab-pane fade ${
                          keywordTab === "organic_ranking" ? "show active" : ""
                        }`}
                      >
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="min-w-55px">Rank</th>
                                <th className="min-w-125px">Keyword</th>
                                <th className="min-w-125px">Profile Name</th>
                                <th className="min-w-75px">Impr.</th>
                                <th className="min-w-75px">Clk.</th>
                                <th className="min-w-75px">CTR</th>
                                <th className="min-w-75px">Spend</th>
                                <th className="min-w-75px">Sales</th>
                                <th className="min-w-75px">ROAS</th>
                                <th className="min-w-75px">Orders</th>
                                <th className="min-w-100px">Sale Units</th>
                                <th className="min-w-75px">CPC</th>
                                <th className="min-w-75px">CVR</th>
                                <th className="min-w-75px">ASP</th>
                                <th className="min-w-75px">CPA</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                          <div className="fs-6 fw-bold text-gray-700 d-flex align-items-center">
                            <select
                              name
                              aria-controls
                              className="form-select form-select-sm form-select-solid w-75px me-3"
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            Showing 1 to 3 of 50 entries
                          </div>
                          <ul className="pagination">
                            <li className="page-item previous">
                              <a className="page-link">
                                <i className="previous" />
                              </a>
                            </li>
                            <li className="page-item active">
                              <a className="page-link">1</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">2</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">3</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">4</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">5</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">6</a>
                            </li>
                            <li className="page-item next">
                              <a className="page-link">
                                <i className="next" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          keywordTab === "generic_keywords" ? "show active" : ""
                        }`}
                      >
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="min-w-55px">Rank</th>
                                <th className="min-w-125px">Keyword</th>
                                <th className="min-w-125px">Profile Name</th>
                                <th className="min-w-75px">Impr.</th>
                                <th className="min-w-75px">Clk.</th>
                                <th className="min-w-75px">CTR</th>
                                <th className="min-w-75px">Spend</th>
                                <th className="min-w-75px">Sales</th>
                                <th className="min-w-75px">ROAS</th>
                                <th className="min-w-75px">Orders</th>
                                <th className="min-w-100px">Sale Units</th>
                                <th className="min-w-75px">CPC</th>
                                <th className="min-w-75px">CVR</th>
                                <th className="min-w-75px">ASP</th>
                                <th className="min-w-75px">CPA</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                          <div className="fs-6 fw-bold text-gray-700 d-flex align-items-center">
                            <select
                              name
                              aria-controls
                              className="form-select form-select-sm form-select-solid w-75px me-3"
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            Showing 1 to 3 of 50 entries
                          </div>
                          <ul className="pagination">
                            <li className="page-item previous">
                              <a className="page-link">
                                <i className="previous" />
                              </a>
                            </li>
                            <li className="page-item active">
                              <a className="page-link">1</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">2</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">3</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">4</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">5</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">6</a>
                            </li>
                            <li className="page-item next">
                              <a className="page-link">
                                <i className="next" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div
                        className={`tab-pane fade ${
                          keywordTab === "brand_terms" ? "show active" : ""
                        }`}
                      >
                        <div className="table-responsive">
                          <table className="table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4">
                            <thead>
                              <tr className="fw-bolder text-dark">
                                <th className="min-w-55px">Rank</th>
                                <th className="min-w-125px">Keyword</th>
                                <th className="min-w-125px">Profile Name</th>
                                <th className="min-w-75px">Impr.</th>
                                <th className="min-w-75px">Clk.</th>
                                <th className="min-w-75px">CTR</th>
                                <th className="min-w-75px">Spend</th>
                                <th className="min-w-75px">Sales</th>
                                <th className="min-w-75px">ROAS</th>
                                <th className="min-w-75px">Orders</th>
                                <th className="min-w-100px">Sale Units</th>
                                <th className="min-w-75px">CPC</th>
                                <th className="min-w-75px">CVR</th>
                                <th className="min-w-75px">ASP</th>
                                <th className="min-w-75px">CPA</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                              <tr>
                                <td>1</td>
                                <td>
                                  <a href className="fw-bold">
                                    Beauty Blender
                                  </a>
                                </td>
                                <td>Beauty Blender</td>
                                <td>
                                  38.6K
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-success">
                                      5554.3%{" "}
                                      <i className="ki-outline ki-arrow-up fs-3 text-success" />
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  149
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  0.38%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $178.3
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $344
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.84
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  10
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  12
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $1.28
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  6.75%
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $28.34
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                                <td>
                                  $19.4
                                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                                    <span className="d-flex align-items-center w-100 fs-8 fw-bolder text-dark">
                                      --
                                    </span>
                                  </span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="d-flex flex-stack flex-wrap pt-0 mt-5">
                          <div className="fs-6 fw-bold text-gray-700 d-flex align-items-center">
                            <select
                              name
                              aria-controls
                              className="form-select form-select-sm form-select-solid w-75px me-3"
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                            Showing 1 to 3 of 50 entries
                          </div>
                          <ul className="pagination">
                            <li className="page-item previous">
                              <a className="page-link">
                                <i className="previous" />
                              </a>
                            </li>
                            <li className="page-item active">
                              <a className="page-link">1</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">2</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">3</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">4</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">5</a>
                            </li>
                            <li className="page-item">
                              <a className="page-link">6</a>
                            </li>
                            <li className="page-item next">
                              <a className="page-link">
                                <i className="next" />
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default KeywordList;
