import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import RuleManagement from "../../modules/pages/rule-management";
import {
  GetRuleManagementListAction,
  CreateRuleAction,
  GetRuleManagementDropdownListAction,
  UpdateRuleAction,
  UpdateMasterRuleAction,
  DeleteRuleAction,
  UpdateRuleStatusAction,
  getRecommendedEventsAction,
  UpdateSubRuleStatusAction,
  fakeActionRuleManagement,
  GetAppliedRuleAction,
} from "../../redux/modules/rule-management/index.action";
import {
  CampaignsAnalyticsAllTableDataAction,
  fakeActionCampaignsAnalytics,
} from "../../redux/modules/campaigns-analytics/index.action";
import {
  GetMarketplaceListAction,
  fakeActionMarketplace,
} from "../../redux/modules/marketplace-credentials/index.action";
import {
  GetAdGroupAllListAction,
  fakeActionCampaignsManagementAnalytics,
} from "../../redux/modules/campaign-management/index.action";

const mapStateToProps = (state) => ({
  GetRuleManagementListResponse:
    state.RuleManagement.GetRuleManagementListResponse,
  GetRuleManagementDropdownResponse:
    state.RuleManagement.GetRuleManagementDropdownResponse,
  CreateNewRuleResponse: state.RuleManagement.CreateNewRuleResponse,

  RuleManagementGetAppliedRuleLogResponse:
    state.RuleManagement.RuleManagementGetAppliedRuleLogResponse,

  CreateNewRuleResponse: state.RuleManagement.CreateNewRuleResponse,
  UpdateRuleResponse: state.RuleManagement.UpdateRuleResponse,
  DeleteRuleResponse: state.RuleManagement.DeleteRuleResponse,
  UpdateMasterRuleResponse: state.RuleManagement.UpdateMasterRuleResponse,

  UpdateRuleStatusResponse: state.RuleManagement.UpdateRuleStatusResponse,
  UpdateSubRuleStatusResponse: state.RuleManagement.UpdateSubRuleStatusResponse,

  AllCompaignsDataResponse: state?.CampaignsAnalytics.AllCompaignsDataResponse,

  GetAdGroupAllListResponse:
    state?.CampaignManagement.GetAdGroupAllListResponse,

  GetRecommendedEventListResponse:
    state.RuleManagement.GetRecommendedEventListResponse,

  GetMarketplaceListResponse:
    state?.MarketplaceCredential.GetMarketplaceListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetRuleManagementListAction,
      fakeActionRuleManagement,
      UpdateRuleAction,

      UpdateRuleStatusAction,
      GetAppliedRuleAction,
      CampaignsAnalyticsAllTableDataAction,
      DeleteRuleAction,
      CreateRuleAction,
      UpdateMasterRuleAction,
      GetRuleManagementDropdownListAction,
      getRecommendedEventsAction,
      fakeActionCampaignsAnalytics,
      UpdateSubRuleStatusAction,

      GetMarketplaceListAction,
      fakeActionMarketplace,

      GetAdGroupAllListAction,
      fakeActionCampaignsManagementAnalytics,
    },
    dispatch
  );

const RuleManagement_ = connect(
  mapStateToProps,
  mapDispatchToProps
)(RuleManagement);

export default RuleManagement_;
