import { Input, Select } from "antd";
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector } from "react-redux";
import { APPID } from "../../../../../config";

export default function (props) {
  const { show, close, GetMarketplaceListAction, fakeActionMarketplace } =
    props;

  const [marketPlaceList, setMarketPlaceList] = useState([]);
  const [marketPlaceLoading, setMarketPlaceLoading] = useState(true);

  const [selectedObj, setSelectedObj] = useState({
    seller_name: JSON.parse(localStorage.getItem("user"))?.seller_name,
    marketplace_id: "",
  });

  const GetMarketplaceListResponse = useSelector(
    (state) => state.MarketplaceCredential.GetMarketplaceListResponse || {}
  );

  useEffect(() => {
    if (GetMarketplaceListResponse?.status === true) {
      let make_ = {};
      GetMarketplaceListResponse?.data.forEach((e) => {
        if (!make_[e.region]) {
          make_ = { ...make_, [e.region]: [] };
        }
        make_[e.region]?.push(e);
      });
      setMarketPlaceList(make_);

      setMarketPlaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    } else if (GetMarketplaceListResponse?.status === false) {
      setMarketPlaceLoading(false);
      fakeActionMarketplace("GetMarketplaceListResponse");
    }
  }, [GetMarketplaceListResponse]);

  useEffect(() => {
    window.onAmazonLoginReady = function () {
      window.amazon.Login.setClientId(APPID);
    };
    let a = document.createElement("script");
    a.type = "text/javascript";
    a.async = true;
    a.id = "amazon-login-sdk";
    a.src = "https://assets.loginwithamazon.com/sdk/na/login1.js";
    const getRoot = document.getElementById("amazon-root");
    getRoot.appendChild(a);

    GetMarketplaceListAction();
    return () => {};
  }, []);

  return (
    <Modal
      centered
      show={show}
      onHide={close}
      maskClosable={false}
      size="lg"
      style={{ zIndex: 9999 }}
    >
      <Modal.Header className="px-5" closeButton>
        <Modal.Title>Add New Advertising Credentials</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="pe-7"
          id="kt_modal_add_customer_scroll"
          data-kt-scroll="true"
          data-kt-scroll-activate="{default: false, lg: true}"
          data-kt-scroll-max-height="auto"
          data-kt-scroll-dependencies="#kt_modal_add_customer_header"
          data-kt-scroll-wrappers="#kt_modal_add_customer_scroll"
          data-kt-scroll-offset="300px"
          style={{}}
        >
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">Seller Account Name</label>
            </div>
            <div className="col-md-8 fv-row">
              <Input
                placeholder="Enter Seller Account Name"
                style={{ height: "38px" }}
                className="ant_common_input"
                size="large"
                id="seller_account_name"
                value={selectedObj?.seller_name || ""}
                onChange={(e) => {
                  setSelectedObj({
                    ...selectedObj,
                    seller_name: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mb-7 align-items-center">
            <div className="col-md-4 fv-row">
              <label className=" fs-6 fw-bold mb-2">Marketplace</label>
            </div>
            <div className="col-md-8 fv-row">
              <Select
                size="large"
                style={{ width: "100%" }}
                placeholder="Select Marketplace"
                loading={marketPlaceLoading}
                value={selectedObj?.marketplace_id || null}
                onChange={(e, _) => {
                  setSelectedObj({
                    ...selectedObj,
                    marketplace_id: e,
                  });
                }}
                options={
                  marketPlaceList &&
                  Object.keys(marketPlaceList)?.length !== 0 &&
                  Object.keys(marketPlaceList)?.map((d, i) => {
                    return {
                      label: d,
                      options: marketPlaceList[d]?.map((r) => {
                        return { label: r?.name, value: r?.id, ...d, ...r };
                      }),
                    };
                  })
                }
                getPopupContainer={(triggerNode) => triggerNode.parentNode}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          type="reset"
          id="kt_modal_add_customer_cancel"
          className="btn btn-light me-3"
          onClick={() => {
            close();
          }}
        >
          Discard
        </button>

        <a
          id="LoginWithAmazon"
          style={{
            marginLeft: "10px",
            opacity:
              Object.values(selectedObj)?.filter((d) => !d)?.length === 0
                ? 1
                : 0.5,
            pointerEvents:
              Object.values(selectedObj)?.filter((d) => !d)?.length === 0
                ? "all"
                : "none",
          }}
          onClick={() => {
            if (Object.values(selectedObj)?.filter((d) => !d)?.length !== 0) {
              return;
            }
            let options = {};
            options.scope = "advertising::campaign_management";
            options.response_type = "code";
            window.amazon.Login.authorize(
              options,

              `https://app.vfunnel.ai/callbackads?marketplace_id=${
                selectedObj?.marketplace_id || ""
              }&seller_name=${selectedObj?.seller_name || ""}`
            );

            close();
          }}
        >
          <img
            border="0"
            alt="Login with Amazon"
            src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_gold_156x32.png"
            width="156"
            height="32"
          />
        </a>
      </Modal.Footer>
    </Modal>
  );
}
