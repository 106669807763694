import React, { useState } from "react";
import { CampaignCreationWrapper } from "../../style";
import { CaretRightOutlined } from "@ant-design/icons";
import { Collapse, Dropdown, Segmented, Select, Tag, theme } from "antd";
import AddGroupSpecifications from "./lib/Ad Group Specifications";
import CampaignSpecifications from "./lib/Campaign Specifications";
import CampaignPreview from "./lib/campaign-preview";
import dayjs from "dayjs";

const CampaignCreation = (props) => {
  const {
    strategyList,
    strategyLoading,
    marketplaceLoading,
    marketplaceList,
    selectedMarketplaceCreate,
    setSelectedMarketplaceCreate,
    configsData,
    configsLoading,
  } = props;
  const [loading, setLoading] = useState(false);
  const [selectedStategy, setSelectedStategy] = useState(null);

  const [basicDetails, setBasicDetails] = useState({
    marketplace_id: selectedMarketplaceCreate, // fixed
    manualType: "product",
    campaign_data: {
      name: null,
      suffix: dayjs(new Date()).format("YYYY-MM-DD hh:mm:ss"),
      campaign_ad_type: "SP",
      portfolio_id: null,
      start_date: null,
      end_date: null,
      targeting_type: "AUTO",
      daily_budget: null,
      top_of_search_placement: null,
      product_pages_placement: null,
      bidding_strategy: "AUTO_FOR_SALES",
      state: "ENABLED",
    },
    ad_groups_data: [
      {
        name: null,
        date: dayjs(new Date()).format("YYYY-MM-DD"),
        default_bid: null,
        state: "ENABLED",
        product_ads: [],
        product_targeting: [],
        keyword_targeting: [],
        product_targeting_manual: [],
      },
    ],
  });
  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 15,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px 1px 4px",
  };
  console.log(basicDetails, "basicDetails");
  const items_ = [
    {
      key: "LEGACY_FOR_SALES",
      label: <a>Dynamic Bids - Down Only</a>,
    },
    {
      key: "AUTO_FOR_SALES",
      label: <a>Dynamic Bids - Up &amp; Down Only</a>,
    },
    {
      key: "MANUAL",
      label: <a>Fixed Bid - Down Only</a>,
    },
  ];

  const Obj = {
    "Dynamic bids - down only": "LEGACY_FOR_SALES",
    "Dynamic bids - up and down": "AUTO_FOR_SALES",
    "Fixed bid": "MANUAL",
  };

  const items = [
    {
      key: "panel1",
      label: "Campaign Specifications",
      children: (
        <CampaignSpecifications
          basicDetails={basicDetails}
          setBasicDetails={setBasicDetails}
          loading={loading}
          {...props}
        />
      ),
      style: panelStyle,
      extra: (
        <div onClick={(e) => e.stopPropagation()}>
          <Select
            loading={configsLoading}
            value={basicDetails?.campaign_data?.bidding_strategy || null}
            options={Object.entries(
              configsData?.biddingStrategyTypes || {}
            )?.map(([key, value]) => ({
              label: value,
              value: key,
            }))}
            onChange={(e) => {
              setBasicDetails({
                ...basicDetails,
                campaign_data: {
                  ...basicDetails?.campaign_data,
                  bidding_strategy: e,
                },
              });
            }}
            className="w-225px mb-2 me-4"
            placeholder="Select Strategy"
          />
          <Select
            loading={strategyLoading}
            value={selectedStategy?.id || null}
            options={strategyList?.map((d) => ({
              label: d?.strategy_name,
              value: d?.id,
              ...d,
            }))}
            onChange={(e, r) => {
              setBasicDetails({
                ...basicDetails,
                campaign_data: {
                  ...basicDetails?.campaign_data,
                  product_pages_placement: r?.product_pages_placement,
                  top_of_search_placement: r?.top_of_search_placement,
                  daily_budget: r?.budget_amount,
                  bidding_strategy: Obj?.[r?.bidding_strategy_type],
                },
              });
              setSelectedStategy(r);
            }}
            className="w-175px mb-2"
            placeholder="Select Strategy"
          />
        </div>
      ),
    },
    {
      key: "panel2",
      label: "Ad Group Specifications",
      children: (
        <AddGroupSpecifications
          basicDetails={basicDetails}
          setBasicDetails={setBasicDetails}
          loading={loading}
          {...props}
        />
      ),
      style: panelStyle,
      extra: ToggleComponents(setBasicDetails, basicDetails),
    },
    // {
    //   key: "3",
    //   label: "Campaign Preview",
    //   children: (
    //     <CampaignPreview
    //       setBasicDetails={setBasicDetails}
    //       basicDetails={basicDetails}
    //       setLoading={setLoading}
    //       loading={loading}
    //       {...props}
    //     />
    //   ),
    //   style: panelStyle,
    // },
  ];

  return (
    <CampaignCreationWrapper>
      <div className="type-select">
        <div className="d-grid">
          {" "}
          <span className="title">Choose your campaign type</span>
          <Segmented
            size="large"
            onChange={(e) => {
              setBasicDetails({
                ...basicDetails,
                campaign_data: {
                  ...basicDetails?.campaign_data,
                  campaign_ad_type: e,
                },
              });
            }}
            value={basicDetails?.campaign_data?.campaign_ad_type}
            options={[
              { label: "Sponsored Products", value: "SP" },
              { label: "Sponsored Brand", value: "SB", disabled: true },
              // { label: "Sponsored Display", value: "SD" },
            ]}
            className="mb-1"
          />
          <small>Able to select any from above</small>
        </div>
        <div className="d-flex align-items-center">
          <Tag
            color={`${
              basicDetails.campaign_data.targeting_type === "MANUAL"
                ? "error"
                : "success"
            }`}
            bordered={false}
          >
            {basicDetails.campaign_data.targeting_type === "MANUAL"
              ? "Manual"
              : "Auto"}
          </Tag>
          <Select
            size="large"
            placeholder="Select Marketplace"
            loading={marketplaceLoading}
            value={selectedMarketplaceCreate || null}
            onChange={(e) => {
              setSelectedMarketplaceCreate(e);
              setBasicDetails({
                ...basicDetails,
                marketplace_id: e,
              });
            }}
            style={{
              width: "200px",
            }}
            options={marketplaceList?.map((d) => ({
              label: d?.name,
              value: d?.id,
            }))}
            className="fs-7 me-3"
          />
          {/* <b className="d-flex align-items-center country">
            <img
              style={{
                borderRadius: "100%",
                width: "20px",
                marginRight: "10px",
              }}
              src="/assets/media/flags/india.svg"
            />{" "}
            India
          </b> */}
        </div>
      </div>
      <div className="border border-dashed border-gray-500 mt-3 mb-3" />
      <Collapse
        bordered={false}
        expandIcon={({ isActive }) => (
          <CaretRightOutlined rotate={isActive ? 90 : 0} />
        )}
        style={{
          background: "transparent",
        }}
        items={items}
        defaultActiveKey={["panel1", "panel2"]}
        // accordion
      />
      <CampaignPreview
        setBasicDetails={setBasicDetails}
        basicDetails={basicDetails}
        setLoading={setLoading}
        loading={loading}
        {...props}
      />
    </CampaignCreationWrapper>
  );
};

export default CampaignCreation;

function ToggleComponents(setBasicDetails, basicDetails) {
  return (
    <div
      onClick={(e) => e.stopPropagation()}
      className="auto-manual d-flex align-items-center"
    >
      <h1>Manual</h1>
      <label
        onChange={() => {
          setBasicDetails({
            ...basicDetails,
            manualType:
              basicDetails.campaign_data.targeting_type === "MANUAL"
                ? "AUTO"
                : "product",
            campaign_data: {
              ...basicDetails.campaign_data,
              targeting_type:
                basicDetails.campaign_data.targeting_type === "MANUAL"
                  ? "AUTO"
                  : "MANUAL",
            },
          });
        }}
        className="switch-button mx-5"
        htmlFor="switch"
      >
        <div className="switch-outer">
          <input
            checked={
              basicDetails.campaign_data.targeting_type === "AUTO"
                ? true
                : false
            }
            id="switch"
            type="checkbox"
          />
          <div className="button">
            <span className="button-toggle" />
            <span className="button-indicator" />
          </div>
        </div>
      </label>
      <h1>Auto</h1>
    </div>
  );
}
