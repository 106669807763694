import axiosCall from "../../configurations/network-services/axiosCall";

export const loginAction = (payload) => {
  const path = `login`;
  const responseType = "LOGIN";
  const body = payload;
  return axiosCall("post", path, responseType, body);
};

export const signUpUserAction = (data) => {
  const path = `sign-up`;
  const responseType = "SIGN_UP";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const forgotPasswordAction = (data) => {
  const path = `forgot-password`;
  const responseType = "FORGOT_PASSWORD";
  return axiosCall("post", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const resetPasswordAction = (token, data) => {
  const path = `reset-password?token=${token}`;
  const responseType = "RESET_PASSWORD";
  return axiosCall("put", path, responseType, data, {
    Authorization: `Bearer ${localStorage.getItem("token")}`,
  });
};

export const fakeActionAuth = () => async (dispatch) => {
  dispatch({ type: "FAKE_ACTION_AUTH" });
};
