import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dashboard from "../../modules/pages/dashboard";
import {
  getOptionListAction,
  fakeActionDashboard,
  getDashboardPerformanceDataAction,
  getDashboardGraphAction,
  DashboardActiveCampaignsAction,
  DashboardTargetingCardDataAction,
  getDashboardCardsDataAction,
} from "../../redux/modules/dashboard/dashboard.action";

import {
  GetMarketplaceListAction,
  fakeActionMarketplace,
} from "../../redux/modules/marketplace-credentials/index.action";

const mapStateToProps = (state) => ({
  OptionListResponse: state.Dashboard.OptionListResponse,
  CardsDataResponse: state.Dashboard.CardsDataResponse,
  DashboardPerformanceMetricsResponse:
    state.Dashboard.DashboardPerformanceMetricsResponse,
  DashboardPerformanceGraphResponse:
    state.Dashboard.DashboardPerformanceGraphResponse,
  DashboardActiveCampaignsResponse:
    state.Dashboard.DashboardActiveCampaignsResponse,
  DashboardTargetingCardResponse:
    state?.Dashboard.DashboardTargetingCardResponse,

  GetMarketplaceListResponse:
    state?.MarketplaceCredential.GetMarketplaceListResponse,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getOptionListAction,
      fakeActionDashboard,
      getDashboardPerformanceDataAction,
      DashboardTargetingCardDataAction,
      getDashboardGraphAction,
      DashboardActiveCampaignsAction,
      getDashboardCardsDataAction,

      // marketplace
      GetMarketplaceListAction,
      fakeActionMarketplace,
    },
    dispatch
  );

const Dashboard_ = connect(mapStateToProps, mapDispatchToProps)(Dashboard);

export default Dashboard_;
