import styled from "styled-components";

export const Wrapper = styled.div`
  .ant-input-affix-wrapper,
  .ant-select-selector {
    height: 60px;
    background: transparent !important;
    border: 1px solid #e1e3ea !important;
    border-radius: 0.475rem;
  }
`;
export const ModalWrapper = styled.div`
  .ant-input-affix-wrapper,
  #add_user_name,
  #add_user_email {
    height: 60px;
    background: transparent !important;
    border: 1px solid #e1e3ea !important;
    border-radius: 0.475rem;
  }
`;
