import styled from "styled-components";

const Wrapper = styled.div`
  .actionIcon {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    cursor: pointer;
    svg {
      font-size: 24px;
      color: #f8a3a8;
      cursor: pointer;
      &:hover {
        color: #f082b0;
      }
    }
  }
`;

export default Wrapper;
