import React, { useEffect, useState } from "react";
import { Button, Form, Modal, message, Input, Upload } from "antd";
import { EndPoint, imageURL } from "../../../../../config";
import { ModalWrapper, Wrapper } from "../../../sign-up/style";
import { PlusOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 10,
    },
    sm: {
      span: 7,
    },
  },
};
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
export default function (props) {
  const {
    show,
    close,
    data,
    signUpUserAction,
    fakeActionAuth,
    getList,
    updateUserAction,
    fakeActionUser,
  } = props;

  const [form] = Form.useForm();

  const [fileList, setFileList] = useState(
    Object.keys(data)?.length !== 0 && data?.u_photo
      ? [
          {
            uid: "-1",
            name: "image.png",
            status: "done",
            url: `${imageURL}${data?.u_photo}`,
          },
        ]
      : []
  );

  const SignUpUserResponse = useSelector(
    (state) => state.Auth.SignUpUserResponse || {}
  );
  const UpdateUserResponse = useSelector(
    (state) => state.User.UpdateUserResponse || {}
  );

  useEffect(() => {
    return () => {};
  }, []);

  useEffect(() => {
    if (UpdateUserResponse?.status === true) {
      message.destroy();
      message.success(UpdateUserResponse?.message);
      getList();
      close();
      fakeActionUser("UpdateUserResponse");
    } else if (UpdateUserResponse?.status === false) {
      message.destroy();

      message.error(
        Object.values(UpdateUserResponse?.data)?.[0]?.[0] ||
          UpdateUserResponse?.message
      );
      fakeActionUser("UpdateUserResponse");
    }
  }, [UpdateUserResponse]);

  useEffect(() => {
    if (SignUpUserResponse?.status === true) {
      message.destroy();
      message.success(SignUpUserResponse?.message);
      getList();
      close();
      fakeActionAuth("SignUpUserResponse");
    } else if (SignUpUserResponse?.status === false) {
      message.destroy();
      message.error(
        Object.values(SignUpUserResponse?.data)?.[0]?.[0] ||
          SignUpUserResponse?.message
      );

      fakeActionAuth("SignUpUserResponse");
    }
  }, [SignUpUserResponse]);

  const onCreate = (values) => {
    message.destroy();
    message.loading("Loading...", 0);

    const withOutPic = { ...values };
    const addObj = {
      name: values?.name,
      email: values?.email,
      password: values?.password,
      confirm_password: values?.confirm_password,
    };
    if (fileList?.length !== 0 || fileList?.[0]?.url) {
      if (Object.keys(data)?.length === 0) {
        signUpUserAction({ ...addObj });
      } else {
        updateUserAction(data?.id, { ...withOutPic });
      }
    } else {
      if (Object.keys(data)?.length === 0) {
        signUpUserAction({ ...addObj });
      } else {
        updateUserAction(data?.id, { ...withOutPic });
      }
    }
  };

  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
  };
  const validatePassword = (_, value) => {
    if (!value || (value.length >= 8 && value.length <= 16)) {
      return Promise.resolve();
    }
    return Promise.reject("Password must be between 8 and 16 characters.");
  };
  return (
    <Modal
      title={`${Object.keys(data)?.length === 0 ? "Add" : "Edit"} User`}
      open={show}
      width={650}
      onCancel={close}
      maskClosable={false}
      footer={[
        <Button size="large" type="ghost" key="back" onClick={close}>
          Close
        </Button>,
        <Button
          size="large"
          key="submit"
          type="dashed"
          onClick={() => {
            form
              .validateFields()
              .then((values) => {
                onCreate(values);
              })
              .catch((info) => {});
          }}
        >
          {`${Object.keys(data)?.length === 0 ? "Create" : "Update"} User`}
        </Button>,
      ]}
    >
      <Form
        {...formItemLayout}
        form={form}
        name="add_user"
        scrollToFirstError
        layout="vertical"
        style={{
          maxHeight: "510px",
          overflowX: "hidden",
          overflowY: "auto",
          padding: "0px 20px",
        }}
        initialValues={
          Object.keys(data)?.length === 0
            ? {}
            : {
                name: data.name,
                email: data.email,
                password: data.password,
                confirm_password: data.confirm_password,
              }
        }
      >
        <ModalWrapper>
          {Object.keys(data)?.length !== 0 && (
            <div className="row align-items-center">
              <Form.Item valuePropName="fileList" label="Avatar">
                <div
                  className="image-input image-input-outline"
                  data-kt-image-input="true"
                >
                  <Upload
                    listType="picture-card"
                    fileList={fileList}
                    name="User[photo]"
                    action={EndPoint + `api/v1/user/${data?.id}/update-image`}
                    headers={{
                      Authorization: `Bearer ${localStorage.getItem("token")}`,
                    }}
                    accept=".png, .jpg, .jpeg"
                    onPreview={handlePreview}
                    onChange={handleChange}
                  >
                    {fileList.length >= 1 ? null : uploadButton}
                  </Upload>
                </div>
                <div className="form-text">
                  Allowed file types: png, jpg, jpeg.
                </div>
              </Form.Item>
            </div>
          )}
          <div className="row   align-items-center">
            <Form.Item
              name="name"
              label="User Name"
              rules={[
                {
                  required: true,
                  message: "Please input your User Name!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter User Name" />
            </Form.Item>
          </div>
          <div className="row   align-items-center">
            <Form.Item
              name="email"
              label="E-mail"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input size="large" placeholder="Enter E-mail" />
            </Form.Item>
          </div>

          {Object.keys(data)?.length === 0 && (
            <>
              <div className="row   align-items-center">
                <Form.Item
                  name="password"
                  label="Password"
                  rules={[
                    {
                      required: true,
                      message: "Password is required",
                    },
                    {
                      validator: validatePassword,
                    },
                  ]}
                >
                  <Input.Password
                    size="large"
                    placeholder="Enter Password"
                    autocomplete="new-password"
                  />
                </Form.Item>
              </div>
              <div className="row   align-items-center">
                <Form.Item
                  name="confirm_password"
                  label="Confirm Password"
                  dependencies={["password"]}
                  rules={[
                    {
                      required: true,
                      message: "Confirm password is required",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "The two passwords that you entered do not match!"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    placeholder="Enter Confirm Password"
                    autocomplete="off"
                    size="large"
                  />
                </Form.Item>
              </div>
            </>
          )}
        </ModalWrapper>
      </Form>
    </Modal>
  );
}
