import { Popover, Table, Tooltip } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Loading from "../../../../components/loading";
import NoData from "../../../../components/no-data";
import Pagination from "../../../../components/pagination";
import ASINTooltip from "../../../../components/tooltip";
import { DefaultPerPage } from "../../../../config";
import Wrapper from "./style";

export default function (props) {
  const { ApplicationLogsSystemLogAction, fakeActionUserSystemLog } = props;
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);

  const [page, setPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [pageSize, setPageSize] = useState(DefaultPerPage);

  const [errorView, setErrorView] = useState(false);
  const [errorData, setErrorData] = useState({});

  const GetApplicationLogSystemResponse = useSelector(
    (state) => state.SystemLog.GetApplicationLogSystemResponse || {}
  );

  useEffect(() => {
    ApplicationLogsSystemLogAction({ page: page, perPage: pageSize });
    return () => {};
  }, []);

  useEffect(() => {
    if (GetApplicationLogSystemResponse.status) {
      setLoading(false);
      setTotalPage(
        GetApplicationLogSystemResponse?.data?.pagination?.totalCount
      );
      setData(GetApplicationLogSystemResponse?.data?.records);
      fakeActionUserSystemLog("GetApplicationLogSystemResponse");
    } else if (GetApplicationLogSystemResponse.status === false) {
      setLoading(false);
      fakeActionUserSystemLog("GetApplicationLogSystemResponse");
    }
  }, [GetApplicationLogSystemResponse]);

  const onPageNo = (e) => {
    setLoading(true);
    setData([]);
    ApplicationLogsSystemLogAction({
      page: e,
      perPage: pageSize,
    });
    setPage(e);
  };

  const onPerPage = (e) => {
    setPage(1);
    setPageSize(e);
    setLoading(true);
    ApplicationLogsSystemLogAction({
      page: 1,
      perPage: e,
    });
  };

  const columns = [
    {
      title: "Sr. No.",
      width: 100,
      align: "left",
      ellipsis: true,
      render: (_, __, i) => {
        return <span>{(page - 1) * pageSize + 1 + i}</span>;
      },
    },
    {
      title: "Date & Time Log Created",
      width: 250,
      align: "left",
      render: (d) => {
        return (
          <span>
            {moment(new Date(d.created_at * 1000)).format(
              "MMM DD, YYYY hh:mm A"
            )}
          </span>
        );
      },
    },
    {
      title: "Event Name",
      width: 220,
      align: "left",
      render: (d) => {
        return <span>{d.event_name || "-"}</span>;
      },
    },
    {
      title: "Event Type",
      width: 150,
      align: "left",
      render: (d) => {
        return <span>{d.event_type || "-"}</span>;
      },
    },
    {
      title: "Event Message",
      width: 180,
      align: "left",
      ellipsis: false,
      render: (d) => {
        return (
          <Tooltip rule title={d.error_message}>
            <div className="actionIcon">{d.error_message}</div>
          </Tooltip>
        );
      },
    },
    {
      title: "Event Data",
      width: 150,
      align: "left",
      ellipsis: false,
      render: (d) => {
        if (!d?.error_data) {
          return "-";
        }

        return (
          <Popover
            // placement="right"
            title=""
            getPopupContainer={() =>
              document.getElementById("System_Event_Log_Table_User").parentNode
            }
            content={
              <div
                style={{
                  width: "400px",
                  wordBreak: "break-word",
                  minHeight: "20px",
                  maxHeight: "100px",
                  overflow: "auto",
                }}
              >
                {d?.error_data}
              </div>
            }
            trigger="hover"
          >
            <div className="actionIcon">{d.error_data}</div>
          </Popover>
        );
      },
    },
  ];

  return (
    <Wrapper>
      <div id="kt_app_content" className="app-content flex-column-fluid">
        <div
          id="kt_app_content_container"
          className="app-container container-fluid"
        >
          <div className>
            <div className="row gy-5 g-xl-5">
              <div className="col-lg-12">
                <div className="card">
                  <div
                    className="card-body py-4"
                    id="System_Event_Log_Table_User"
                  >
                    <div className="table-responsive">
                      {loading ? (
                        <Loading />
                      ) : data?.length != 0 ? (
                        <Table
                          columns={columns}
                          dataSource={data}
                          rowKey="key"
                          loading={loading}
                          pagination={false}
                        />
                      ) : (
                        <NoData />
                      )}
                    </div>
                    <Pagination
                      loading={loading || data?.length === 0}
                      pageSize={pageSize}
                      page={page}
                      totalPage={totalPage}
                      onPerPage={onPerPage}
                      onPageNo={onPageNo}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}
