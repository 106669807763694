import { DotChartOutlined } from "@ant-design/icons";
import { Segmented, Select, Skeleton, message } from "antd";
import React, { useEffect } from "react";
import NoData from "../../../../components/no-data";

import Highcharts from "highcharts";

export const LineChart = (props) => {
  const { data, count, signs } = props;

  const sign = {
    ACOS: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    ROAS: {
      valueSuffix: "",
      valuePrefix: "",
    },
    Spend: {
      valueSuffix: "",
      valuePrefix: signs,
    },
    "Total Sales": {
      valueSuffix: "",
      valuePrefix: signs,
    },
    CPC: {
      valueSuffix: "",
      valuePrefix: signs,
    },
    "Conversion Rate": {
      valueSuffix: "%",
      valuePrefix: "",
    },
    Orders: {
      valueSuffix: "",
      valuePrefix: "",
    },
    Clicks: {
      valueSuffix: "",
      valuePrefix: "",
    },
    Impressions: {
      valueSuffix: "",
      valuePrefix: "",
    },
    CTR: {
      valueSuffix: "%",
      valuePrefix: "",
    },
    "Ad Sales Percentage": {
      valueSuffix: "%",
      valuePrefix: "",
    },
  };
  const colorHashCodes = [
    "#FF5733", // Deep Orange
    "#4CAF50", // Green
    "#2196F3", // Blue
    "#FFC107", // Amber
    "#9C27B0", // Purple
    "#FFEB3B", // Yellow
    "#FF9800", // Orange
    "#E91E63", // Pink
    "#795548", // Brown
    "#00BCD4", // Cyan
    "#FF4081", // Pink Red
  ];
  useEffect(() => {
    if (data?.length === 0 || count?.length === 0) return;
    Highcharts.chart("line-chart", {
      chart: {
        renderTo: "sales-by-week-graph",
        height: "490px",
        style: {
          fontFamily: "Oswald,sans-serif",
        },
      },
      type: "column",
      title: "",
      xAxis: {
        title: {
          text: "Date",
        },
        categories: data,
        // crosshair: true,
      },
      yAxis: [
        {
          title: {
            text: "",
          },
          labels: {
            formatter: function () {
              return this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return signs + this.value?.toLocaleString();
            },
          },
        },
        {
          title: {
            text: "",
          },
          opposite: true,
          labels: {
            formatter: function () {
              return this.value?.toLocaleString() + "%";
            },
          },
        },
      ],

      series: count?.map((d, i) => ({
        name: d?.name,
        type: d?.name === "Impressions" ? "column" : "line",
        tooltip: sign?.[d?.name],
        color: colorHashCodes?.reverse()?.[i] || "#A52A2A",
        data: d?.data?.map((d) => parseFloat(parseFloat(d).toFixed(2))),
        yAxis: sign?.[d?.name]?.valuePrefix
          ? 1
          : sign?.[d?.name]?.valueSuffix
          ? 2
          : 0,
      })),
      tooltip: {
        useHTML: true,
        shared: true,
        style: {
          fontSize: "14px", // Change font size
          padding: "10px", // Change padding
          // You can add more properties to modify the tooltip style
        },
        pointFormatter: function () {
          return (
            '<span style="color:' +
            this.color +
            '">\u25CF</span> ' +
            this.series.name +
            ": <b>" +
            sign?.[this.series.name]?.valuePrefix +
            this.y?.toLocaleString() +
            sign?.[this.series.name]?.valueSuffix +
            "</b><br/>"
          );
        },
      },
    });
  }, [count]);

  return <div id="line-chart" />;
};

function GraphView(
  cardsDataLoader,
  optionLoader,
  filters,
  setFilters,
  metricsOptionList,
  options,
  series,
  allAdsPerformanceGraphData,
  setAllAdsPerformanceGraphData,
  setGraphType,
  graphType,
  graphSeriesList,
  graphData,
  sign
) {
  console.log(graphSeriesList, series, "graphData");
  return (
    <div className="row gx-5">
      <div className="col-xl-12">
        <div className="card card-flush pb-5 overflow-hidden h-lg-100">
          <div className="card-header pt-5">
            <h3 className="card-title align-items-start flex-column">
              <span className="card-label fw-bold text-dark">
                Daily Performance Trend
              </span>
            </h3>
            <div className="card-toolbar">
              <div className="position-relative">
                <Segmented
                  value={graphType}
                  options={["Previous", "Current"]}
                  className="me-5"
                  onChange={(e) => setGraphType(e)}
                />
              </div>
              <div className="position-relative">
                <Select
                  showSearch
                  mode="multiple"
                  allowClear={false}
                  size="large"
                  placeholder="Select Option"
                  maxTagCount="responsive"
                  loading={optionLoader}
                  value={filters?.metrics || []}
                  onChange={(e) => {
                    if (e?.length === 0) return;
                    let filters_ = { ...filters };

                    if (e?.length !== 0 && e?.length > 4) {
                      message.destroy();
                      message.warning("Select only 4 metrics");
                      return;
                    }
                    filters_ = {
                      ...filters_,
                      metrics:
                        e?.length !== 0
                          ? e?.length > 4
                            ? filters_?.metrics
                            : e
                          : [],
                    };
                    setFilters(filters_);
                  }}
                  style={{
                    width: "300px",
                  }}
                  filterOption={(input, option) =>
                    (option?.label ?? "")
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={metricsOptionList}
                  className="fs-7"
                />
              </div>
            </div>
          </div>
          <div className="card-body d-flex align-items-end p-0">
            <div
              id="kt_charts_widget_36 "
              style={{ height: "500px" }}
              className={`min-h-auto w-100  ps-4 pe-6 ${
                cardsDataLoader &&
                `d-flex align-items-center justify-content-center`
              }`}
            >
              {cardsDataLoader ? (
                <>
                  <Skeleton.Node active>
                    <DotChartOutlined
                      style={{
                        fontSize: 40,
                        color: "#bfbfbf",
                      }}
                    />
                  </Skeleton.Node>
                </>
              ) : (
                  graphType === "Current"
                    ? graphSeriesList?.length === 0
                    : series?.length === 0
                ) ? (
                <div
                  style={{
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <NoData />
                </div>
              ) : (
                <>
                  <LineChart
                    data={graphData}
                    count={graphType === "Current" ? graphSeriesList : series}
                    signs={sign}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default GraphView;
