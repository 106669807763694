import React from "react";
import Wrapper from "./style";
import { Button } from "antd";
import { Link } from "react-router-dom";

export default function NotFound() {
  return (
    <Wrapper>
      <div>
        <svg
          width="380px"
          height="500px"
          viewBox="0 0 837 1045"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
        >
          {/* SVG paths */}
        </svg>
        <div className="message-box">
          <h1>404</h1>
          <p>Page not found</p>
          <div className="buttons-con">
            <div className="action-link-wrap">
              <Link
                style={{
                  color: "#007bff",
                  cursor: "pointer",
                  marginRight: "15px",
                }}
                target="_blank"
                to={{
                  pathname: localStorage.getItem("adminData")
                    ? "/"
                    : "/manage-seller-users",
                }}
              >
                Go to Home Page
              </Link>{" "}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
}

export const NothingToShow = () => (
  <div style={{ textAlign: "center" }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="42"
      height="39"
      viewBox="0 0 42 39"
    >
      <path
        fill="#363A3E"
        fillRule="evenodd"
        d="M1.917 22.254l3.879-1.066.223.8 3.924-1.065.446 1.555 9.897-2.666v7.062l-7.266 11.15 1.515.976 6.599-10.217L27.733 39l1.515-.977-7.222-11.149v-7.551l9.586-2.577.268 1.065L42 16.612 37.451 0l-9.317 4.086.268 1.066-21.223 5.73.446 1.556L3.7 13.503l.223.8L0 15.368l1.917 6.886zM36.293 2.443l3.433 12.615-6.51.8-2.942-10.75 6.019-2.665zM28.891 6.84l2.23 8.172-19.53 5.33-2.228-8.172L28.89 6.84zm-20.82 7.33l1.382 5.062-2.229.622-1.383-5.063 2.23-.622zM4.37 16.034l.937 3.465-2.14.578-.981-3.466 2.184-.577z"
        opacity=".507"
      />
    </svg>
    <div
      style={{
        fontSize: "14px",
        color: "rgb(54, 58, 62)",
        opacity: "0.51",
        paddingTop: "3px",
        fontWeight: "bold",
        letterSpacing: "-0.36px",
      }}
    >
      Nothing to see here!
    </div>
  </div>
);
