import { createStore, combineReducers, applyMiddleware, compose } from "redux";
import { createHashHistory } from "history";
import thunk from "redux-thunk";
import { routerMiddleware } from "connected-react-router";
import reducers from "../reducer";

const history = createHashHistory();
const routeMiddleware = routerMiddleware(history);
const middleware = [thunk, routeMiddleware];

const composeEnhancers =
    typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
        ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
        : compose;

const rootReducer = combineReducers({
    ...reducers,
});

const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(...middleware))
);

export { store, history };
