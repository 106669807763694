export const nameObject = {
  "/": {
    name: "Dashboard",
  },
  "/dashboard": {
    name: "Dashboard",
  },

  "/campaigns-analytics": {
    name: "Campaigns Analytics",
  },
  "/manage-seller-users": {
    name: "Manage Seller Users",
  },
  "/rule-management": {
    name: "Rule Management",
  },
  "/campaigns-portfolio": {
    name: "Portfolio Level Data",
  },
  "/product-data": {
    name: "Product Data",
  },
  "/keyword-list": {
    name: "Keyword List",
  },
  "/negative-keywords": {
    name: "Negative Keywords",
  },
  "/negative-asin": {
    name: "Negative Asin",
  },
  "/campaign-management": {
    name: "Campaign Management",
  },
  "/search-term": {
    name: "Search Term Data",
  },
  "/organic-spon-ranking": {
    name: "Organic & Spon. Ranking",
  },
  "/setting/my-profile": {
    name: "My Profile",
    parent: "Setting",
  },
  "/setting/manage-user": {
    name: "Manage User",
    parent: "Setting",
  },
  "/setting/marketplace-credentials": {
    name: "Marketplace Credentials",
    parent: "Setting",
  },
  "/callback_sp": {
    name: "Marketplace Credentials",
    parent: "Setting",
  },
  "/callbackads": {
    name: "Marketplace Credentials",
    parent: "Setting",
  },
  "/application-logs/central-log": {
    name: "Central Log",
    parent: "Application Logs",
  },
  "/application-logs/system-log": {
    name: "System Log",
    parent: "Application Logs",
  },
};
export const CampaignAdType = [
  {
    label: "Sponsored Products",
    value: "SP",
  },
  {
    label: "Sponsored Brand",
    value: "SB",
  },
  {
    label: "Sponsored Display",
    value: "SD",
  },
];
