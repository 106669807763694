import { styled } from "styled-components";

const Wrapper = styled.div`
  .ant-segmented {
    background-color: #efefef !important;
    .ant-segmented-item-selected {
      background-color: #22a9e6;
      color: rgb(255 255 255);
    }
    .ant-segmented-item-label {
      font-weight: 600;
      letter-spacing: 0.4px;
      font-size: 14px;
    }
  }
  .ant-table-wrapper .ant-table-thead > tr > td {
    background: #ffffff !important;
  }
`;

export const CampaignCreationWrapper = styled.div`
  .country {
    border: 1px dashed #202125;
    padding: 10px;
    border-radius: 7px;
  }

  .type-select {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
    background: #22a9e61c;
    border-radius: 12px;
    .ant-tag {
      font-size: 15px;
    }
    .ant-segmented {
      background-color: #fff !important;
      width: fit-content;
    }
    .title {
      font-size: 16px;
      margin-bottom: 10px;
      font-weight: 700;
    }
  }
  .campaign-specifications {
    background: #ffffff;
    padding: 20px;
    border-radius: 12px;
    position: relative;
    border: 1px dashed #22a9e6;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    .ant-picker,
    .ant-input,
    .ant-input-group-addon {
      border: none !important;
    }
    .ant-input-wrapper {
      background-color: #22a9e612 !important;
      border-radius: 8px !important;
    }
    .ant-input-group-addon {
      background: #dff1fb;
    }
    .ant-input-group-wrapper {
      border: 1px solid #22a9e6 !important;
      border-radius: 8px;
    }
    .antd-border-add {
      border: 1px solid #22a9e6 !important;
    }
    .other-filed {
      label {
        font-size: 15px;
        font-weight: 800;
      }
    }
    .ant-input-number {
      background-color: #e4f3fc;
      border: 1px solid #22a9e6;
    }
    .date-range {
      background-color: #e6f3fc !important;
      border: 1px solid #21aae6 !important;
      width: 100%;
    }
    .Campaign-Name {
      display: grid;
      .ant-input {
        font-size: 14px;
        font-weight: 300;
        height: 40px;
      }
      .ant-picker {
        background-color: #e6f3fc !important;
      }
      .ant-picker-range {
        background-color: #22a9e612 !important;
        border: 1px solid #22a9e6 !important;
        height: 40px;
      }

      label {
        font-size: 15px;
        font-weight: 800;
      }
      small {
        color: gray;
      }
    }
  }
  .ant-collapse-extra {
    height: 22px;
  }
  .auto-manual {
    transform: scale(0.5);
    position: relative;
    top: -17px;
    right: -70px;
  }
  .switch-button {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    justify-content: center;
    margin: auto;
    height: 55px;
  }

  .switch-button .switch-outer {
    height: 100%;
    background: #252532;
    width: 115px;
    border-radius: 165px;
    -webkit-box-shadow: inset 0px 5px 10px 0px #16151c, 0px 3px 6px -2px #403f4e;
    box-shadow: inset 0px 5px 10px 0px #16151c, 0px 3px 6px -2px #403f4e;
    border: 1px solid #32303e;
    padding: 6px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;
  }

  .switch-button .switch-outer input[type="checkbox"] {
    opacity: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;
  }

  .switch-button .switch-outer .button-toggle {
    height: 42px;
    width: 42px;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(#3b3a4e),
      to(#272733)
    );
    background: -o-linear-gradient(#5a596f, #3f3f4d);
    background: linear-gradient(#5a596f, #3f3f4d);
    border-radius: 100%;
    -webkit-box-shadow: inset 0px 5px 4px 0px #424151, 0px 4px 15px 0px #0f0e17;
    box-shadow: inset 0px 5px 4px 0px #424151, 0px 4px 15px 0px #0f0e17;
    position: relative;
    z-index: 2;
    -webkit-transition: left 0.3s ease-in;
    -o-transition: left 0.3s ease-in;
    transition: left 0.3s ease-in;
    left: 0;
  }

  .switch-button
    .switch-outer
    input[type="checkbox"]:checked
    + .button
    .button-toggle {
    left: 58%;
  }

  .switch-button
    .switch-outer
    input[type="checkbox"]:checked
    + .button
    .button-indicator {
    -webkit-animation: indicator 1s forwards;
    animation: indicator 1s forwards;
  }

  .switch-button .switch-outer .button {
    width: 100%;
    height: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    -webkit-box-pack: justify;
    justify-content: space-between;
  }

  .switch-button .switch-outer .button-indicator {
    height: 25px;
    width: 25px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    border-radius: 50%;
    border: 3px solid #ef565f;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    right: 10px;
    position: relative;
  }

  @-webkit-keyframes indicator {
    30% {
      opacity: 0;
    }

    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      border: 3px solid #60d480;
      left: -68%;
    }
  }

  @keyframes indicator {
    30% {
      opacity: 0;
    }

    0% {
      opacity: 1;
    }

    100% {
      opacity: 1;
      border: 3px solid #60d480;
      left: -68%;
    }
  }
  .action-buttons {
    position: absolute;
    right: 13px;
    top: 7px;
  }
  .group-view {
    background: #e4eff6;
    padding: 15px;
    border-radius: 7px;
    padding-top: 15px;
  }
  .list-card {
    display: flex;
    margin-top: 15px;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    background: #ffffff12;
    padding: 6px;
    border-radius: 13px;
  }

  .details {
    display: flex;
    align-items: center;
    width: 77%;
  }

  .list-image {
    width: 90px;
    border-radius: 10px;
  }

  .list-details {
    display: grid;
    margin-left: 20px;
  }

  .list-title {
    font-size: 15px;
    color: #0597d9;
    font-weight: 700;

    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .list-details-info {
    margin-top: 6px;
    display: flex;
    align-items: center;
    font-size: 12px;

    span {
      font-weight: 600;
    }
  }

  .list-details-info div:not(:last-child)::after {
    content: "|";
    margin: 0 5px;
    color: rgba(0, 0, 0, 0.45);
  }
  .action-btn-add {
    background: #0597d9;
    height: fit-content;
    border: none;
    margin-bottom: 10px;
    color: #d4e8f4;
    font-weight: 800;
    letter-spacing: 0.1px;
    border-radius: 10px;
    font-size: 12px;
    padding: 5px 10px;
  }
  .list-action {
    width: 23%;
    display: grid;
  }

  .action-btn {
    font-size: 12px;
  }

  .action-remove {
    justify-items: end;
    display: grid;
  }
  .ant-rate {
    font-size: 14px;
    color: #fa9b14;
  }
`;
export default Wrapper;
