import React, { useEffect, useState } from "react";
import { Drawer, Checkbox, Space, Button, Spin, Table } from "antd";

import { MenuOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

const Row = ({ children, ...props }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === "sort") {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: "none",
                  cursor: "move",
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};

const ColumnDrawer = ({
  visible,
  onClose,
  columnsList,
  visibleColumns,
  onColumnToggle,
  configLoading,
  configList,
  CampaignConfigUpdateAction,
}) => {
  const [configColumns, setConfigColumns] = useState([]);
  const [updateConfig, setUpdateConfig] = useState(false);

  useEffect(() => {
    if (columnsList?.length > 0) {
      const listColumns = columnsList?.map((d, i) => {
        const index = configList?.findIndex((r) => r === d?.keyTitle);

        return {
          name: d?.defaultTitle || d?.title,
          keyTitle: d?.keyTitle,
          checked: index !== -1,
          index: i,
          key: i + 1,
        };
      });

      listColumns.sort((a, b) => {
        const indexA = configList.indexOf(a.keyTitle);
        const indexB = configList.indexOf(b.keyTitle);

        // If both keys are found in the keys array, compare their indices
        if (indexA !== -1 && indexB !== -1) {
          return indexA - indexB;
        }

        // If one of the keys is not found in the keys array, move it to the end
        if (indexA === -1 && indexB !== -1) {
          return 1;
        } else if (indexA !== -1 && indexB === -1) {
          return -1;
        }

        // If neither key is found in the keys array, maintain their current order
        return a.index - b.index;
      });

      setConfigColumns(listColumns);
    }
  }, [columnsList, configList]);

  const handleCheckboxChange = (name) => {
    setConfigColumns((prev) => {
      return prev?.map((d) => {
        if (d?.name === name) {
          return { ...d, checked: !d?.checked };
        }
        return { ...d };
      });
    });
  };

  const onCloseModal = () => {
    setConfigColumns(
      columnsList?.map((d) => {
        const index = configList?.findIndex((r) => r === d?.keyTitle);

        return { ...d, checked: index !== -1 };
      })
    );
    onClose();
  };

  const onOk = () => {
    setUpdateConfig(true);

    CampaignConfigUpdateAction({
      type: "campaigns-analytics",
      table_configuration: configColumns
        ?.filter((r) => r?.checked)
        ?.map((d) => d?.keyTitle),
    });
  };

  const columns = [
    {
      key: "sort",
    },

    {
      title: "Titles",
      render: (e) => {
        return (
          <Checkbox
            onChange={() => handleCheckboxChange(e?.name)}
            checked={e?.checked}
            key={e.index}
          >
            {e?.name || "-"}
          </Checkbox>
        );
      },
    },
  ];

  const [dataSource, setDataSource] = useState([
    {
      key: "1",
      name: "John Brown",
      age: 32,
      address:
        "Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text Long text",
    },
    {
      key: "2",
      name: "Jim Green",
      age: 42,
      address: "London No. 1 Lake Park",
    },
    {
      key: "3",
      name: "Joe Black",
      age: 32,
      address: "Sidney No. 1 Lake Park",
    },
  ]);
  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setConfigColumns((previous) => {
        const activeIndex = previous.findIndex((i) => i.key === active.id);
        const overIndex = previous.findIndex((i) => i.key === over?.id);

        return arrayMove(previous, activeIndex, overIndex);
      });
    }
  };

  return (
    <Drawer
      title="Show / Hide Columns"
      placement="right"
      closable={false}
      onClose={onClose}
      open={visible}
      extra={
        <Space>
          <Button onClick={() => onCloseModal()}>Cancel</Button>
          <Button loading={updateConfig} type="primary" onClick={onOk}>
            OK
          </Button>
        </Space>
      }
      width={420}
    >
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          items={configColumns.map((i) => i.key)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            components={{
              body: {
                row: Row,
              },
            }}
            rowKey="key"
            showHeader={false}
            columns={columns}
            pagination={false}
            dataSource={configColumns}
          />
        </SortableContext>
      </DndContext>
    </Drawer>
  );
};

export default ColumnDrawer;
