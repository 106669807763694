import { Skeleton } from "antd";
import React from "react";

const Targetloading = () => {
  return (
    <div className="row">
      {Array(4)
        ?.fill(1)
        ?.map((d, i) => (
          <div key={i} className="col-md-6 pe-7 mb-6">
            <div className="d-flex flex-stack">
              <div className="d-flex align-items-center me-5">
                <div className="me-5">
                  <a className="text-gray-800 fw-bolder text-hover-primary fs-6">
                    <Skeleton.Button
                      active
                      size="small"
                      shape="round"
                      className="me-2 mb-1"
                    />
                  </a>

                  <span className="fw-semibold fs-7 d-flex text-start text-dark ps-0">
                    <Skeleton.Input
                      active
                      size="small"
                      shape="round"
                      className="me-2"
                    />
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-center">
                <h4 className="fw-bold mb-0">
                  <Skeleton.Button
                    active
                    size="small"
                    shape="round"
                    className="me-2"
                  />
                </h4>
              </div>
            </div>
          </div>
        ))}
    </div>
  );
};

export default Targetloading;
